import {LocalStorageKey} from "@appTypes/localstorage";
import {SearchParams} from "@appTypes/searchParams";

interface GoogleAuth {
  country?: string;
  contentId?: string;
  userId?: string;
  anonRegPoint?: string;
  challengeId?: string;
  source?: string;
}

export const googleAuthLink =
  process.env.REACT_APP_GOOGLE_AUTH ||
  `https://api.${window.location.host}/api/auth/connect/google`;

export const googleAuth = (props: GoogleAuth) => {
  let route = googleAuthLink;

  const {country, contentId, userId, anonRegPoint, challengeId, source} = props;
  const anonUserId = localStorage.getItem(LocalStorageKey.AnonUserId);
  const searchParams = new URLSearchParams();

  if (country) {
    searchParams.set(SearchParams.Country, country);
  }

  if (contentId) {
    searchParams.set(SearchParams.ContentUid, contentId);
  }

  if (userId) {
    searchParams.set(SearchParams.UserUid, userId);
  }

  if (anonUserId) {
    searchParams.set(SearchParams.AnonUserUid, anonUserId);
  }

  if (anonRegPoint) {
    searchParams.set(SearchParams.AnonRegPoint, anonRegPoint);
  }

  if (challengeId) {
    searchParams.set(SearchParams.ChallengeUid, challengeId);
  }

  if (source) {
    searchParams.set(SearchParams.Source, source);
  }

  return `${route}?${searchParams.toString()}`;
};
