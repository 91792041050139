import React from "react";

import styles from "./menuDrawer.module.scss";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import clsx from "clsx";
import {useDispatch, useSelector} from "react-redux";
import {
  setCloseMenuDrawer,
  setOpenMenuDrawer,
} from "@app/store/slices/menuDrawer";
import contentService from "../../services/contentService";
import userService from "../../services/userService";
import {
  INFORMATION,
  PAYMENT_RU,
  PAYMENTS,
  PRIVACY_POLICY_RU,
  REQUISITES_RU,
  SETTINGS,
  TERMS_OF_SERVICE_RU,
} from "@app/router.constants";
import {useLocation, useNavigate} from "react-router-dom";
import {
  CONTEXT_ARCHIVE_CONTENT,
  CONTEXT_ARCHIVE_MYSTERY,
  CONTEXT_ARCHIVE_POST,
  CONTEXT_CREATE_ACCOUNT,
  CONTEXT_CREATE_MYSTERY,
  CONTEXT_CREATE_POST,
  CONTEXT_DELETE_CONTENT,
  CONTEXT_DELETE_MYSTERY,
  CONTEXT_DELETE_POST,
  CONTEXT_DELETE_PROFILE,
  CONTEXT_EDIT_CONTENT,
  CONTEXT_EDIT_MYSTERY,
  CONTEXT_EDIT_POST,
  CONTEXT_INFORMATION,
  CONTEXT_LOGOUT,
  CONTEXT_MARK_ALL_AS_READ,
  CONTEXT_PAYMENTS,
  CONTEXT_RU_USEFUL_PAYMENT,
  CONTEXT_RU_USEFUL_PRIVACY_POLICY,
  CONTEXT_RU_USEFUL_REQUISITES,
  CONTEXT_RU_USEFUL_TERMS,
  CONTEXT_SETTINGS,
  CONTEXT_SHARE_CHALLENGE,
  CONTEXT_SHARE_CONTENT,
  CONTEXT_SHARE_PROFILE,
  CONTEXT_SHARE_SOCIAL,
  CONTEXT_SORT_NEW_FIRST,
  CONTEXT_SORT_UNREAD_FIRST,
  CONTEXT_UNARCHIVE_CONTENT,
  CONTEXT_UNARCHIVE_MYSTERY, CONTEXT_UNARCHIVE_POST,
  CONTEXT_UNFOLLOW_USER,
  CONTEXT_UNJOIN_CHALLENGE,
} from "./menuDrawer.constants";
import {
  setContent,
  setOpen as setOpenContent,
} from "@app/store/slices/contentOverlay";
import {ReactComponent as CopyIcon} from "@assets/images/notification-copy.svg";
import {ReactComponent as Tick} from "@assets/images/tick.svg";
import {OVERLAY_EDIT_CONTENT} from "../content-overlay/contentOverlay.constants";
import {setContentFeedStatus} from "@app/store/slices/contentFeed";
import {
  setChallengeContentStatus,
  setChallengeParticipants,
} from "@app/store/slices/challenge";
import {
  currentProfileSelector,
  isAuthenticatedSelector,
  setDeleteVisible,
  setLogoutVisible,
  setMysteryCreationOverlay,
  setMysteryStatus,
  setPostCreationOverlay,
  setPostStatus,
  setProfileContentStatus,
  unjoin,
} from "@app/store/slices/profile";
import challengeService from "@services/challengeService";
import useNotification from "@hooks/useNotification";
import {setContentStatus} from "@app/store/slices/content";
import {
  setMysteryEdit,
  setOpenMysteryEditOverlay,
} from "@app/store/slices/mysteryEditOverlay";
import {removeFromFollowers} from "@app/store/slices/followers";
import {openAuthOverlay} from "@app/store/slices/authOverlay";
import {
  WithoutAuthFormType,
  WithoutAuthRegPoint,
} from "@app/store/interfaces/auth";
import {setSort} from "@app/store/slices/chat";
import notificationService from "@services/notificationService";
import {useAppSelector} from "@app/store/hooks";
import {ReactComponent as BlockedIcon} from "@assets/images/notification-blocked-white.svg";
import {ReactComponent as ErrorIcon} from "@assets/images/notification-error-white.svg";
import EmailNotVerified from "@components/notification/templates/emailNotVerified";
import {
  setOpenPostEditOverlay,
  setPostEdit,
} from "@app/store/slices/postEditOverlay";

const MenuDrawer = (props) => {
  const {list, visible} = props;
  const {username, isAnonym, status, isVerified, isApproved} = useSelector(
    currentProfileSelector,
  );
  const isAuthenticated = useAppSelector(isAuthenticatedSelector);

  const notification = useNotification();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const {pathname} = location;

  const toggleDrawer = (value) => {
    if (!value) {
      dispatch(setCloseMenuDrawer());
    } else {
      dispatch(setOpenMenuDrawer());
    }
  };

  const handleItemClick = (item) => {
    switch (item.type) {
      case CONTEXT_CREATE_ACCOUNT:
        dispatch(setCloseMenuDrawer());
        dispatch(
          openAuthOverlay({
            withoutAuthFormType: WithoutAuthFormType.Default,
          }),
        );
        break;
      case CONTEXT_LOGOUT:
        dispatch(setCloseMenuDrawer());
        return dispatch(setLogoutVisible(true));
      case CONTEXT_SHARE_CONTENT:
        dispatch(setCloseMenuDrawer());
        return contentService
          .shareContent(item.videoId, item.userName)
          .then((data) => {
            if (data) {
              notification.send({
                type: "message",
                text: "Link copied to clipboard.",
                options: {
                  icon: <CopyIcon />,
                  duration: 3000,
                },
              });
            }
          });
      case CONTEXT_SHARE_SOCIAL:
        dispatch(setCloseMenuDrawer());
        const socialBaseUrls = {
          instagram: `https://www.instagram.com/`,
          facebook: `https://www.facebook.com/`,
          youtube: `https://www.youtube.com/`,
          tiktok: `https://www.tiktok.com/`,
          twitter: `https://twitter.com/`,
          twitch: `https://www.twitch.tv/`,
          reddit: `https://www.reddit.com/`,
        };
        if (socialBaseUrls[item.social] && item.userId) {
          const fullUrl = `${socialBaseUrls[item.social]}${item.userId}`;
          navigator.clipboard
            .writeText(fullUrl)
            .then(() => {
              notification.send({
                type: "message",
                text: "Link copied to clipboard.",
                options: {
                  icon: <CopyIcon />,
                  duration: 3000,
                },
              });
            })
            .catch((err) => {
              console.error("Failed to copy link: ", err);
              notification.send({
                type: "error",
                text: "Failed to copy link.",
              });
            });
        }
        return notification.send({
          type: "error",
          text: "Failed to copy link.",
        });
      case CONTEXT_SHARE_PROFILE:
        dispatch(setCloseMenuDrawer());
        const isCurrentUser = username === item.username;

        if (isAnonym && isCurrentUser) {
          return dispatch(
            openAuthOverlay({
              withoutAuthFormType: WithoutAuthFormType.Default,
              regPoint: WithoutAuthRegPoint.ShareProfile,
              pathname,
            }),
          );
        }

        return userService.shareProfile(item.username).then((data) => {
          if (data) {
            notification.send({
              type: "message",
              text: "Link copied to clipboard.",
              options: {
                icon: <CopyIcon />,
                duration: 3000,
              },
            });
          }
        });
      case CONTEXT_SHARE_CHALLENGE:
        dispatch(setCloseMenuDrawer());
        return challengeService
          .shareChallenge(item.challengeAlias)
          .then((data) => {
            if (data) {
              notification.send({
                type: "message",
                text: "Link copied to clipboard.",
                options: {
                  icon: <CopyIcon />,
                  duration: 3000,
                },
              });
            }
          });
      case CONTEXT_UNJOIN_CHALLENGE:
        dispatch(setCloseMenuDrawer());
        return challengeService.unjoinChallenge(item.challengeId).then(() => {
          dispatch(unjoin(item.challengeId));
          dispatch(setChallengeParticipants("unjoin"));
        });
      case CONTEXT_SETTINGS:
        dispatch(setCloseMenuDrawer());
        if (isAnonym) {
          return dispatch(
            openAuthOverlay({
              withoutAuthFormType: WithoutAuthFormType.Default,
              regPoint: WithoutAuthRegPoint.ProfileSettings,
              pathname,
            }),
          );
        }
        return navigate(SETTINGS);
      case CONTEXT_PAYMENTS:
        dispatch(setCloseMenuDrawer());
        return navigate(PAYMENTS);
      case CONTEXT_INFORMATION:
        dispatch(setCloseMenuDrawer());
        return navigate(INFORMATION);
      case CONTEXT_EDIT_CONTENT:
        dispatch(
          setContent({
            type: OVERLAY_EDIT_CONTENT,
            contentId: item.videoId,
          }),
        );
        dispatch(setOpenContent());
        return dispatch(setCloseMenuDrawer());
      case CONTEXT_EDIT_MYSTERY:
        dispatch(
          setMysteryEdit({
            mystery: item.mystery,
          }),
        );
        dispatch(setOpenMysteryEditOverlay());
        return dispatch(setCloseMenuDrawer());
      case CONTEXT_ARCHIVE_CONTENT:
        contentService.archiveContent(item.videoId);
        dispatch(
          setContentFeedStatus({
            videoId: item.videoId,
            status: "archive",
          }),
        );
        dispatch(
          setChallengeContentStatus({
            videoId: item.videoId,
            status: "archive",
          }),
        );
        dispatch(
          setProfileContentStatus({
            videoId: item.videoId,
            status: "archive",
          }),
        );
        dispatch(
          setContentStatus({
            status: "archive",
          }),
        );
        return dispatch(setCloseMenuDrawer());
      case CONTEXT_ARCHIVE_MYSTERY:
        userService.archiveMystery(item.mysteryId);
        dispatch(
          setMysteryStatus({
            id: item.mysteryId,
            status: "archive",
          }),
        );
        return dispatch(setCloseMenuDrawer());
      case CONTEXT_UNARCHIVE_CONTENT:
        contentService.unarchiveContent(item.videoId);
        dispatch(
          setContentFeedStatus({
            videoId: item.videoId,
            status: "active",
          }),
        );
        dispatch(
          setChallengeContentStatus({
            videoId: item.videoId,
            status: "active",
          }),
        );
        dispatch(
          setProfileContentStatus({
            videoId: item.videoId,
            status: "active",
          }),
        );
        dispatch(
          setContentStatus({
            status: "active",
          }),
        );
        return dispatch(setCloseMenuDrawer());
      case CONTEXT_UNARCHIVE_MYSTERY:
        userService.unarchiveMystery(item.mysteryId);
        dispatch(
          setMysteryStatus({
            id: item.mysteryId,
            status: "active",
          }),
        );
        return dispatch(setCloseMenuDrawer());
      case CONTEXT_DELETE_CONTENT:
        dispatch(setCloseMenuDrawer());
        return dispatch(
          setDeleteVisible({
            visible: true,
            type: "content",
            contentId: item.videoId,
          }),
        );
      case CONTEXT_DELETE_MYSTERY:
        dispatch(setCloseMenuDrawer());
        return dispatch(
          setDeleteVisible({
            visible: true,
            type: "mystery",
            contentId: item.mysteryId,
          }),
        );
      case CONTEXT_DELETE_PROFILE:
        dispatch(setCloseMenuDrawer());
        return dispatch(
          setDeleteVisible({
            visible: true,
            type: "profile",
            videoId: item.videoId,
          }),
        );
      //TODO: Uncomment when report is implemented
      // case CONTEXT_COMPLAIN_CONTENT:
      //   dispatch(setCloseMenuDrawer());
      //   return dispatch(
      //     setReportVisible({
      //       visible: true,
      //       type: CONTEXT_COMPLAIN_CONTENT,
      //       contentId: item.videoId,
      //     }),
      //   );
      // case CONTEXT_COMPLAIN_USER:
      //   dispatch(setCloseMenuDrawer());
      //   return dispatch(
      //     setReportVisible({
      //       visible: true,
      //       type: CONTEXT_COMPLAIN_USER,
      //       contentId: item.videoId,
      //     }),
      //   );
      case CONTEXT_RU_USEFUL_TERMS:
        dispatch(setCloseMenuDrawer());
        navigate(TERMS_OF_SERVICE_RU);
        break;
      case CONTEXT_RU_USEFUL_PRIVACY_POLICY:
        dispatch(setCloseMenuDrawer());
        navigate(PRIVACY_POLICY_RU);
        break;
      case CONTEXT_RU_USEFUL_REQUISITES:
        dispatch(setCloseMenuDrawer());
        navigate(REQUISITES_RU);
        break;
      case CONTEXT_RU_USEFUL_PAYMENT:
        dispatch(setCloseMenuDrawer());
        navigate(PAYMENT_RU);
        break;
      case CONTEXT_UNFOLLOW_USER:
        userService
          .removeFollower(item.uid)
          .then(() => {
            dispatch(removeFromFollowers({uid: item.uid}));
            dispatch(setCloseMenuDrawer());
          })
          .catch((error) => {
            console.error(
              "Error occurred trying to remove user from followers",
              error,
            );
          });
        break;
      case CONTEXT_SORT_NEW_FIRST:
        dispatch(setSort("last_message_at"));
        dispatch(setCloseMenuDrawer());
        break;
      case CONTEXT_SORT_UNREAD_FIRST:
        dispatch(setSort("has_unread"));
        dispatch(setCloseMenuDrawer());
        break;
      case CONTEXT_MARK_ALL_AS_READ:
        dispatch(setCloseMenuDrawer());
        notificationService.markAllAsRead();
        break;
      case CONTEXT_CREATE_POST:
        dispatch(setCloseMenuDrawer());
        dispatch(setPostCreationOverlay(true));
        break;
      case CONTEXT_DELETE_POST:
        dispatch(setCloseMenuDrawer());
        return dispatch(
          setDeleteVisible({
            visible: true,
            type: "post",
            contentId: item.postId,
          }),
        );
      case CONTEXT_EDIT_POST:
        dispatch(
          setPostEdit({
            post: item.post,
          }),
        );
        dispatch(setOpenPostEditOverlay());
        return dispatch(setCloseMenuDrawer());
      case CONTEXT_CREATE_MYSTERY:
        if (!isAuthenticated || isAnonym) {
          dispatch(
            openAuthOverlay({
              withoutAuthFormType: WithoutAuthFormType.Default,
              regPoint: WithoutAuthRegPoint.ProfileSettings,
              pathname: location.pathname,
            }),
          );

          return;
        }

        if (isAnonym) {
          return dispatch(
            openAuthOverlay({
              withoutAuthFormType: WithoutAuthFormType.Default,
              regPoint: WithoutAuthRegPoint.ProfileSettings,
              pathname: location.pathname,
            }),
          );
        }

        if (status === "blocked") {
          return notification.send({
            type: "error",
            text: `Oops! It seems your account is currently blocked, so you're unable to vote. Feel free to reach out to the admin for help or more details.`,
            options: {
              duration: 10000,
              icon: <BlockedIcon />,
            },
          });
        }

        if (!isVerified) {
          return notification.send({
            type: "error",
            text: <EmailNotVerified />,
            options: {
              duration: 10000,
              icon: <ErrorIcon />,
            },
          });
        }

        if (!isApproved) {
          return notification.send({
            type: "error",
            text: "Your profile was not approved. We check your link in bio. It will take no more than 24 hours",
            options: {
              duration: 10000,
              icon: <ErrorIcon />,
            },
          });
        }
        dispatch(setCloseMenuDrawer());
        dispatch(setMysteryCreationOverlay(true));
        break;

      case CONTEXT_ARCHIVE_POST:
        userService.archivePost(item.postId);
        dispatch(
          setPostStatus({
            id: item.postId,
            status: "archive",
          }),
        );
        return dispatch(setCloseMenuDrawer());
      case CONTEXT_UNARCHIVE_POST:
        userService.unarchivePost(item.postId);
        dispatch(
          setPostStatus({
            id: item.postId,
            status: "active",
          }),
        );
        return dispatch(setCloseMenuDrawer());
      default:
        return;
    }
  };

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={visible}
      onClose={() => toggleDrawer(false)}
      onOpen={() => toggleDrawer(true)}
      disableSwipeToOpen={true}
      ModalProps={{
        keepMounted: true,
      }}
    >
      <div className={styles.root}>
        <div className={styles.list}>
          {list &&
            list.map(
              (item, index) =>
                item.visible && (
                  <div
                    className={styles.list_item}
                    key={index}
                    onClick={() => handleItemClick(item)}
                  >
                    {item.icon && item.icon}
                    {item.isSelected && <Tick stroke={"#4285F4"} />}
                    <span
                      style={{
                        color: item.color ? item.color : "#000",
                      }}
                    >
                      {item.text}
                    </span>
                  </div>
                ),
            )}
        </div>

        <div
          className={clsx(styles.button, styles.cancel)}
          onClick={() => toggleDrawer(false)}
        >
          Cancel
        </div>
      </div>
    </SwipeableDrawer>
  );
};

export default MenuDrawer;
