import React, {useEffect, useMemo} from "react";

import styles from "./topControls.module.scss";
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
  currentProfileSelector,
  selectedProfileSelector,
} from "@app/store/slices/profile";
import {ReactComponent as UserMenuIcon} from "@assets/images/user-menu.svg";
import {ReactComponent as PlaceUpIcon} from "@assets/images/challenge-rank-up.svg";
import {ReactComponent as PlaceDownIcon} from "@assets/images/challenge-rank-down.svg";
import {
  clearMenuDrawer,
  setMenuDrawer,
  setOpenMenuDrawer,
} from "@app/store/slices/menuDrawer";
import {
  CONTEXT_ARCHIVE_CONTENT,
  CONTEXT_COMPLAIN_CONTENT,
  CONTEXT_DELETE_CONTENT,
  CONTEXT_EDIT_CONTENT,
  CONTEXT_SHARE_CONTENT,
  CONTEXT_UNARCHIVE_CONTENT,
} from "../../menu-drawer/menuDrawer.constants";
import {ROOT} from "@app/router.constants";
import {IUser} from "@app/store/interfaces/user";
import {ContentStatus} from "@app/store/interfaces/content";
import {IChallenge} from "@app/store/interfaces/challenge";
import getColorByRank from "@utils/getColorByRank";
import {setCloseOverlay} from "@app/store/slices/overlay";

interface IUserInfoProps {
  user?: Partial<IUser>;
  challenge?: Partial<IChallenge>;
  rank?: number;
  rankDelta?: number;
  videoId?: string;
  videoStatus?: ContentStatus;
  activeIndex: number;
  inView: boolean;
  rightButtonVisible: boolean;
  analyticCategory?: string;
  top?: string | number;
  left?: string | number;
}

const TopControls = (props: IUserInfoProps) => {
  const {
    user,
    challenge,
    rank,
    rankDelta = 0,
    videoId,
    videoStatus,
    top,
    left,
    rightButtonVisible = true,
    analyticCategory,
    inView,
  } = props;
  const {uid} = useSelector(currentProfileSelector);
  const navigate = useNavigate();
  const {pathname} = useLocation();

  const isMenuButtonVisible = useMemo(() => {
    return rightButtonVisible && user;
  }, [rightButtonVisible, user]);

  const isCurrentUser = user && user.uid === uid;

  const menuList = useMemo(() => {
    const isArchive = videoStatus === "archive";
    const isBlocked = videoStatus === "blocked";
    const isNew = videoStatus === "new";
    const isFeed = pathname === ROOT;

    return [
      {
        visible: uid && !isCurrentUser,
        text: "Report",
        type: CONTEXT_COMPLAIN_CONTENT,
        userId: user?.uid,
        color: "#FF4848",
        videoId,
      },
      {
        visible: !isBlocked,
        text: "Share video",
        type: CONTEXT_SHARE_CONTENT,
        userId: user?.uid,
        userName: user?.username,
        videoId,
      },
      {
        visible: !isFeed && isCurrentUser && !isBlocked,
        text: "Edit",
        type: CONTEXT_EDIT_CONTENT,
        userId: user?.uid,
        videoId,
      },
      {
        visible: !isFeed && isCurrentUser && !isArchive && !isNew && !isBlocked,
        text: "Archive",
        type: CONTEXT_ARCHIVE_CONTENT,
        userId: user?.uid,
        videoId,
      },
      {
        visible: !isFeed && isCurrentUser && isArchive && !isBlocked,
        text: "Unarchive",
        type: CONTEXT_UNARCHIVE_CONTENT,
        userId: user?.uid,
        videoId,
      },
      {
        visible: !isFeed && isCurrentUser,
        text: "Delete",
        type: CONTEXT_DELETE_CONTENT,
        userId: user?.uid,
        color: "#FF4848",
        videoId,
      },
    ];
  }, [user, uid, videoId, videoStatus, pathname]);

  const dispatch = useDispatch();

  const renderRankDeltaIcon = () => {
    if (rankDelta < 0) {
      return <PlaceUpIcon />;
    }

    if (rankDelta > 0) {
      return <PlaceDownIcon />;
    }

    return null;
  };

  useEffect(() => {
    if (inView && !isMenuButtonVisible) {
      if (user && uid) {
        dispatch(setMenuDrawer(menuList));
      } else {
        dispatch(
          setMenuDrawer([
            {
              visible: true,
              text: "Share video",
              type: CONTEXT_SHARE_CONTENT,
              userName: user?.username,
              videoId,
            },
          ]),
        );
      }
    }
  }, [user, uid, inView, isMenuButtonVisible, menuList, videoId]);

  const handleClickMenu = () => {
    dispatch(clearMenuDrawer());
    if (user && uid) {
      dispatch(setMenuDrawer(menuList));
    } else {
      dispatch(
        setMenuDrawer([
          {
            visible: true,
            text: "Share video",
            type: CONTEXT_SHARE_CONTENT,
            userName: user?.username,
            videoId,
          },
        ]),
      );
    }
    dispatch(setOpenMenuDrawer());
  };

  const handleClickChallenge = (navigateTo: string) => {
    if (pathname === navigateTo) {
      return dispatch(setCloseOverlay());
    }

    return navigate(navigateTo);
  };

  return (
    <div
      className={styles.root}
      style={{
        width: `calc(100% - ${left})`,
        left,
        top,
      }}
    >
      {challenge?.uid && (
        <div
          className={styles.challenge}
          onClick={() => handleClickChallenge(`/challenges/${challenge.alias}`)}
        >
          <div
            className={styles.challenge_place}
            style={{
              backgroundColor: getColorByRank(rank || 10),
              marginRight: rank && rank < 4 ? 8 : 0,
            }}
          >
            №{rank || " –"}
            {renderRankDeltaIcon()}
          </div>
          <div className={styles.challenge_name}>#{challenge.name}</div>
        </div>
      )}
      <div className={styles.menu} onClick={handleClickMenu}>
        <UserMenuIcon />
      </div>
    </div>
  );
};

export default TopControls;
