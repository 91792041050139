export const toPercent = (number: number): number => {
  if (!number && number !== 0) {
    return 0;
  }

  return number * 100;
};

export const roundByDecimals = (value: number, decimals = 2): number => {
  const roundedValue = Number(
    //@ts-ignore
    Math.round(value + "e" + decimals) + "e-" + decimals,
  );

  if (Number.isInteger(roundedValue)) {
    return roundedValue;
  } else {
    return Number(roundedValue.toFixed(decimals));
  }
};
