import {ReactComponent as CloseIcon} from "@assets/images/close.svg";
import styles from "./cookiesPanel.module.scss";
import {useEffect, useState} from "react";
import clsx from "clsx";
import {useNavigate} from "react-router-dom";
import {COOKIES_POLICY} from "@app/router.constants";
import {useAppDispatch} from "@app/store/hooks";
import {setCookieConsent} from "@app/store/slices/profile";

const CookiesPanel = () => {
  const dispatch = useAppDispatch();

  const [hidden, setHidden] = useState(() => {
    return document.cookie.includes("cookieConsent=true");
  });
  const [consentGiven, setConsentGiven] = useState(false);
  const navigate = useNavigate();

  const handleConsentClick = () => {
    document.cookie = `cookieConsent=true; max-age=${12 * 30 * 24 * 60 * 60}`;
    setConsentGiven(true);
    dispatch(setCookieConsent());
  };

  const handleCookiePolicyClick = () => {
    navigate(COOKIES_POLICY);
  };

  useEffect(() => {
    const handleCookieChange = () => {
      const consentCookie = document.cookie.includes("cookieConsent=true");
      setConsentGiven(consentCookie);
      setHidden(consentCookie);
    };

    window.addEventListener("storage", handleCookieChange);

    // Очищаем слушатель при размонтировании компонента
    return () => {
      window.removeEventListener("storage", handleCookieChange);
    };
  }, []);

  return (
    !hidden && (
      <div className={clsx(styles.root, consentGiven && styles.root_hidden)}>
        <div className={styles.text} onClick={handleCookiePolicyClick}>
          <span>
            By browsing this website, you accept our <u>cookie policy.</u>
          </span>
        </div>
        <div className={styles.close} onClick={handleConsentClick}>
          <CloseIcon />
        </div>
      </div>
    )
  );
};

export default CookiesPanel;
