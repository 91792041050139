import React from "react";
import styles from "../notification.module.scss";
import {useNavigate} from "react-router-dom";
import {PROFILE_CURRENT} from "../../../app/router.constants";

const UserNotApproved = () => {
  const navigate = useNavigate();
  const handleMoreClick = () => {
    navigate(PROFILE_CURRENT);
  };
  return (
    <span>
      The video was moderated, but your profile was not approved.&nbsp;
      <br />
      <span onClick={handleMoreClick} className={styles.link}>
        Tap to read more
      </span>
    </span>
  );
};

export default UserNotApproved;
