export enum ServerEvents {
  AppFreeVoteCountdown = "app_free_vote_countdown",
  AppFrontVersion = "app_front_version",
  AppFrontFacebookDisabled = "app_front_facebook_disabled",
  AppFrontEnableRusPayments = "app_front_enable_rus_payments",
  AppFrontInstagramRedirect = "app_front_instagram_redirect",
  AppFrontInstagramRedirectModels = "app_front_instagram_redirect_models",
  AppFrontProfileFeedUserUidList = "app_front_profile_feed_user_uid_list",
  AppFrontUserLandingV4Enabled = "app_front_user_landing_v4_enabled",
}

export interface IServerEvent {
  name: string;
  value: string;
}

export enum NetworkSpeedType {
  Slow = "S",
  Normal = "M",
  Fast = "L",
}
