import styles from "./mute.module.scss";
import {ReactComponent as MuteIcon} from "../../../assets/images/video-mute.svg";
import {ReactComponent as UnmuteIcon} from "../../../assets/images/video-unmute.svg";

import React from "react";

interface IMuteProps {
  muted: boolean;
  toggleMute: () => void;
}

const Mute = (props: IMuteProps) => {
  const {muted, toggleMute} = props;

  return (
    <div className={styles.root} onClick={toggleMute}>
      {muted ? <UnmuteIcon /> : <MuteIcon />}
    </div>
  );
};

export default Mute;
