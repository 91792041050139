import styles from "./deletedScreen.module.scss";
import blockedScreemIcon from "@assets/images/blocked-screen.png";
import Button, {ButtonStyle, ButtonType} from "../button/button";
import {useState} from "react";
import useSignOut from "@hooks/useSignOut";
import {useNavigate} from "react-router-dom";
import {ROOT} from "@app/router.constants";
import {AnalyticIDs} from "@app/store/interfaces/analytics";
import userService from "@services/userService";

interface DeletedScreenProps {
  personalUid: string;
}

const DeletedScreen = (props: DeletedScreenProps) => {
  const {personalUid} = props;
  const [loading, setLoading] = useState(false);
  const signout = useSignOut();
  const navigate = useNavigate();

  const navigateRoot = () => {
    navigate(ROOT);
  };

  const handleClickGoToHomePage = () => {
    setLoading(true);
    userService
      .getCurrentProfile(personalUid)
      .then(() => {
        signout(navigateRoot, setLoading);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div className={styles.content_icon}>
          <img src={blockedScreemIcon} alt="deletedScreemIcon" />
        </div>
        <div className={styles.content_message}>
          Oops! It seems your account is currently deleted
        </div>
      </div>
      <Button
        title="Go to home page"
        type={ButtonType.Outlined}
        style={ButtonStyle.White}
        disabled={loading}
        loading={loading}
        onClick={handleClickGoToHomePage}
        rootStyles={{
          width: 200,
          bottom: "24px",
          padding: "0 24px",
        }}
      />
    </div>
  );
};

export default DeletedScreen;
