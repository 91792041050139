import {createSelector, createSlice} from "@reduxjs/toolkit";
import {AppState} from "../type";
import {IUser} from "../interfaces/user";
import {IContentMysteryBox, IContentPrices} from "../interfaces/content";
import {authOverlaySelector} from "@app/store/slices/authOverlay";
import {isRuSelector} from "@app/store/slices/profile";

interface IVoteDrawerStore {
  prices?: IContentPrices;
  mysteryBoxes?: IContentMysteryBox[];
  user?: Partial<IUser>;
  visible: boolean;
  contentId?: string;
  isStaticView?: boolean;
  timerVisible?: boolean;
}

const initialState: IVoteDrawerStore = {
  contentId: undefined,
  visible: false,
  isStaticView: false,
  timerVisible: true,
};

export const voteDrawerSlice = createSlice({
  name: "voteDrawer",
  initialState,
  reducers: {
    updateFreeDateVoteDrawer: (state, action) => {
      if (state.prices) {
        state.prices.freeVoteDate = {
          ...state.prices.freeVoteDate,
          date: action.payload.date,
        };
      }
    },
    setVoteDrawer: (state, action) => {
      state.prices = action.payload.prices;
      state.user = action.payload.user;
      state.contentId = action.payload.contentId;
      state.timerVisible = action.payload?.timerVisible ?? true;
    },
    setMysteryBoxes: (state, action) => {
      state.mysteryBoxes = action.payload;
    },
    setOpenVoteDrawer: (state, action) => {
      state.visible = true;
      state.isStaticView = action.payload.isStaticView ?? false;
    },
    setCloseVoteDrawer: (state) => {
      state.visible = false;
      state.isStaticView = false;
    },
    clearVoteDrawer: () => {
      return initialState;
    },
  },
});

export const {
  updateFreeDateVoteDrawer,
  setVoteDrawer,
  setMysteryBoxes,
  setOpenVoteDrawer,
  setCloseVoteDrawer,
  clearVoteDrawer,
} = voteDrawerSlice.actions;

export const voteDrawerSelector = (state: AppState) => state.voteDrawer;

export const isVoteDrawerVisibleSelector = createSelector(
  [voteDrawerSelector, authOverlaySelector],
  (voteDrawer, authOverlay) => ({
    isDrawerVisible: voteDrawer.visible && !authOverlay.visible,
    isStaticView: voteDrawer.isStaticView ?? false,
  }),
);

export const voteDrawerPrices = createSelector(
  [voteDrawerSelector, isRuSelector],
  (voteDrawer, isRu) => {
    const {prices} = voteDrawer;

    return {
      ...prices,
      items: prices?.items.filter((item) =>
        isRu ? item.forShowRu : item.forShow,
      ),
    };
  },
);

export const voteDrawerReducer = voteDrawerSlice.reducer;
