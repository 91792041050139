import React, {CSSProperties} from "react";
import {ReactComponent as DateIcon} from "../../assets/images/challenge-date.svg";
import {ReactComponent as TimeIcon} from "../../assets/images/video-challenge-info-timer.svg";
import {ReactComponent as TimeLowIcon} from "../../assets/images/challenge-time-low.svg";
import Countdown, {CountdownRenderProps, zeroPad} from "react-countdown";
import moment from "moment";
import styles from "./timer.module.scss";
import clsx from "clsx";

export enum TimerTheme {
  Light = "light",
  Dark = "dark",
}

interface ITimerProps {
  dateEnd?: string;
  columns?: boolean;
  rows?: boolean;
  small?: boolean;
  visibleHours?: boolean;
  theme?: TimerTheme;
  textStyle?: CSSProperties;
  updateTime?: (timeLeft: number) => void;
  completeTime?: () => void;
}

const Timer = (props: ITimerProps) => {
  const {
    dateEnd,
    columns,
    rows,
    small,
    visibleHours = true,
    textStyle,
    theme = TimerTheme.Light,
    updateTime,
    completeTime,
  } = props;

  const renderIcon = (timer: CountdownRenderProps) => {
    const {days, completed} = timer;

    if (completed) {
      return <DateIcon />;
    }

    if (days) {
      return <TimeIcon />;
    }

    return <TimeLowIcon />;
  };

  const renderer = (timer: CountdownRenderProps) => {
    const {days, hours, minutes, seconds, completed} = timer;
    const isDaysLow = !completed && !days && hours >= 2;
    const isTimesLow = !completed && !days && hours < 2;

    if (columns) {
      const rootStyle = clsx(styles.challenge, {
        [styles.days_low]: isDaysLow,
        [styles.times_low]: isTimesLow,
        [styles.dark]: theme === TimerTheme.Dark,
      });

      return (
        <div className={rootStyle}>
          {renderIcon(timer)}
          {completed ? (
            <div
              style={{
                ...(textStyle || {}),
              }}
            >
              {moment(dateEnd).format("DD/MM/YYYY")}
            </div>
          ) : (
            <span
              className={styles.timer}
              style={{
                ...(textStyle || {}),
              }}
            >
              {days > 0 && <span>{days} days</span>}
              {days === 0 && (
                <>
                  {zeroPad(hours)}:{zeroPad(minutes)}
                  <span>:{zeroPad(seconds)}</span>
                </>
              )}
            </span>
          )}
        </div>
      );
    } else if (rows) {
      const rootStyle = clsx(styles.feed, {
        [styles.days_low]: isDaysLow,
        [styles.times_low]: isTimesLow,
      });

      return (
        <div
          className={rootStyle}
          style={{
            gap: small ? "0px" : "7px",
            lineHeight: small ? "18px" : "normal",
          }}
        >
          <span
            className={styles.top}
            style={{
              fontSize: small ? "14px" : "16px",
            }}
          >
            {completed ? (
              <>{moment(dateEnd).format("DD/MM/YYYY")}</>
            ) : (
              <>
                {days > 0 && <>{zeroPad(days)}d </>}
                {zeroPad(hours)}:{zeroPad(minutes)}
                {days === 0 && <>:{zeroPad(seconds)}</>}
              </>
            )}
          </span>
          <span
            className={styles.bottom}
            style={{
              fontSize: "12px",
            }}
          >
            {completed ? "finished" : "left"}
          </span>
        </div>
      );
    } else {
      return completed ? (
        moment(dateEnd).format("DD/MM/YYYY")
      ) : (
        <span>
          {days > 0 && <>{zeroPad(days)}d </>}
          {visibleHours && <>{zeroPad(hours)}:</>}
          {zeroPad(minutes)}
          {days === 0 && <>:{zeroPad(seconds)}</>}
        </span>
      );
    }
  };

  return (
    <Countdown
      date={dateEnd}
      daysInHours={true}
      renderer={renderer}
      onTick={(timer) => updateTime?.(timer.total)}
      onMount={(timer) => updateTime?.(timer.total)}
      onComplete={() => completeTime?.()}
    />
  );
};

export default Timer;
