import React, {CSSProperties, PropsWithChildren} from "react";

import styles from "./overlay.module.scss";
import clsx from "clsx";

interface IOverlayProps {
  visible: boolean;
  rootStyle?: CSSProperties;
}

const Overlay = (props: PropsWithChildren<IOverlayProps>) => {
  const {visible, rootStyle, children} = props;

  return (
    <div
      className={clsx(styles.root, visible && styles.visible)}
      style={{
        ...(rootStyle || {}),
      }}
    >
      {children}
    </div>
  );
};

export default Overlay;
