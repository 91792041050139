import {useEffect, useState} from "react";
import userService from "@services/userService";
import {MysteryPriceItemType} from "@app/store/interfaces/mystery";

type MysteryBoxPricesType = [boolean, MysteryPriceItemType[], any];

const useMysteryBoxPrices = (visible: string): MysteryBoxPricesType => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<MysteryPriceItemType[]>([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (visible) {
      setLoading(true);
      userService
        .getMysteryPrices()
        .then((response) => {
          if (response.data.items) {
            setData(response.data.items);
          }
        })
        .catch((error) => {
          setError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [visible]);

  return [loading, data, error];
};

export default useMysteryBoxPrices;
