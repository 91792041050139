import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import {ReactComponent as CloseCircleIcon} from "@assets/images/close-circle.svg";
import React, {PropsWithChildren, ReactNode} from "react";

import styles from "./drawer.module.scss";
import Button, {ButtonStyle} from "../button/button";

interface IDrawerProps {
  title: string | ReactNode;
  visible: boolean;
  buttonText?: string;
  buttonClick?: () => void;
  confirmId?: string;
  cancelId?: string;
  onClose: () => void;
  onOpen: () => void;
}

const Drawer = (props: PropsWithChildren<IDrawerProps>) => {
  const {
    title,
    visible,
    buttonText,
    confirmId,
    cancelId,
    buttonClick,
    onClose,
    onOpen,
    children,
  } = props;

  return (
    <SwipeableDrawer
      container={document.body}
      anchor="bottom"
      open={visible}
      onClose={onClose}
      onOpen={onOpen}
      disableSwipeToOpen={true}
      ModalProps={{
        keepMounted: false,
      }}
    >
      <div className={styles.root}>
        <div className={styles.content}>
          <div id={cancelId} className={styles.close} onClick={onClose}>
            <CloseCircleIcon />
          </div>
          <div className={styles.title}>{title}</div>
          {children}
        </div>
        {buttonText && (
          <Button
            id={confirmId}
            title="Yes"
            style={ButtonStyle.Gold}
            onClick={buttonClick ? buttonClick : onClose}
            rootStyles={{
              marginBottom: "24px",
            }}
          />
        )}
      </div>
    </SwipeableDrawer>
  );
};

export default Drawer;
