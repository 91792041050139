import Lottie, {Options} from "react-lottie";
import {CSSProperties, memo, useMemo} from "react";
import coloredHeartsAnimation from "@assets/animations/colored-hearts.json";

export interface IAnimationProps {
  animationDataString?: string;
  animationData?: any;
  isStopped?: boolean;
  autoplay?: boolean;
  loop?: boolean;
  isMeet?: boolean;
  width?: number | string;
  height?: number | string;
  rootStyle?: CSSProperties;
}

const AnimationComponent = (props: IAnimationProps) => {
  const {
    animationDataString,
    animationData,
    isStopped,
    autoplay,
    loop,
    isMeet,
    width,
    height,
    rootStyle,
  } = props;

  const animationDataParsed = useMemo(() => {
    if (!animationDataString) {
      return;
    }

    let parsedJsonAnimation;

    try {
      parsedJsonAnimation = JSON.parse(animationDataString);
    } catch {
      parsedJsonAnimation = coloredHeartsAnimation;
    }

    return parsedJsonAnimation;
  }, [animationDataString]);

  const defaultOptions: Options = useMemo(
    () => ({
      loop: loop || false,
      autoplay: autoplay || false,
      animationData: animationDataParsed || animationData,
      rendererSettings: {
        preserveAspectRatio: isMeet ? "xMidYMid meet" : "xMidYMid slice",
      },
    }),
    [animationData, autoplay, loop, animationDataParsed],
  );

  return (
    <Lottie
      options={defaultOptions}
      width={width || "100%"}
      height={height || "100%"}
      isClickToPauseDisabled={true}
      isStopped={isStopped}
      style={{
        ...rootStyle,
        pointerEvents: "none",
      }}
    />
  );
};

export default memo(AnimationComponent);
