import React from "react";
import FacebookButton from "./components/facebook-button/facebookButton";
import GoogleButton from "./components/google-button/googleButton";

interface ISocialAuthButtons {
  title: string;
  onClickGoogle?: () => void;
  onClickFacebook?: () => void;
}

const SocialAuthButtons = (props: ISocialAuthButtons) => {
  const {title, onClickGoogle, onClickFacebook} = props;

  return (
    <>
      <FacebookButton title={title} onClick={onClickFacebook} />
      <GoogleButton title={title} onClick={onClickGoogle} />
    </>
  );
};

export default SocialAuthButtons;
