import React, {
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import HlsPlayer from "./hls-player/hlsPlayer";
import Preview from "./preview/preview";
import styles from "./videoPlayer.module.scss";
import {useMatch} from "react-router-dom";
import {ReactComponent as SuccessIcon} from "@assets/images/payment-success.svg";
import {ReactComponent as BlockedIcon} from "@assets/images/notification-blocked-white.svg";
import {ReactComponent as ArchiveIcon} from "@assets/images/notification-archive.svg";
import {ReactComponent as ClockIcon} from "@assets/images/notification-clock.svg";
import {ReactComponent as ErrorIcon} from "@assets/images/notification-error-white.svg";

import {ContentStatus, IContent} from "@app/store/interfaces/content";
import useNotification from "@hooks/useNotification";
import {CONTENT} from "@app/router.constants";
import {useAppDispatch, useAppSelector} from "@app/store/hooks";
import {setVoteDrawer, voteDrawerSelector} from "@app/store/slices/voteDrawer";
import {feedSelector, toggleFeedMuted} from "@app/store/slices/feed";
import {
  currentProfileSelector,
  isFullyAuthenticatedSelector,
} from "@app/store/slices/profile";
import contentService from "@services/contentService";
import UserNotApproved from "../notification/templates/userNotApproved";
import SuccessfullyVerified from "../notification/templates/successfullyVerified";
import TopControls from "./top-controls/topControls";
import BottomControls from "./bottom-controls/bottomControls";
import {configSelector} from "@app/store/slices/config";
import {
  authOverlaySelector,
  openAuthOverlay,
  setAuthOverlayContentId,
} from "@app/store/slices/authOverlay";
import {
  WithoutAuthFormType,
  WithoutAuthRegPoint,
} from "@app/store/interfaces/auth";
import {useSelector} from "react-redux";
import {
  fakeLoaderOverlaySelector,
  setFakeLoaderOverlayVisible,
} from "@app/store/slices/fakeLoaderOverlay";
import {
  AnalyticSource,
  IVideoViewedOptions,
} from "@app/store/interfaces/analytics";

type IVideoPlayerProps = Partial<IContent> & {
  videoIndex: number;
  activeIndex: number;
  blockReason?: string;
  isNeedRender?: boolean;
  isNeedLoad?: boolean;
  description?: string;
  traceDebugId: string;
  topControlsTop?: string | number;
  topControlsLeft?: string | number;
  bottomControlsBottom?: string | number;
  bottomControlsVisible?: boolean;
  videoBackVisible?: boolean;
  topControlsVisible?: boolean;
  progressVisible?: boolean;
  defaultAutoPlay?: boolean;
  userRightButtonVisible?: boolean;
  hlsDisabled: boolean;
  notificationHide?: boolean;
  analyticCategory?: string;
  isUserLanding?: boolean;
  playingActive?: boolean;
  setPlayingActive?: () => void;
  onViewed?: (options: IVideoViewedOptions) => void;
  onEnded?: () => void;
};

const VideoPlayer = (props: PropsWithChildren<IVideoPlayerProps>) => {
  const {
    uid,
    status,
    previewUrl,
    url,
    playlists,
    user,
    challenge,
    voteCount = 0,
    sharingCount,
    traceDebugId,
    videoIndex,
    activeIndex,
    description,
    topControlsTop,
    topControlsLeft = 0,
    prices,
    data,
    rank,
    rankDelta,
    bottomControlsBottom,
    blockReason,
    analyticCategory,
    bottomControlsVisible = true,
    topControlsVisible = true,
    progressVisible = true,
    userRightButtonVisible = true,
    defaultAutoPlay = false,
    notificationHide = false,
    isUserLanding = false,
    isNeedRender,
    isNeedLoad,
    hlsDisabled,
    playingActive,
    children,
    onEnded,
    onViewed,
    setPlayingActive,
  } = props;
  const rootRef = useRef<HTMLDivElement>(null);
  const [previewVisible, setPreviewVisible] = useState(true);
  const notification = useNotification();
  const {muted} = useAppSelector(feedSelector);
  const currentUser = useAppSelector(currentProfileSelector);
  const isFullyAuthenticated = useAppSelector(isFullyAuthenticatedSelector);
  const {networkType} = useAppSelector(configSelector);
  const isMuted = useRef<boolean>(muted || false);
  const isContentPage = useMatch(CONTENT);
  const {hasContentId} = useAppSelector(fakeLoaderOverlaySelector);

  const [isAuthOverlayShown, setAuthOverlayShown] = useState(false);
  const dispatch = useAppDispatch();

  const {visible} = useSelector(authOverlaySelector);
  const {visible: isVoteDrawerVisible} = useAppSelector(voteDrawerSelector);

  const isAutoPlay = useMemo(() => {
    if (defaultAutoPlay) {
      return true;
    }
    return activeIndex === videoIndex;
  }, [activeIndex, videoIndex, defaultAutoPlay]);

  const needLoad = useMemo(() => {
    return videoIndex > activeIndex - 2 && videoIndex < activeIndex + 2;
  }, [activeIndex, videoIndex]);

  const videoInfoBottomValue = useMemo(() => {
    if (bottomControlsBottom !== undefined) {
      return bottomControlsBottom;
    } else {
      return "67px";
    }
  }, []);

  const videoSource = useMemo(() => {
    if (playlists && networkType) {
      return playlists[networkType];
    }
  }, [playlists, networkType]);

  const toggleMute = () => {
    isMuted.current = !muted;
    Array.from(document.querySelectorAll("video")).map((item) => {
      item.muted = !muted;
    });
    dispatch(toggleFeedMuted(!muted));
  };

  const handleVideoEnded = () => {
    if (uid) {
      contentService
        .viewContent(uid)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    onEnded && onEnded();
  };

  const handleVideoViewed = () => {
    onViewed &&
      onViewed({
        challengeName: challenge?.name,
        challengeUid: challenge?.uid,
        videoUid: uid,
        model: user?.username,
        modelUid: user?.uid,
        isOnline: user?.online,
        duration: data?.duration,
        viewTime: data?.duration?.toFixed(2),
        rating: rank,
      });
  };

  useEffect(() => {
    if (uid && isAutoPlay) {
      dispatch(setAuthOverlayContentId(uid));
      dispatch(
        setVoteDrawer({
          prices,
          user,
          contentId: uid,
        }),
      );
    }
  }, [uid, isAutoPlay]);

  // show auth form every 3 seconds
  useEffect(() => {
    setAuthOverlayShown(false);

    if (visible || isFullyAuthenticated || isVoteDrawerVisible) {
      return;
    }

    const timeout = setTimeout(() => {
      setAuthOverlayShown(true);
    }, 3000);

    return () => clearTimeout(timeout);
  }, [visible, isFullyAuthenticated, isVoteDrawerVisible]);

  useEffect(() => {
    isAuthOverlayShown &&
      dispatch(
        openAuthOverlay({
          withoutAuthFormType: WithoutAuthFormType.VideoPlayer,
          regPoint: WithoutAuthRegPoint.VideoPlayerTimeout,
        }),
      );
  }, [isAuthOverlayShown]);

  useEffect(() => {
    if (
      status &&
      isAutoPlay &&
      !notificationHide &&
      analyticCategory !== "feed"
    ) {
      if (
        (status === ContentStatus.Active || status === ContentStatus.Past) &&
        !isContentPage
      ) {
        notification.clear();
      }
      if (uid && currentUser.uid === user?.uid) {
        const previousStatus = localStorage.getItem(uid);

        if (status === ContentStatus.Active) {
          if (previousStatus === ContentStatus.New) {
            notification.send({
              type: "message",
              text: <SuccessfullyVerified />,
              options: {
                icon: <SuccessIcon />,
              },
            });
            localStorage.setItem(uid, ContentStatus.Active);
          }
        } else if (status === ContentStatus.New) {
          localStorage.setItem(uid, ContentStatus.New);
        }
      }

      if (status === ContentStatus.Archive) {
        notification.send({
          type: "message",
          text: "Your video has been archived. You can make it active again through the context menu",
          options: {
            icon: <ArchiveIcon />,
          },
        });
      } else if (status === ContentStatus.Blocked) {
        notification.send({
          type: "error",
          text: `The video is blocked. The reason: ${blockReason}`,
          options: {
            icon: <BlockedIcon />,
          },
        });
      } else if (status === ContentStatus.New) {
        notification.send({
          type: "message",
          text: "Your video is under moderation. It will be available in 8 hours",
          options: {
            icon: <ClockIcon />,
          },
        });
      } else if (status === ContentStatus.UserNotApproved) {
        notification.send({
          type: "error",
          text: <UserNotApproved />,
          options: {
            icon: <ErrorIcon />,
          },
        });
      }
    }
  }, [
    status,
    isAutoPlay,
    isContentPage,
    blockReason,
    notificationHide,
    uid,
    analyticCategory,
  ]);

  useEffect(() => {
    if (isMuted) {
      if (!muted && isAutoPlay) {
        isMuted.current = false;
      } else {
        isMuted.current = true;
      }
    }
  }, [muted, isMuted, isAutoPlay]);

  useEffect(() => {
    if (hasContentId) {
      dispatch(setFakeLoaderOverlayVisible(false));
    }
  }, [hasContentId]);

  return (
    <div className={styles.root} ref={rootRef}>
      {/* TODO: filtered background
            {isNeedRender && previewUrl && (
                <div style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    filter: "blur(15px) brightness(0.7)",
                    opacity: "0.6",
                }}>
                    <img src={previewUrl} style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                    }} alt={user?.username}/>
                </div>
            )}
            */}
      {isNeedRender && topControlsVisible && (
        <TopControls
          top={topControlsTop ? topControlsTop : "60px"}
          left={topControlsLeft}
          user={user}
          rightButtonVisible={userRightButtonVisible}
          challenge={challenge}
          rank={rank}
          rankDelta={rankDelta}
          videoId={uid}
          videoStatus={status}
          analyticCategory={analyticCategory}
          inView={isAutoPlay}
          activeIndex={activeIndex}
        />
        // <UserInfo
        //     top={userTop ? userTop : "60px"}
        //     user={user}
        //     isUserLanding={isUserLanding}
        //     rightButtonVisible={userRightButtonVisible}
        //     videoId={uid}
        //     videoStatus={status}
        //     inView={isAutoPlay}
        //     activeIndex={activeIndex}
        // />
      )}
      {isNeedRender && (
        <BottomControls
          visible={bottomControlsVisible}
          voteVisible={status === "active" || status === "active_profile"}
          description={description}
          contentId={uid}
          isUserLanding={isUserLanding}
          bottom={videoInfoBottomValue}
          challenge={challenge}
          voteCount={voteCount}
          rank={rank}
          sharingCount={sharingCount}
          user={user}
          prices={prices}
          muted={muted}
          toggleMute={toggleMute}
          analyticCategory={analyticCategory}
        />
        // <VideoInfo
        //     visible={videoInfoVisible && status === "active"}
        //     description={description}
        //     contentId={uid}
        //     isUserLanding={isUserLanding}
        //     bottom={videoInfoBottomValue}
        //     challenge={challenge}
        //     voteCount={voteCount}
        //     rank={rank}
        //     sharingCount={sharingCount}
        //     user={user}
        //     prices={prices}
        //     muted={muted}
        //     toggleMute={toggleMute}
        // />
      )}
      {/*{isNeedRender && (*/}
      {/*  <Preview*/}
      {/*    src={previewUrl}*/}
      {/*    previewVisible={activeIndex !== videoIndex || previewVisible}*/}
      {/*  />*/}
      {/*)}*/}
      {url && (
        <HlsPlayer
          isMuted={isMuted}
          className={styles.video}
          src={videoSource || url}
          autoPlay={isAutoPlay}
          muted={muted}
          traceDebugId={traceDebugId}
          width={rootRef?.current?.clientWidth}
          loop={true}
          isUserLanding={isUserLanding}
          needLoad={isNeedLoad}
          poster={previewUrl}
          defaultAutoPlay={defaultAutoPlay}
          hlsDisabled={hlsDisabled}
          videoInfoBottom={
            bottomControlsBottom !== undefined ? bottomControlsBottom : "65px"
          }
          setPreview={(value: boolean) => setPreviewVisible(value)}
          onEnded={handleVideoEnded}
          onViewed={handleVideoViewed}
          playingActive={playingActive}
          setPlayingActive={setPlayingActive}
          progressVisible={progressVisible}
          uid={uid}
        />
      )}
      {children}
    </div>
  );
};

export default VideoPlayer;
