import React, {useEffect, useState} from "react";

import styles from "./contentOverlay.module.scss";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import {useDispatch} from "react-redux";
import {
  clearContentOverlay,
  setClose,
  setOpen,
} from "@app/store/slices/contentOverlay";
import {ReactComponent as CloseIcon} from "@assets/images/close.svg";
import contentService from "@services/contentService";
import CircularProgress from "@mui/material/CircularProgress";
import Input from "../input/input";
import VideoPlayer from "../video-player/videoPlayer";
import useNotification from "@hooks/useNotification";
import {v4 as generateUid} from "uuid";
import {toggleFeedMuted} from "@app/store/slices/feed";
import getHeight from "@utils/getHeight";
import {isPWA} from "@utils/detections";
import {updateChallengeDescription} from "@app/store/slices/challenge";
import {updateProfileContentDescription} from "@app/store/slices/profile";

const ContentOverlay = (props) => {
  const {contentId, visible} = props;
  const [description, setDescription] = useState("");
  const [content, setContent] = useState({});
  const [loading, setLoading] = useState(true);
  const notification = useNotification();
  const dispatch = useDispatch();

  const heightContent = getHeight();

  const toggleDrawer = (value) => {
    if (!value) {
      dispatch(setClose());
    } else {
      dispatch(setOpen());
    }
  };
  const handleChange = (event) => {
    setDescription(event.target.value);
  };

  const handleSave = () => {
    const challengeUid = content.challenge.uid;

    if (challengeUid) {
      contentService
        .putContent(contentId, {
          description,
        })
        .then((response) => {
          const updatedDescription = response.data.description;
          dispatch(
            updateChallengeDescription({
              contentId,
              description: updatedDescription,
            }),
          );
          dispatch(
            updateProfileContentDescription({
              contentId,
              description: updatedDescription,
            }),
          );
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setContent({});
          notification.clear();
          dispatch(clearContentOverlay());
        });
    }
  };

  useEffect(() => {
    if (contentId) {
      setLoading(true);
      contentService
        .getContent(contentId)
        .then((response) => {
          if (response.data) {
            setContent(response.data);
            if (response.data.description) {
              setDescription(response.data.description);
            }
          }
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [contentId, dispatch]);

  useEffect(() => {
    if (!visible) {
      notification.clear();
      dispatch(toggleFeedMuted(true));
    }
  }, [visible, dispatch]);

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={visible}
      onClose={() => toggleDrawer(false)}
      onOpen={() => toggleDrawer(true)}
      disableSwipeToOpen={true}
      ModalProps={{
        keepMounted: true,
      }}
    >
      {visible && (
        <div
          className={styles.root}
          style={{
            ...(isPWA()
              ? {}
              : {
                  height: "calc(var(--vh, 1vh) * 100)",
                  minHeight: "calc(var(--vh, 1vh) * 100)",
                }),
          }}
        >
          <div className={styles.header}>
            <div className={styles.close} onClick={() => toggleDrawer(false)}>
              <CloseIcon />
            </div>
            <div className={styles.title}>Edit video</div>
            <div className={styles.save} />
          </div>
          <div className={styles.preview}>
            {!content.previewUrl ? (
              <CircularProgress />
            ) : (
              <>
                {content && (
                  <VideoPlayer
                    {...content}
                    userInfoVisible={false}
                    videoInfoVisible={false}
                    videoInfoBottom={16}
                    progressVisible={false}
                    traceDebugId={generateUid()}
                    userRightButtonVisible={false}
                    hlsDisabled={!content?.url?.includes("m3u8")}
                    previewUrl={content.previewUrl}
                    isNeedRender={true}
                    videoIndex={1}
                    videoBackVisible={false}
                    activeIndex={1}
                  />
                )}
              </>
            )}
          </div>
          <div className={styles.input}>
            <Input
              className={styles.field}
              label="Description"
              name="description"
              value={description ?? ""}
              onChange={handleChange}
            />
          </div>

          <div className={styles.save_button} onClick={handleSave}>
            Save
          </div>
        </div>
      )}
    </SwipeableDrawer>
  );
};

export default ContentOverlay;
