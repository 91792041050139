import {ReactComponent as OopsIcon} from "@assets/images/oops-icon.svg";
import styles from "./errorBoundaryScreen.module.scss";
import Button, {ButtonStyle} from "@components/button/button";
import {useEffect} from "react";
import {logError} from "@services/logger";
import {AnalyticIDs} from "@app/store/interfaces/analytics";
import {
  setFakeBlackLoaderOverlayVisible,
  setFakeLoaderOverlayVisible,
} from "@app/store/slices/fakeLoaderOverlay";
import {useAppDispatch} from "@app/store/hooks";

interface ErrorBoundaryScreenProps {
  error: any;
  type?: "ConnectionError";
}

export const ErrorBoundaryScreen = (props: ErrorBoundaryScreenProps) => {
  const {error, type} = props;
  const dispatch = useAppDispatch();

  useEffect(() => {
    logError(
      {
        response: error,
      },
      "high",
    );
  }, [error]);

  const handleReloadButton = () => {
    window.location.reload();
  };

  // Добавил на всякий случай: чтобы лоадер не перекрывал экран с ошибкой
  useEffect(() => {
    dispatch(setFakeLoaderOverlayVisible(false));
    dispatch(setFakeBlackLoaderOverlayVisible(false));
  }, []);

  const renderMessage = () => {
    if (type === "ConnectionError") {
      return (
        <div>
          <h4>Connection Error</h4>
          <p>
            Error connecting to chat, <br />
            refresh the page to try again.
          </p>
        </div>
      );
    }

    return (
      <div>
        <h4>
          Something <br /> went wrong
        </h4>
        <p>Please reload the page</p>
      </div>
    );
  };

  return (
    <div
      id={
        type !== "ConnectionError"
          ? AnalyticIDs.ErrorShowPage
          : AnalyticIDs.MessengerChatConnectionError
      }
      className={styles.wrapper}
    >
      <OopsIcon />

      {renderMessage()}

      <Button
        rootStyles={{
          width: "100%",
          maxWidth: "384px",
        }}
        style={ButtonStyle.White}
        title={"reload the page"}
        onClick={handleReloadButton}
      />
    </div>
  );
};
