import React, {CSSProperties, PropsWithChildren, ReactNode} from "react";
import styles from "./button.module.scss";
import clsx from "clsx";
import {ReactComponent as SpinnerIcon} from "../../assets/images/spinner.svg";

export enum ButtonType {
  Filled = "filled",
  Outlined = "outlined",
}

export enum ButtonStyle {
  Gold = "gold",
  White = "white",
  Pink = "pink",
  Auth = "auth",
  Text = "text",
}

interface IButtonProps {
  id?: string;
  title?: string;
  type?: ButtonType;
  style?: ButtonStyle;
  iconEnd?: ReactNode;
  iconStart?: ReactNode;
  loading?: boolean;
  disabled?: boolean;
  onClick: (event?: React.MouseEvent<HTMLDivElement>) => void;
  rootStyles?: CSSProperties;
  childrenStyles?: CSSProperties;
}

const Button = (props: PropsWithChildren<IButtonProps>) => {
  const {
    id,
    title,
    iconEnd,
    iconStart,
    loading,
    disabled,
    rootStyles,
    childrenStyles,
    children,
    onClick,
    type = ButtonType.Filled,
    style = ButtonStyle.Gold,
  } = props;

  const rootClassName = clsx(styles.root, {
    [styles.disabled]: disabled || loading,
    [styles.filled]: type === ButtonType.Filled,
    [styles.outlined]: type === ButtonType.Outlined,
    [styles.white]: style === ButtonStyle.White,
    [styles.gold]: style === ButtonStyle.Gold,
    [styles.pink]: style === ButtonStyle.Pink,
    [styles.auth]: style === ButtonStyle.Auth,
    [styles.text]: style === ButtonStyle.Text,
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!disabled && !loading) {
      onClick(event);
    }
  };

  return (
    <div
      id={id}
      className={rootClassName}
      onClick={handleClick}
      style={rootStyles}
    >
      {iconStart && (
        <span className={styles.icon}>
          {loading ? (
            <SpinnerIcon color="#fff" width={20} height={20} />
          ) : (
            <span className={styles.icon_start}>{iconStart}</span>
          )}
        </span>
      )}
      <span className={styles.title} style={childrenStyles}>
        {loading && !iconEnd && !iconStart ? (
          <SpinnerIcon color="#fff" width={20} height={20} />
        ) : (
          title || children
        )}
      </span>
      {iconEnd && (
        <span className={styles.icon}>
          {loading ? (
            <SpinnerIcon color="#fff" width={20} height={20} />
          ) : (
            <span className={styles.icon_end}>{iconEnd}</span>
          )}
        </span>
      )}
    </div>
  );
};

export default Button;
