import {privateApi} from "./api";

const notificationService = {
  getList: async (page = 1) => {
    return await privateApi.get(`/notifications?page=${page}`);
  },
  markAllAsRead: async () => {
    return await privateApi.post(
      `/notifications/mark-all-as-read`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
};

export default notificationService;
