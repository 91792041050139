import {AnalyticSource} from "@app/store/interfaces/analytics";

export const getSourceByPath = (path: string): AnalyticSource => {
  if (path.includes("challenges")) {
    return AnalyticSource.Challenge;
  } else if (path.includes("profile")) {
    return AnalyticSource.Profile;
  }

  return AnalyticSource.Feed;
};
