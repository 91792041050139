import {CSSProperties, ReactNode, useMemo} from "react";
import styles from "./avatar.module.scss";
import userWithoutAvatarBackground, {
  ReactComponent as UserWithoutAvatarIcon,
} from "@assets/images/user-without-avatar.svg";
import clsx from "clsx";
import Skeleton from "../skeleton/skeleton";
import {AvailabilityStatus} from "@app/store/interfaces/onlineStatus";
import {getLastSeenTime} from "@utils/time";
import {ReactComponent as InstagramBadge} from "@assets/images/instagram-badge.svg";
import {ReactComponent as FacebookBadge} from "@assets/images/facebook-badge.svg";
import {ReactComponent as TwitterBadge} from "@assets/images/twitter-badge.svg";
import {ReactComponent as RedditBadge} from "@assets/images/reddit-badge.svg";
import {ReactComponent as YoutubeBadge} from "@assets/images/youtube-badge.svg";
import {ReactComponent as TwitchBadge} from "@assets/images/twitch-badge.svg";
import {ReactComponent as TiktokBadge} from "@assets/images/tiktok-badge.svg";
import {ReactComponent as GoogleBadge} from "@assets/images/goggle-badge.svg";

export enum AvatarSize {
  Feed = "feed",
  Small = "small",
  Normal = "normal",
  Medium = "medium",
  Large = "large",
  ExtraLarge = "extraLarge",
}

interface IAvatarProps {
  isFeed?: boolean;
  url?: string;
  element?: ReactNode;
  status?: AvailabilityStatus;
  lastSeen?: string;
  border?: boolean;
  loading?: boolean;
  size?: AvatarSize;
  rootStyles?: CSSProperties;
  imageStyles?: CSSProperties;
  isSocial?: boolean;
  socialLink?: string;
}

const Avatar = (props: IAvatarProps) => {
  const {
    isFeed,
    url,
    element,
    size,
    status,
    border,
    loading,
    rootStyles,
    imageStyles,
    lastSeen,
    isSocial,
    socialLink,
  } = props;

  const rootClassName = clsx(styles.root, {
    [styles.feed]: size === AvatarSize.Feed,
    [styles.small]: size === AvatarSize.Small,
    [styles.normal]: size === AvatarSize.Normal,
    [styles.medium]: size === AvatarSize.Medium,
    [styles.large]: size === AvatarSize.Large,
    [styles.extra_large]: size === AvatarSize.ExtraLarge,
    [styles.bordered]: border,
  });

  const skeletonTypeByAvatarSize = useMemo(() => {
    switch (size) {
      case AvatarSize.Feed:
        return "feed-circle";
      case AvatarSize.Small:
        return "small-circle";
      case AvatarSize.Normal:
        return "normal-circle";
      case AvatarSize.Medium:
        return "medium-circle";
      case AvatarSize.Large:
        return "large-circle";
      default:
        return "medium-circle";
    }
  }, [size]);

  const SocialBadge: React.FC<{socialLink?: string}> = ({socialLink}) => {
    if (!socialLink) return null;

    switch (socialLink) {
      case "instagram":
        return <InstagramBadge />;
      case "twitter":
        return <TwitterBadge />;
      case "x.com":
        return <TwitterBadge />;
      case "facebook":
        return <FacebookBadge />;
      case "reddit":
        return <RedditBadge />;
      case "youtube":
        return <YoutubeBadge />;
      case "twitch":
        return <TwitchBadge />;
      case "tiktok":
        return <TiktokBadge />;
      case "google":
        return <GoogleBadge />;
      default:
        return null;
    }
  };

  const pastAfterLastSeenTime = getLastSeenTime(lastSeen);

  const renderImage = () => {
    if (url && !element) {
      return <img src={url} alt="avatar" loading="lazy" />;
    } else if (!url && element) {
      return element;
    }

    return <UserWithoutAvatarIcon />;
  };
  return (
    <div className={rootClassName} style={rootStyles}>
      {loading ? (
        <Skeleton type={skeletonTypeByAvatarSize} />
      ) : (
        <>
          <div
            className={styles.image}
            style={{
              background: `url(${userWithoutAvatarBackground})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              alignItems: url ? "stretch" : "center",
              ...imageStyles,
            }}
          >
            {renderImage()}
          </div>
          {isSocial && (
            <div className={styles.insta_badge}>
              <SocialBadge socialLink={socialLink} />
            </div>
          )}
          {status === "online" && (
            <div className={isFeed ? styles.online_feed : styles.online} />
          )}
          {status === "last_seen" && pastAfterLastSeenTime && (
            <div className={styles.last_seen}>{pastAfterLastSeenTime}</div>
          )}
          {status === "last_seen" && !pastAfterLastSeenTime && (
            <div
              className={
                isFeed ? styles.last_seen_badge_feed : styles.last_seen_badge
              }
            >
              {pastAfterLastSeenTime}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Avatar;
