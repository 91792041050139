import {privateApi} from "./api";
import {PaymentProvider} from "../appTypes/payments";
import {AnalyticSource} from "@app/store/interfaces/analytics";
import {SearchParams} from "@appTypes/searchParams";

interface IFeedServiceSetVote {
  contentId: string;
  voteType: string;
  voteCount: number;
  source: AnalyticSource;
  paymentProvider?: PaymentProvider;
}

const feedService = {
  getList: async (page = 1, cUid: string | null) => {
    let params = new URLSearchParams({page: page.toString()});
    if (cUid && page === 1) {
      params.append(SearchParams.ContentUid, cUid);
    }
    let url = `/feed?${params.toString()}`;
    return await privateApi.get(url);
  },
  getVotePrices: async (contentId: string) => {
    return await privateApi.get(`/content/${contentId}/votes/prices`);
  },
  setVote: async ({
    contentId,
    voteType,
    voteCount,
    paymentProvider = "stripe",
    source = AnalyticSource.Other,
  }: IFeedServiceSetVote) => {
    return await privateApi.post(`/content/${contentId}/vote`, {
      type: voteType,
      count: voteCount,
      source,
      paymentProvider,
    });
  },
};

export default feedService;
