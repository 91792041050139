import {useAppDispatch, useAppSelector} from "@app/store/hooks";
import {profileSelector, setReportVisible} from "@app/store/slices/profile";
import userService from "@services/userService";
import styles from "@components/complain-drawer/complainDrawer.module.scss";
import React, {useState} from "react";
import {
  CONTEXT_COMPLAIN_CONTENT,
  CONTEXT_COMPLAIN_CHAT_MESSAGE,
  CONTEXT_COMPLAIN_USER,
} from "@components/menu-drawer/menuDrawer.constants";
import Drawer from "@components/drawer/drawer";
import {ReactComponent as ArrowIcon} from "@assets/images/arrow-right-grey.svg";

const ComplainDrawer = () => {
  const {reportDrawerVisible, reportDrawerType} =
   useAppSelector(profileSelector);
  const dispatch = useAppDispatch();
  const [reportReasonChosen, setReportReasonChosen] = useState(false);

  const handleClose = () => {
    setReportReasonChosen(false);
    dispatch(
     setReportVisible({
       visible: false,
     }),
    );
  };

  const handleOpen = () => {
    dispatch(
     setReportVisible({
       visible: false,
     }),
    );
  };

  const handleItemClick = (label: string) => {
    setReportReasonChosen(true);
    userService
      .reportProfile(label)
      .then(() => {})
      .catch((error) => {
        console.log(error);
      });
  };

  const renderTitle = () =>
   reportReasonChosen ? (
    <>
      Thank you for <br /> reporting!
    </>
   ) : (
    <>
      Why are you <br /> reporting this post?
    </>
   );

  const renderOptions = () => {
    switch (reportDrawerType) {
      case CONTEXT_COMPLAIN_USER:
        return [
          {label: "Intellectual Property Violations"},
          {label: "Inappropriate Content"},
          {label: "Spam or Scam"},
          {label: "Harassment, Bullying, Hate Speech, and Discrimination"},
        ];
      case CONTEXT_COMPLAIN_CONTENT:
        return [
          {label: "Intellectual Property Violations"},
          {label: "Inappropriate Content"},
          {label: "Spam or Scam"},
        ];
      case CONTEXT_COMPLAIN_CHAT_MESSAGE:
        return [
          {label: "Spam or Scam"},
          {label: "Harassment, Bullying, Hate Speech, and Discrimination"},
        ];
      default:
        return [];
    }
  };

  return (
   <Drawer
    visible={reportDrawerVisible || false}
    title={renderTitle()}
    onClose={handleClose}
    onOpen={handleOpen}
   >
     <div className={styles.root}>
       <div
        className={`${styles.list} ${reportReasonChosen ? styles.hidden : ""}`}
       >
         {renderOptions().map((item, index) => (
          <div
           className={styles.list_item}
           key={index}
           onClick={() => handleItemClick(item.label)}
          >
              <span>
                {item.label} <ArrowIcon />
              </span>
          </div>
         ))}
       </div>
       {reportReasonChosen && (
        <div className={styles.item}>
            <span>
              Your report has been <br /> successfully received. We <br />
              appreciate your help in <br /> keeping our community a safe <br />
              and respectful place. Our team <br /> will review your complaint
              as <br /> soon as possible.
            </span>
        </div>
       )}
     </div>
   </Drawer>
  );
};

export default ComplainDrawer;
