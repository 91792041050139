import {getPersonalUidFromLanding} from "@utils/getPersonalUidFromLanding";
import authService from "@services/authService";
import {checkFirebasePermissionAndUpdateToken} from "@utils/pushUtils";
import {generatePath, useLocation, useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "@app/store/hooks";
import {signupSelector} from "@app/store/slices/signup";
import {checkRuByIp, isInAppBrowser} from "@utils/detections";
import {PaymentProvider} from "@appTypes/payments";
import {PaymentName, usePayments} from "@hooks/payments/usePayments";
import {ActionType} from "@pages/user-landing/v3/type";
import userService from "@services/userService";
import {MESSAGES, PROFILE_ITEM, ROOT} from "@app/router.constants";
import {
  authOverlaySelector,
  closeAuthOverlay,
} from "@app/store/slices/authOverlay";
import {useState} from "react";
import {
  isRuSelector,
  setIsAnonym,
  setIsAuthenticated,
} from "@app/store/slices/profile";
import {setFakeLoaderOverlayVisible} from "@app/store/slices/fakeLoaderOverlay";
import {LocalStorageKey} from "@appTypes/localstorage";
import {AnalyticSource} from "@app/store/interfaces/analytics";

export const useAnonymCreation = () => {
  const [paymentLoading, setPaymentLoading] = useState(false);

  const location = useLocation();
  const {country, reffererUserUid} = useAppSelector(signupSelector);
  const isInApp = isInAppBrowser() || true;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {username, userId, actionType, contentId, payment} =
    useAppSelector(authOverlaySelector);

  const pay = usePayments({
    paymentSource: AnalyticSource.Landing,
    setLoading: setPaymentLoading,
  });

  const isRu = useAppSelector(isRuSelector);
  const isRuByIp = checkRuByIp();

  const beforeAnonAuth = () => {
    dispatch(closeAuthOverlay());
    dispatch(setIsAuthenticated(true));
    dispatch(setIsAnonym(true));
  };

  const goToTargetPage = () => {
    if (payment) {
      setPaymentLoading(true);
      const paymentProvider: PaymentProvider = isRu ? "payou" : "stripe";

      if (payment.tipId) {
        pay({
          paymentProvider,
          paymentName: PaymentName.Tips,
          paymentObjectUid: payment.tipId,
        });
      } else if (payment.mysteryBoxId) {
        pay({
          paymentProvider,
          paymentName: PaymentName.MysteryBox,
          paymentObjectUid: payment.mysteryBoxId,
        });
      } else if (payment.realGiftId) {
        pay({
          paymentProvider,
          paymentName: PaymentName.RealGift,
          paymentObjectUid: payment.realGiftId,
        });
      } else if (payment.onlineGiftId) {
        pay({
          paymentProvider,
          paymentName: PaymentName.OnlineGift,
          paymentObjectUid: payment.onlineGiftId,
        });
      }
    } else if (actionType === ActionType.Chat && userId) {
      userService
        .startChat({uid: userId})
        .then((data) => {
          navigate(`${MESSAGES}?channel=${data.data.channelId}`, {
            state: {
              userId,
              visibleFakeChatPageLoading: true,
            },
          });
        })
        .finally(() => {
          beforeAnonAuth();
        });
    } else if (actionType === ActionType.Unlock && userId) {
      userService
        .startHotContentChat({uid: userId})
        .then((data) => {
          navigate(`${MESSAGES}?channel=${data.data.channelId}`, {
            state: {
              userId,
              visibleFakeChatPageLoading: true,
            },
          });
        })
        .finally(() => {
          beforeAnonAuth();
        });
    } else if (contentId) {
      navigate(`${ROOT}?c_uid=${contentId}`);
      beforeAnonAuth();
    } else if (username) {
      const path = generatePath(PROFILE_ITEM, {
        id: username,
      });

      navigate(path);
      beforeAnonAuth();
    }
  };

  const signinByHash = (authHash: string) => {
    return authService
      .authByHash(authHash, "anonym")
      .then((response) => {
        localStorage.setItem(LocalStorageKey.Token, response.data.token);
        localStorage.setItem(
          LocalStorageKey.RefreshToken,
          response.data.refreshToken,
        );
        localStorage.setItem(
          LocalStorageKey.StreamToken,
          response.data.streamToken,
        );
        localStorage.setItem(LocalStorageKey.AnonUserId, response.data.uid);

        dispatch(setIsAuthenticated(true));
        if (response.data.userType === "user") {
          dispatch(setIsAnonym(false));
        } else {
          dispatch(setIsAnonym(true));
        }

        dispatch(closeAuthOverlay());

        goToTargetPage();
      })
      .catch(() => {
        return signupAnonymUser();
      });
  };

  const signupAnonymUser = () => {
    const searchParams = new URLSearchParams(location.search);
    const pathname = window.location.pathname;
    const personalUid = getPersonalUidFromLanding(searchParams, pathname);
    const options = {
      contentId: location?.state?.contentId,
      country:
        country?.value ?? localStorage.getItem(LocalStorageKey.UserCountry),
      userUid: reffererUserUid,
      personalUid: personalUid,
    };
    return authService.signupAnonym(options).then((response) => {
      localStorage.setItem(LocalStorageKey.Token, response.data.token);
      localStorage.setItem(
        LocalStorageKey.RefreshToken,
        response.data.refreshToken,
      );
      localStorage.setItem(
        LocalStorageKey.StreamToken,
        response.data.streamToken,
      );
      localStorage.setItem(LocalStorageKey.AnonUserId, response.data.uid);

      isInApp &&
        localStorage.setItem(LocalStorageKey.AuthHash, response.data.authHash);

      // TODO: push notifications
      checkFirebasePermissionAndUpdateToken();
    });
  };

  const handleAnonymUser = () => {
    if (!(payment || isRuByIp)) {
      dispatch(setFakeLoaderOverlayVisible(true));
    }

    const authHash = localStorage.getItem(LocalStorageKey.AuthHash);

    if (isInApp && authHash) {
      return signinByHash(authHash);
    } else {
      return signupAnonymUser();
    }
  };

  return {
    goToTargetPage,
    handleAnonymUser,
    paymentLoading,
  };
};
