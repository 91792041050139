import React from "react";
import {
  Badge,
  BottomNavigation,
  BottomNavigationAction,
  styled,
} from "@mui/material";
import {useLocation, useMatch, useNavigate} from "react-router-dom";

import {ReactComponent as MenuCreateAccountIcon} from "@assets/images/menu-create-account.svg";
import {ReactComponent as MenuFeedIcon} from "@assets/images/menu-feed.svg";
import {ReactComponent as MenuFeedActiveIcon} from "@assets/images/menu-feed-active.svg";
import {ReactComponent as MenuChallengesIcon} from "@assets/images/menu-challenges.svg";
import {ReactComponent as MenuChallengesActiveIcon} from "@assets/images/menu-challenges-active.svg";
import {ReactComponent as MenuMessagesIcon} from "@assets/images/menu-messages.svg";
import {ReactComponent as MenuMessagesActiveIcon} from "@assets/images/menu-messages-active.svg";
import {ReactComponent as MenuProfileIcon} from "@assets/images/menu-profile.svg";
import {ReactComponent as MenuProfileActiveIcon} from "@assets/images/menu-profile-active.svg";
import {ReactComponent as MenuNotificationIcon} from "@assets/images/menu-notification.svg";
import {ReactComponent as MenuNotificationActiveIcon} from "@assets/images/menu-notification-active.svg";
import {
  CHALLENGE_LIST,
  MESSAGES,
  NOTIFICATION,
  PAYMENTS,
  PROFILE_CURRENT,
  PROFILE_ITEM,
  ROOT,
  SETTINGS,
} from "@app/router.constants";
import styles from "./menu.module.scss";
import {useDispatch, useSelector} from "react-redux";
import {
  currentProfileSelector,
  isAuthenticatedSelector,
  isDesktopChatterSelector,
} from "@app/store/slices/profile";
import clsx from "clsx";
import {openAuthOverlay} from "@app/store/slices/authOverlay";
import {useAppSelector} from "@app/store/hooks";
import {
  WithoutAuthFormType,
  WithoutAuthRegPoint,
} from "@app/store/interfaces/auth";

const StyledBadge = styled(Badge)(() => ({
  "& .MuiBadge-badge": {
    right: 2,
    top: 6,
    border: `0px solid transparent`,
    padding: "0 4px",
    background: "#FF1694",
    display: "flex",
    alignItems: "center",
    fontSize: 12,
    fontWeight: 500,
    color: "#fff",
    minWidth: "20px",
    height: "20px",
  },
}));

const stylesItems = {
  item: {
    minWidth: "45px",
    flex: 0.8,
    padding: 0,
  },
  lastItem: {
    minWidth: "75px",
    flex: 0.8,
    padding: 0,
  },
  vertical: {
    paddingLeft: "8px",
    display: "flex",
    gap: "8px",
    flexDirection: "row",
    justifyContent: "flex-start",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
};

const Menu = (props) => {
  const {onClickItem} = props;
  const {pathname} = useLocation();
  const {notificationCount, messagesCount} = useSelector(
    currentProfileSelector,
  );
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuthenticated = useAppSelector(isAuthenticatedSelector);
  const isDesktopChatter = useAppSelector(isDesktopChatterSelector);
  const isVerticalOrientation = isDesktopChatter && pathname === MESSAGES;
  const isChallengesActive = useMatch({
    path: `${CHALLENGE_LIST}/*`,
    caseSensitive: false,
  });
  const isProfileItemActive = useMatch({
    path: `${PROFILE_ITEM}/*`,
    caseSensitive: false,
  });

  const menuItemStyle = {
    ...stylesItems.item,
    ...(isVerticalOrientation && stylesItems.vertical),
  };

  const menuLastItemStyle = {
    ...stylesItems.lastItem,
    ...(isVerticalOrientation && stylesItems.vertical),
  };

  const isSelectedProfileActive =
    isProfileItemActive && pathname !== PAYMENTS && pathname !== SETTINGS;

  const previousPath =
    location?.state?.from && location?.state?.from.includes("challenges")
      ? location?.state?.from
      : ROOT;

  const renderLabel = (text, count) => {
    return isVerticalOrientation ? (
      <div className={styles.menu_label_container}>
        <div
          className={clsx(
            styles.menu_label_container_text,
              text === "CHAT" &&
              styles.menu_label_container_text_active,
          )}
        >
          {text}
        </div>
        {count > 0 && (
          <div className={styles.menu_label_container_badge}>{count}</div>
        )}
      </div>
    ) : (
      text
    );
  };

  const notificationIcon = () => {
    if (notificationCount && notificationCount > 0 && !isVerticalOrientation) {
      return (
        <StyledBadge badgeContent={notificationCount} color="success">
          {pathname === NOTIFICATION ? (
            <MenuNotificationActiveIcon className={styles.menu_icon} />
          ) : (
            <MenuNotificationIcon className={styles.menu_icon} />
          )}
        </StyledBadge>
      );
    } else {
      return pathname === NOTIFICATION ? (
        <MenuNotificationActiveIcon className={styles.menu_icon} />
      ) : (
        <MenuNotificationIcon className={styles.menu_icon} />
      );
    }
  };

  const messagesIcon = () => {
    if (messagesCount && messagesCount > 0 && !isVerticalOrientation) {
      return (
        <StyledBadge badgeContent={messagesCount} color="success">
          {pathname === MESSAGES ? (
            <MenuMessagesActiveIcon className={styles.menu_icon} />
          ) : (
            <MenuMessagesIcon className={styles.menu_icon} />
          )}
        </StyledBadge>
      );
    } else {
      return pathname === MESSAGES ? (
        <MenuMessagesActiveIcon className={styles.menu_icon} />
      ) : (
        <MenuMessagesIcon className={styles.menu_icon} />
      );
    }
  };

  const handleCreateAccount = () => {
    dispatch(
      openAuthOverlay({
        withoutAuthFormType: WithoutAuthFormType.None,
        regPoint: WithoutAuthRegPoint.CreateAccountBtn,
        pathname,
      }),
    );
  };

  return (
    <BottomNavigation
      style={{
        backgroundColor: "#000000BF",
        height: isVerticalOrientation ? "300px" : "56px",
        position: "absolute",
        width: isVerticalOrientation ? "150px" : "100%",
        maxWidth: isVerticalOrientation ? "unset" : "420px",
        zIndex: 1000,
        bottom: 0,
        left: 0,
        right: isVerticalOrientation ? "unset" : 0,
        margin: "auto",
        flexDirection: isVerticalOrientation ? "column" : "row",
      }}
      showLabels
      value={"Feed"}
    >
      <BottomNavigationAction
        id="menu_feed_clk_btn"
        label={renderLabel("FEED", 0)}
        className={styles.menu}
        icon={
          pathname === ROOT ||
          (isSelectedProfileActive && previousPath === ROOT) ? (
            <MenuFeedActiveIcon className={styles.menu_icon} />
          ) : (
            <MenuFeedIcon className={styles.menu_icon} />
          )
        }
        onClick={() => onClickItem(ROOT)}
        style={menuItemStyle}
      />
      <BottomNavigationAction
        id="menu_challenges_clk_btn"
        label={renderLabel("CHALLENGES", 0)}
        className={styles.menu}
        icon={
          isChallengesActive ||
          (isSelectedProfileActive && previousPath.includes("challenges")) ? (
            <MenuChallengesActiveIcon className={styles.menu_icon} />
          ) : (
            <MenuChallengesIcon className={styles.menu_icon} />
          )
        }
        onClick={() => onClickItem(CHALLENGE_LIST)}
        style={menuItemStyle}
      />
      {isAuthenticated ? (
        <BottomNavigationAction
          id="menu_profile_clk_btn"
          label={renderLabel("PROFILE", 0)}
          className={styles.menu}
          icon={
            pathname === PROFILE_CURRENT ||
            pathname === SETTINGS ||
            pathname === PAYMENTS ? (
              <MenuProfileActiveIcon className={styles.menu_icon} />
            ) : (
              <MenuProfileIcon className={styles.menu_icon} />
            )
          }
          onClick={() => onClickItem(PROFILE_CURRENT)}
          style={menuItemStyle}
        />
      ) : (
        <BottomNavigationAction
          id="menu_profile_clk_btn"
          label={renderLabel("CREATE ACCOUNT", 0)}
          className={clsx(styles.menu, styles.menu_create)}
          icon={
            <MenuCreateAccountIcon
              className={clsx(styles.menu_icon, styles.menu_icon_create)}
            />
          }
          onClick={handleCreateAccount}
          style={menuItemStyle}
        />
      )}
      <BottomNavigationAction
        id="menu_messenger_clk_btn"
        label={renderLabel("CHAT", messagesCount)}
        className={styles.menu}
        icon={messagesIcon()}
        onClick={() => onClickItem(MESSAGES)}
        style={menuItemStyle}
      />
      {isAuthenticated && (
        <BottomNavigationAction
          id="menu_notifications_clk_btn"
          label={renderLabel("NOTIFICATIONS", notificationCount)}
          className={styles.menu}
          icon={notificationIcon()}
          onClick={() => onClickItem(NOTIFICATION)}
          style={menuLastItemStyle}
        />
      )}
    </BottomNavigation>
  );
};

export default Menu;
