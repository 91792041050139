import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import clsx from "clsx";
import {ReactComponent as VisibilityIcon} from "../../assets/images/eye.svg";
import {ReactComponent as VisibilityOffIcon} from "../../assets/images/eye-slash.svg";
import TextareaAutosize from "react-textarea-autosize";

import "./input.scss";

const Input = (props) => {
  const {
    type = "text",
    autocomplete = "on",
    multiline = false,
    value = "",
    label,
    labelTextNotFocused,
    name,
    error,
    borderStyle = "gold",
    borderRadius = 25,
    onFocus,
    onBlur,
    onChange,
    onEnter,
    maxLength = 200,
    showCharacterCount = false,
    disabled = false,
    minRows,
    minValue = 0,
    className,
    backgroundColor,
  } = props;
  const [isFocused, setIsFocused] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  const inputRef = useRef(null);
  const [charCount, setCharCount] = useState(value.length);

  const rootStyle = clsx("input", {
    [className]: className,
    textarea: multiline,
    password: type === "password",
    focused: isFocused,
    filled: inputValue,
    gold: borderStyle === "gold",
    white: borderStyle === "white",
    error: error,
  });

  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const inputType = useMemo(() => {
    if (type === "password") {
      return showPassword ? "text" : "password";
    }

    return type;
  }, [type, showPassword]);

  const handleLabelClick = useCallback(() => {
    inputRef?.current.focus();
  }, [inputRef]);

  const handleFocus = (event) => {
    setIsFocused(true);
    onFocus?.(event);
  };

  const handleBlur = (event) => {
    setIsFocused(false);
    onBlur?.(event);
  };

  const handleChange = (event) => {
    const inputValue = event.target.value?.slice(0, maxLength);
    event.target.value = inputValue;
    setInputValue(inputValue);
    setCharCount(value.length);
    onChange?.(event);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onEnter?.();
    }

    if (
      type === "number" &&
      (event.code === "KeyE" ||
        event.code === "Minus" ||
        event.code === "Equal")
    ) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    setInputValue(value);
    setCharCount(value.length);
  }, [value]);

  const isLabelDisplayed = () => {
    return isFocused || inputValue || !labelTextNotFocused
      ? label
      : labelTextNotFocused;
  };

  return (
    <div className={rootStyle}>
      <label
        style={{
          color: disabled && "gray",
        }}
        onClick={handleLabelClick}
      >
        {isLabelDisplayed()}
        {(isFocused || inputValue) && showCharacterCount && (
          <div className="character_count">{`${charCount}/${maxLength}`}</div>
        )}
      </label>
      {multiline && (isFocused || inputValue) && (
        <>
          <div className="label_background" style={{backgroundColor}} />
        </>
      )}
      {multiline ? (
        <TextareaAutosize
          ref={inputRef}
          name={name}
          value={inputValue}
          disabled={disabled}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          minRows={minRows || 6}
          maxRows={100}
        />
      ) : (
        <input
          {...(type === "number" ? {min: minValue} : {})}
          maxLength={maxLength || 150}
          ref={inputRef}
          type={inputType}
          name={name}
          value={inputValue}
          disabled={disabled}
          autoComplete={autocomplete}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          style={{
            color: disabled && "gray",
            ...(borderRadius ? {borderRadius} : {}),
          }}
        />
      )}
      {type === "password" && (
        <div
          className="visibility"
          onClick={handleClickShowPassword}
          onMouseDown={handleMouseDownPassword}
        >
          {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
        </div>
      )}
    </div>
  );
};

export default Input;
