import {SearchParams} from "@appTypes/searchParams";

export const getPersonalUidFromLanding = (
  searchParams: URLSearchParams,
  pathname: string,
): string | null => {
  let personalUid = searchParams.get(SearchParams.PersonalUid);
  if (!personalUid && pathname) {
    const uidMatch = /\/[^/]+\/([^/?]+)/.exec(pathname);
    personalUid = uidMatch ? uidMatch[1] : null;
  }
  return personalUid;
};
