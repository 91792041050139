import React, {useState} from "react";
import styles from "../notification.module.scss";
import Button, {ButtonStyle} from "../../button/button";
import useNotification from "@hooks/useNotification";
import {useAppSelector} from "@app/store/hooks";
import {currentProfileSelector} from "@app/store/slices/profile";
import {ReactComponent as ResendLinkWhiteIcon} from "@assets/images/resend-link-white.svg";
import authService from "@services/authService";

interface Props {
  text?: string;
}

const EmailNotVerified = (props: Props) => {
  const {
    text = "Email verification is pending. You won't be able to vote until your email is confirmed.",
  } = props;

  const [readMoreVisible, setReadMoreVisible] = useState(true);
  const [loadingResend, setLoadingResend] = useState(false);
  const {email} = useAppSelector(currentProfileSelector);
  const notification = useNotification();

  const handleResendClick = () => {
    setLoadingResend(true);
    authService
      .resendLink(email)
      .then(() => {
        notification.send({
          type: "message",
          text: "A confirmation link has been sent to your email.",
          options: {
            icon: <ResendLinkWhiteIcon />,
            duration: 3000,
          },
        });
      })
      .catch(({response}) => {
        notification.send({
          type: "error",
          text:
            response?.data?.message ||
            response?.data?.error ||
            "Something went wrong.",
          options: {
            icon: <ResendLinkWhiteIcon />,
            duration: 3000,
          },
        });
      })
      .finally(() => {
        setLoadingResend(false);
      });
  };
  const handleMoreClick = () => {
    setReadMoreVisible(false);
  };
  return (
    <span>
      {text}
      {readMoreVisible && (
        <>
          <br />
          <span onClick={handleMoreClick} className={styles.link}>
            Tap to read more
          </span>
        </>
      )}
      {!readMoreVisible && (
        <>
          Resend confirmation link?
          <Button
            loading={loadingResend}
            disabled={loadingResend}
            onClick={handleResendClick}
            style={ButtonStyle.White}
            rootStyles={{
              marginTop: 16,
              height: 40,
            }}
          >
            <span
              style={{
                color: "#000",
                textTransform: "uppercase",
                fontSize: "12px",
                fontWeight: 800,
              }}
            >
              Resend link
            </span>
          </Button>
        </>
      )}
    </span>
  );
};

export default EmailNotVerified;
