export default function getColorByRank(rank?: number): string {
  switch (rank) {
    case 1:
      return "#FFEF5B";
    case 2:
      return "#BCBCBC";
    case 3:
      return "#FFBB6C";
    default:
      return "#FFFFFF";
  }
}
