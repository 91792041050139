import styles from "@components/vote-drawer/voteDrawer.module.scss";
import React, {useEffect, useRef, useState} from "react";
import {IContentMysteryBox, IContentPrice} from "@app/store/interfaces/content";
import {ReactComponent as ArrowIcon} from "@assets/images/vote-arrow.svg";
import {ReactComponent as VoteVideoIcon} from "@assets/images/vote-pack-videos.svg";
import {ReactComponent as VoteImageIcon} from "@assets/images/vote-pack-photos.svg";
import {ReactComponent as VoteAudioIcon} from "@assets/images/vote-pack-audios.svg";
import CircularProgress from "@mui/material/CircularProgress";
import clsx from "clsx";
import {getPrice} from "@utils/getPrice";
import Avatar, {AvatarSize} from "@components/avatar/avatar";
import {
  AnalyticIDs,
  AnalyticProductType,
  AnalyticSource,
  IPaymentOfferViewedOptions,
} from "@app/store/interfaces/analytics";
import {PaymentName, usePayments, VoteType} from "@hooks/payments/usePayments";
import {useAppSelector} from "@app/store/hooks";
import {isRuSelector} from "@app/store/slices/profile";
import getButtonColorByVote from "@utils/getButtonColorByVote";
import {voteDrawerSelector} from "@app/store/slices/voteDrawer";
import Amplitude from "@utils/amplitude";
import {getPaymentOfferViewedOptions} from "@utils/getPaymentOfferViewedOptions";
import {PaymentProvider} from "@appTypes/payments";
import {useLocation} from "react-router-dom";
import {getSourceByPath} from "@utils/getSourceByPath";

interface IHandleClickVote {
  type: string;
  value: number;
  priceRu: number;
}

interface IVoteDrawerPriceProps {
  price: IContentPrice;
}

const VoteDrawerPrice = (props: IVoteDrawerPriceProps) => {
  const {price} = props;
  const {mysteryBoxes, user, contentId} = useAppSelector(voteDrawerSelector);
  const {pathname} = useLocation();
  const [loading, setLoading] = useState(false);

  const isPaymentOfferViewedSent = useRef(false);

  const isRu = useAppSelector(isRuSelector);

  const paymentProvider: PaymentProvider = isRu ? "payou" : "stripe";

  const pay = usePayments({
    setLoading,
    paymentSource: AnalyticSource.Feed,
  });

  const getButtonStyle = (votes: number) => {
    return {
      fontSize: 20,
      background: getButtonColorByVote(votes),
    };
  };

  const getMysteryBoxByPriceType = (type: string) => {
    if (mysteryBoxes?.length) {
      return mysteryBoxes.find((box) => box.type === type);
    }

    return false;
  };

  const getMysteryBoxIconByPriceType = (type: string) => {
    switch (type) {
      case "L":
        return (
          <div className={clsx(styles.mystery_box, styles.mystery_box_l)} />
        );
      case "M":
        return (
          <div className={clsx(styles.mystery_box, styles.mystery_box_m)} />
        );
      default:
        return (
          <div className={clsx(styles.mystery_box, styles.mystery_box_s)} />
        );
    }
  };

  const handleClickVote = async ({value, type, priceRu}: IHandleClickVote) => {
    if (contentId) {
      setLoading(true);

      pay({
        paymentName: PaymentName.AddVotes,
        paymentProvider: isRu ? "payou" : "stripe",
        paymentObjectUid: contentId,
        voteCount: value,
        voteType: type as VoteType,
      });
    }
  };

  const mysteryBox = getMysteryBoxByPriceType(price.type);

  const renderPriceItem = () => {
    if (loading) {
      return (
        <CircularProgress
          sx={{
            color: "#ff1694 !important",
          }}
          size={60}
          thickness={2}
        />
      );
    }

    if (mysteryBox) {
      return (
        <>
          <div
            className={clsx(styles.packs_item, styles.packs_item_mystery)}
            onClick={() =>
              handleClickVote({
                type: "paid",
                value: price.votes,
                priceRu: price.priceRu,
              })
            }
          >
            <div
              className={styles.votes}
              style={{
                ...getButtonStyle(price.votes),
              }}
            >
              {`+${price.votes}`}
            </div>
            <div className={styles.price}>
              <span>BUY</span>
              <span className={styles.currency}>
                {getPrice(isRu ? price.priceRu : price.price, isRu)}
              </span>
            </div>
          </div>
          <div className={styles.separator}>+</div>
          <div className={styles.mystery}>
            <div className={styles.mystery_box}>
              {getMysteryBoxIconByPriceType(price.type)}
              <div className={styles.mystery_avatar}>
                <Avatar url={user?.avatar} border={true} />
              </div>
            </div>
            <div className={styles.mystery_counts}>
              <div className={styles.mystery_counts_item}>
                {mysteryBox.videoCount}
                <VoteVideoIcon />
              </div>
              <div className={styles.mystery_counts_item}>
                {mysteryBox.imageCount}
                <VoteImageIcon />
              </div>
              <div className={styles.mystery_counts_item}>
                {mysteryBox.audioCount}
                <VoteAudioIcon />
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <div
          className={styles.packs_item}
          onClick={() =>
            handleClickVote({
              type: "paid",
              value: price.votes,
              priceRu: price.priceRu,
            })
          }
        >
          <div
            className={styles.votes}
            style={{
              ...getButtonStyle(price.votes),
            }}
          >
            {`+${price.votes}`}
          </div>
          <div className={styles.price}>
            <span>BUY</span>
            <span className={styles.currency}>
              {getPrice(isRu ? price.priceRu : price.price, isRu)}
            </span>
          </div>
          <div className={styles.arrow}>
            <ArrowIcon />
          </div>
          <div className={styles.avatar}>
            <Avatar url={user?.avatar} size={AvatarSize.Large} border={true} />
          </div>
        </div>
      );
    }
  };

  useEffect(() => {
    if (!isPaymentOfferViewedSent.current && user?.uid && price?.votes) {
      let options: IPaymentOfferViewedOptions = {
        billing: paymentProvider,
        originalCurrency: isRu ? "RUB" : "USD",
        originalPrice: isRu ? price?.priceRu : price?.price,
        isOnline: user?.online,
        model: user?.username,
        modelUid: user?.uid,
        votesCount: price?.votes,
        price: price?.price,
        type: AnalyticProductType.Votes,
        subtype: price?.type,
        source: getSourceByPath(pathname),
        videoUid: contentId,
      };

      if (mysteryBox) {
        options = {
          ...options,
          title: "Mystery Box",
          description: mysteryBox?.description,
          productUid: mysteryBox?.uid,
          audioCount: Number(mysteryBox?.audioCount ?? 0),
          imageCount: Number(mysteryBox?.imageCount ?? 0),
          videoCount: Number(mysteryBox?.videoCount ?? 0),
        };
      }

      isPaymentOfferViewedSent.current = true;
      Amplitude.track(
        AnalyticIDs.PaymentOfferViewed,
        getPaymentOfferViewedOptions(options),
      );
    }
  }, [price, isRu]);

  return <div className={styles.packs_wrapper}>{renderPriceItem()}</div>;
};

export default VoteDrawerPrice;
