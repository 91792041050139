import {useState} from "react";
import useValidate from "./useValidate";

interface IValidationErrors {
  name: string;
  email: string;
  password: string;
  repeatPassword: string;
}

const useValidation = (state: any) => {
  const [values, setValues] = useState(state);
  const [errors, setErrors] = useState<IValidationErrors>(
    {} as IValidationErrors,
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validate = useValidate();

  const handleChange = (event: any) => {
    const {name, value} = event.target;
    const newValues = {
      ...values,
      [name]: value,
    };
    setValues(newValues);
    const validationErrors = validate(newValues);
    setErrors(validationErrors as IValidationErrors);
  };

  const handleSubmit = (event: any) => {
    if (event) event.preventDefault();
    const validationErrors = validate(values);
    setErrors(validationErrors as IValidationErrors);
    setIsSubmitting(true);
  };

  const handleClear = (values: any) => {
    setValues(values);
    setErrors({} as IValidationErrors);
  };

  return {
    values,
    errors,
    isSubmitting,
    handleChange,
    handleSubmit,
    handleClear,
  };
};

export default useValidation;
