import {AnalyticIDs} from "../../../app/store/interfaces/analytics";

const SuccessfullyVerified = () => {
  return (
    <span id={AnalyticIDs.VerificationSuccessShowPage}>
      We successfully verified the ChallengeTV link in the instagram account.
      Congratulations, your video is now open for public voting in the Challenge
    </span>
  );
};

export default SuccessfullyVerified;
