import styles from "./ageConfirmation.module.scss";
import tearIcon from "@assets/images/smile-tear.png";
import lipsIcon from "@assets/images/lips-icon.png";
import {ReactComponent as UserWithoutAvatarIcon} from "@assets/images/user-without-avatar.svg";
import Button, {ButtonStyle, ButtonType} from "../../../button/button";
import React, {useCallback, useState} from "react";
import {useDispatch} from "react-redux";
import {FormState} from "@app/store/interfaces/auth";
import {
  authOverlaySelector,
  changeAuthOverlayFormState,
} from "@app/store/slices/authOverlay";
import {AnalyticIDs} from "@app/store/interfaces/analytics";
import authService from "../../../../services/authService";
import {useAnonymCreation} from "@components/auth-overlay/hooks/useAnonymCreation";
import {useAppSelector} from "@app/store/hooks";
import {checkRuByIp} from "@utils/detections";
import {LocalStorageKey} from "@appTypes/localstorage";
import Amplitude from "@utils/amplitude";
import {setIsAnonym, setIsAuthenticated} from "@app/store/slices/profile";
import {ActionType} from "@pages/user-landing/v3/type";

const AgeConfirmation = () => {
  const [isUnder18, setIsUnder18] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const {isWithoutAuth, actionType, displayName, avatar} =
    useAppSelector(authOverlaySelector);

  const isRuByIp = checkRuByIp();

  const openUnder18 = useCallback(() => {
    setIsUnder18(true);
  }, []);

  const closeUnder18 = useCallback(() => {
    setIsUnder18(false);
  }, []);

  const {handleAnonymUser, goToTargetPage, paymentLoading} =
    useAnonymCreation();

  const confirmAge = useCallback(() => {
    setIsLoading(true);
    Amplitude.track(AnalyticIDs.AgeConfirmed, {
      path: window.location.pathname,
    });
    authService
      .confirmOver18()
      .then(() => {
        if (isWithoutAuth) {
          handleAnonymUser().then(() => {
            setIsLoading(false);
            dispatch(setIsAuthenticated(true));
            dispatch(setIsAnonym(true));
            if (isRuByIp) {
              dispatch(changeAuthOverlayFormState(FormState.CountrySelection));
            } else {
              goToTargetPage();
            }
          });
        } else {
          if (isRuByIp) {
            dispatch(changeAuthOverlayFormState(FormState.CountrySelection));
          } else {
            dispatch(changeAuthOverlayFormState(FormState.Auth));
          }
        }

        localStorage.setItem(LocalStorageKey.IsAgeConfirmed, "true");
      })
      .catch((error) => {
        console.error("Error during age confirmation attempt:", error);
      })
      .finally(() => {
        console.log("Age confirmation attempt completed");
      });
  }, [dispatch, handleAnonymUser, isWithoutAuth, isRuByIp]);

  const renderContent = () => {
    if (actionType === ActionType.Chat) {
      return (
        <div className={styles.content}>
          <div className={styles.user}>
            <div className={styles.user_avatar}>
              {avatar ? (
                <img src={avatar} alt="user avatar" />
              ) : (
                <UserWithoutAvatarIcon />
              )}
              <div className={styles.user_status} />
            </div>
            <div className={styles.user_name}>
              <p>{displayName}</p>
            </div>
          </div>
          <div className={styles.messages}>
            <div className={styles.messages_item}>Hello darling!❤️</div>
            <div className={styles.messages_item}>
              Please confirm your age <br />
              to begin our 🌶️ chat
            </div>
          </div>
        </div>
      );
    }

    if (actionType === ActionType.Unlock) {
      return (
        <div className={styles.content}>
          <div className={styles.user}>
            <div className={styles.user_avatar}>
              {avatar ? (
                <img src={avatar} alt="user avatar" />
              ) : (
                <UserWithoutAvatarIcon />
              )}
              <div className={styles.user_status} />
            </div>
            <div className={styles.user_name}>
              <p>{displayName}</p>
            </div>
          </div>
          <div className={styles.messages}>
            <div className={styles.messages_item}>Hi❤️</div>
            <div className={styles.messages_item}>
              My content’s seriously 🔥!
            </div>
            <div className={styles.messages_item}>Confirm you’re 18 😉</div>
          </div>
        </div>
      );
    }

    return (
      <>
        <div className={styles.iconWrapper}>
          <img className={styles.icon} src={lipsIcon} alt="lips icon" />
        </div>
        <h2>
          Please confirm <br /> your age
        </h2>
      </>
    );
  };
  return (
    <div className={styles.wrapper}>
      {isUnder18 ? (
        <>
          <div className={styles.iconWrapper}>
            <img className={styles.icon} src={tearIcon} alt="lips icon" />
          </div>
          <h2>
            Sorry, but access to TheChallengeTV is only available for users{" "}
            <br />
            over 18 years old
          </h2>
        </>
      ) : (
        renderContent()
      )}

      <div className={styles.buttonsWrapper}>
        {isUnder18 ? (
          <Button
            onClick={closeUnder18}
            style={ButtonStyle.White}
            type={ButtonType.Outlined}
            rootStyles={{width: "160px", background: "transparent"}}
          >
            <span className={styles.buttonText}>OK</span>
          </Button>
        ) : (
          <>
            <Button
              onClick={openUnder18}
              style={ButtonStyle.White}
              type={ButtonType.Outlined}
              rootStyles={{width: "100%"}}
            >
              <span className={styles.buttonText}>
                <span>{"< "}</span>18
              </span>
            </Button>
            <Button
              onClick={confirmAge}
              style={ButtonStyle.Pink}
              rootStyles={{width: "100%"}}
              loading={isLoading || paymentLoading}
            >
              <span className={styles.buttonText} style={{color: "#fff"}}>
                18+
              </span>
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default AgeConfirmation;
