import React, {CSSProperties, PropsWithChildren, ReactNode} from "react";
import styles from "./pageTitle.module.scss";
import {ReactComponent as BackIcon} from "@assets/images/back.svg";
import {useNavigate} from "react-router-dom";
import {ReactComponent as UserApprovedIcon} from "@assets/images/profile-approved.svg";
import {ReactComponent as UserVerifiedIcon} from "@assets/images/profile-verified.svg";
import clsx from "clsx";
import {useSelector} from "react-redux";
import {profileSelector} from "@app/store/slices/profile";

interface IPageTitleProps {
  title?: string;
  titleStyle?: CSSProperties;
  backClick?: () => void;
  backTo?: string;
  backButtonVisible?: boolean;
  backStyle?: CSSProperties;
  backCounter?: ReactNode;
  rootStyle?: CSSProperties;
  button?: ReactNode;
  buttonVisible?: boolean;
  buttonStyle?: CSSProperties;
  buttonClick?: () => void;
  label?: "blue" | "white" | string;
  iconSize?: number;
  isVerified?: boolean;
  isApproved?: boolean;
  isChannelHeader?: boolean;
}

const PageTitle = (props: PropsWithChildren<IPageTitleProps>) => {
  const {
    title,
    backTo,
    backButtonVisible,
    backStyle,
    backCounter,
    titleStyle,
    button,
    buttonVisible = true,
    buttonStyle,
    rootStyle,
    buttonClick,
    backClick,
    label,
    iconSize = 24,
    isVerified,
    isApproved,
    children,
    isChannelHeader,
  } = props;
  const navigate = useNavigate();
  const {isVisitProfileBannerViewedFiveTimes} = useSelector(profileSelector);
  const renderUserIcon = () => {
    if (label) {
      return label === "blue" ? (
        <UserApprovedIcon width={iconSize} height={iconSize} />
      ) : (
        <UserVerifiedIcon width={iconSize} height={iconSize} />
      );
    } else {
      if (isVerified && isApproved) {
        return <UserApprovedIcon width={iconSize} height={iconSize} />;
      }

      if (isVerified && !isApproved) {
        return <UserVerifiedIcon width={iconSize} height={iconSize} />;
      }
    }

    return <></>;
  };

  const handleBackClick = () => {
    if (backClick) {
      backClick();
    } else {
      // @ts-ignore-line
      navigate(backTo || -1);
    }
  };

  const renderTitle = () => {
    if (title === "Notifications" || title === "Challenges") {
      return title;
    } else {
      return <span className={styles.titleText}>{title}</span>;
    }
  };

  return (
    <div
      className={styles.root}
      style={{
        boxSizing: "content-box",
        ...(rootStyle || {}),
      }}
    >
      <div
        className={styles.left}
        style={backStyle || {}}
        onClick={handleBackClick}
      >
        {backButtonVisible && <BackIcon fill={"white"} />}
        {backCounter !== undefined && backCounter}
      </div>
      <h1
        className={clsx(
          isChannelHeader && !isVisitProfileBannerViewedFiveTimes
            ? styles.visit_profile
            : styles.center,
        )}
        style={titleStyle || {}}
      >
        {renderTitle()}
        {renderUserIcon()}
        {children && children}
      </h1>
      <div
        className={styles.right}
        style={buttonStyle || {}}
        onClick={buttonClick}
      >
        {button && buttonVisible && button}
      </div>
    </div>
  );
};

export default PageTitle;
