import React, {ChangeEvent, useEffect, useState} from "react";
import {ReactComponent as CloseIcon} from "@assets/images/close.svg";
import {ReactComponent as CloseCircleIcon} from "@assets/images/close-circle.svg";
import {ReactComponent as InfoIcon} from "@assets/images/info-circle.svg";
import {ReactComponent as ImageIcon} from "@assets/images/vote-pack-photos.svg";
import {ReactComponent as VideoIcon} from "@assets/images/vote-pack-videos.svg";
import {ReactComponent as AudioIcon} from "@assets/images/vote-pack-audios.svg";
import {ReactComponent as SpinnerIcon} from "@assets/images/spinner.svg";
import {ReactComponent as SelectedIcon} from "@assets/images/selected-circle.svg";
import {ReactComponent as PlusIcon} from "@assets/images/mystery-plus.svg";
import audioImage from "@assets/images/mystery-cover-audio.jpg";
import PageTitle from "@components/page-title/pageTitle";
import styles from "./stepTwo.module.scss";
import Button, {ButtonStyle} from "@components/button/button";
import {IMystery, IMysteryMedia} from "@app/store/interfaces/mystery";
import clsx from "clsx";
import {secondsToMinutesAndSeconds} from "@utils/time";
import update from "immutability-helper";
import userService from "@services/userService";
import {Scrollbars} from "react-custom-scrollbars-2";
import useNotification from "@hooks/useNotification";
import {ReactComponent as ErrorIcon} from "@assets/images/notification-error-white.svg";

interface IStepThreeProps {
  mysteryBox: IMystery;
  onUpdateMystery: (mystery: IMystery) => void;
  onClose: (created?: boolean) => void;
  onBack: () => void;
}

const StepThree = (props: IStepThreeProps) => {
  const notification = useNotification();
  const {mysteryBox, onUpdateMystery, onClose, onBack} = props;
  const [loading, setLoading] = useState(false);
  const [loadingFiles, setLoadingFiles] = useState(false);
  const [loadingFilesPreview, setLoadingFilesPreview] = useState<
    IMysteryMedia[]
  >([]);
  const [cards, setCards] = useState([] as IMysteryMedia[]);
  const [previewMediaUid, setPreviewMediaUid] = useState("");
  const uid = mysteryBox?.uid;

  const iconByMediaType = (type: string) => {
    switch (type) {
      case "image":
        return <ImageIcon />;
      case "video":
        return <VideoIcon />;
      default:
        return <AudioIcon />;
    }
  };

  const handleRemoveMedia = (
    removeIndex: number,
    isSelected: boolean,
    mediaId: string,
  ) => {
    setCards((prevCards) => {
      const updateCards = update(prevCards, {
        $splice: [[removeIndex, 1]],
      });

      const newCards = updateCards.map((card, index) => {
        return {
          ...card,
          rank: index + 1,
        };
      });

      if (newCards.length === 0 || isSelected) {
        setPreviewMediaUid("");
      }

      userService
        .deleteMediaMystery(mediaId)
        .then((response) => {
          const updateMedia = cards.filter((card) => card.uid !== mediaId);

          onUpdateMystery({
            ...mysteryBox,
            media: updateMedia,
          });
        })
        .catch((error) => {
          console.log(error);
        });

      return newCards;
    });
  };

  const handleUpdateMysteryBoxPreview = (id: string) => {
    setPreviewMediaUid(id);
  };

  // TODO: move to utils
  const getMediaTypeByFileType = (fileType: string) => {
    if (fileType.includes("image")) {
      return "image";
    } else if (fileType.includes("video")) {
      return "video";
    } else {
      return "audio";
    }
  };
  const handleFilesChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files || [];
    const formData = new FormData();
    let previewFiles: IMysteryMedia[] = [];

    [...files].forEach((file, index) => {
      const item = URL.createObjectURL(file);

      previewFiles.push({
        previewUrl: item,
        type: getMediaTypeByFileType(file.type),
        rank: index + 1,
        uid: item,
        commentsCount: 0,
        likesCount: 0,
        loading: true,
      });

      if (index === files.length - 1) {
        setLoadingFilesPreview(previewFiles);
      }
    });

    if (files) {
      [...files].forEach((file, i) => {
        formData.append("files[]", file, file.name);
      });
    }

    if (uid && files) {
      setLoadingFiles(true);
      userService
        .uploadMediaMystery(uid, formData)
        .then((response) => {
          if (response.data) {
            setLoadingFilesPreview([]);
            onUpdateMystery({
              ...response.data,
              previewUid: previewMediaUid || response.data.previewUid,
            });
          }
        })
        .catch((error) => {
          // setServerErrorMessage(error?.response?.data?.error || "");
          if (error.response?.status === 422) {
            notification.send({
              type: "error",
              text: error?.response?.detail,
              options: {
                icon: <ErrorIcon />,
                duration: 100000,
              },
            });
            setLoadingFilesPreview([]);
          }
        })
        .finally(() => {
          setLoadingFiles(false);
          event.target.value = "";
        });
    }
  };

  const checkSelectedItem = (id: string, rank: number, index: number) => {
    if (previewMediaUid) {
      return previewMediaUid === id;
    } else {
      return rank === 1 || index === 0;
    }
  };

  const renderMediaGridItem = (item: IMysteryMedia, itemIndex: number) => {
    const isLoading = item.loading;
    const isSelected =
      !isLoading && checkSelectedItem(item.uid, item.rank, itemIndex);
    const mediaItemStyle = {
      border: isSelected ? "2px solid #fff" : "2px solid transparent",
    };

    return (
      <div
        key={item.uid}
        className={styles.media_item}
        style={mediaItemStyle}
        onClick={() => handleUpdateMysteryBoxPreview(item.uid)}
      >
        {isLoading && (
          <div className={styles.media_loader}>
            <SpinnerIcon color="#fff" width={45} height={45} />
          </div>
        )}
        {isSelected && (
          <div className={styles.media_selected}>
            <SelectedIcon />
          </div>
        )}
        {!isLoading && (
          <div
            className={styles.media_remove}
            onClick={(event) => {
              event.stopPropagation();
              handleRemoveMedia(itemIndex, isSelected, item.uid);
            }}
          >
            <CloseCircleIcon />
          </div>
        )}
        {isLoading && item.type === "video" ? (
          <video
            src={item.previewUrl}
            controls={false}
            autoPlay={false}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        ) : (
          <img src={item.type === "audio" ? audioImage : item.previewUrl} />
        )}
        {!isLoading && (
          <div className={styles.media_type}>{iconByMediaType(item.type)}</div>
        )}
        <div className={styles.media_duration}>
          {secondsToMinutesAndSeconds(item.duration)}
        </div>
      </div>
    );
  };

  const renderMediaGrid = () => {
    return (
      <div className={styles.media_grid}>
        {cards?.length > 0 &&
          cards.map((item, index) => renderMediaGridItem(item, index))}
        {loadingFilesPreview?.length > 0 &&
          loadingFilesPreview.map((item, index) =>
            renderMediaGridItem(item, index),
          )}
        <div className={clsx(styles.media_item, styles.media_item_upload)}>
          <input
            type="file"
            name="files[]"
            id="files"
            className={styles.file}
            multiple
            accept="video/*, image/jpeg, image/png, image/webp, image/bmp, audio/*"
            onChange={(event) => {
              if (!loadingFiles && !loading) {
                handleFilesChange(event);
              }
            }}
          />
          <label
            htmlFor="files"
            onClick={(event) => {
              if (loading || loadingFiles) {
                event.preventDefault();
              }
            }}
          >
            <PlusIcon />
            {/*{loadingFiles*/}
            {/*    ? <SpinnerIcon width={32} height={32} />*/}
            {/*    : <PlusIcon />*/}
            {/*}*/}
          </label>
        </div>
      </div>
    );
  };

  const handleSave = () => {
    setLoading(true);
    const options = {
      type: mysteryBox.type,
      description: mysteryBox.description,
      previewMediaUid: previewMediaUid || mysteryBox.previewUid,
      media: cards,
    };

    userService
      .updateMystery(uid, options)
      .then((response) => {
        onUpdateMystery(response.data);
        onClose(true);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setCards(mysteryBox.media || []);
  }, [mysteryBox]);

  useEffect(() => {
    mysteryBox?.previewUid && setPreviewMediaUid(mysteryBox.previewUid);
  }, [mysteryBox]);

  return (
    <div className={styles.root}>
      <PageTitle
        backButtonVisible={true}
        backClick={onBack}
        button={<CloseIcon />}
        buttonClick={() => onClose(false)}
        buttonStyle={{
          alignSelf: "flex-start",
          paddingTop: "15px",
        }}
      >
        <div className={styles.step}>Step 2/2</div>
      </PageTitle>

      <Scrollbars
        className={styles.area}
        renderView={(props) => <div {...props} className={styles.content} />}
      >
        <div className={styles.field_title}>
          Uploaded files<span>*</span>
        </div>
        <div className={styles.field_info}>
          <InfoIcon />
          The pic with a border will be the cover of the mystery box. Tap on
          another image to change the cover
        </div>

        <div className={styles.media}>{renderMediaGrid()}</div>
      </Scrollbars>
      <Button
        title="Save"
        style={ButtonStyle.Gold}
        disabled={
          !mysteryBox?.media ||
          mysteryBox?.media?.length === 0 ||
          loading ||
          loadingFiles
        }
        loading={loading}
        onClick={handleSave}
        rootStyles={{
          fontStyle: "normal",
          margin: "10px auto",
          width: "calc(100% - 48px)",
        }}
      />
    </div>
  );
};

export default StepThree;
