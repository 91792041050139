import moment, {Duration} from "moment/moment";
import momentTimeZone from "moment-timezone";
import {toPercent} from "./number";

export interface IDuration {
  duration: Duration;
  days: number;
}

export const getDuration = (dateEnd: string, dateStart?: string): IDuration => {
  const start = dateStart ? moment(dateStart) : moment();
  const end = moment(dateEnd);

  return {
    duration: moment.duration(end.diff(start)),
    days: end.diff(start, "days"),
  };
};

export type IProgressDuration = IDuration & {
  allDays: number;
  percent: number;
};

export const getPastDuration = (dateStart: string): IDuration => {
  const start = moment(dateStart);
  const end = moment();

  return {
    duration: moment.duration(end.diff(start)),
    days: end.diff(start, "days"),
  };
};

export const getProgressDuration = (
  dateEnd: string,
  dateStart: string,
): IProgressDuration => {
  const {days: allDays} = getDuration(dateEnd, dateStart);
  const {days, duration} = getPastDuration(dateStart);

  return {
    duration,
    allDays,
    days,
    percent: toPercent(days / allDays),
  };
};

export const fromServerToClientDate = (serverDate?: string): string => {
  if (!serverDate) {
    return "–";
  }
  const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const clientDate = momentTimeZone.tz(serverDate, clientTimeZone);

  return clientDate.toISOString();
};

export const getHoursSince = (date: string) => {
  const pastDate = new Date(date);
  const currentDate = new Date();

  // @ts-ignore
  const differenceInMilliseconds = currentDate - pastDate;

  const hoursDifference = differenceInMilliseconds / (1000 * 60 * 60);

  return hoursDifference;
};

export const secondsToMinutesAndSeconds = (seconds?: number): string => {
  if (!seconds) {
    return "";
  }
  const duration = moment.duration(seconds, "seconds");
  const formattedTime = moment.utc(duration.asMilliseconds()).format("mm:ss");
  return formattedTime;
};

export const getLastSeenTime = (lastSeen?: string) => {
  if (!lastSeen) {
    return undefined;
  }

  const now = new Date();
  const past = new Date(lastSeen);

  // @ts-ignore
  const diffMs = now - past;

  const diffSeconds = Math.floor(diffMs / 1000);
  const diffMinutes = Math.floor(diffSeconds / 60);
  const diffHours = Math.floor(diffMinutes / 60);
  const diffDays = Math.floor(diffHours / 24);
  const diffMonths = Math.floor(diffDays / 30);
  const diffYears = Math.floor(diffMonths / 12);

  if (diffMinutes < 55) {
    return `${Math.ceil(diffMinutes / 5) * 5} min`;
  } else if (diffHours < 24) {
    return diffHours === 0 ? `1 h` : `${diffHours} h`;
  } else if (diffDays <= 30) {
    return `${diffDays} d`;
  } else if (diffMonths <= 12) {
    return `${diffMonths} m`;
  } else if (diffYears <= 2) {
    return `${diffYears} y`;
  } else {
    return undefined;
  }
};
