import React, {useCallback, useEffect, useLayoutEffect} from "react";
import Menu from "@components/menu/menu";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
  currentProfileSelector,
  isAuthenticatedSelector,
  isCookieConsentSelector,
  isDesktopChatterSelector,
  isFullyAuthenticatedSelector,
  setProfileData,
} from "@app/store/slices/profile";
import {MESSAGES, NOTIFICATION, PROFILE_CURRENT} from "@app/router.constants";
import userService from "@services/userService";
import MenuDrawer from "@components/menu-drawer/menuDrawer";
import {menuDrawerSelector} from "@app/store/slices/menuDrawer";
import {contentOverlaySelector} from "@app/store/slices/contentOverlay";
import ContentOverlay from "@components/content-overlay/contentOverlay";
import {ReactComponent as PaymentSuccessIcon} from "@assets/images/payment-success.svg";
import {ReactComponent as NotificationErrorIcon} from "@assets/images/notification-error-white.svg";
import Notification from "@components/notification/notification";
import useNotification from "@hooks/useNotification";
import VoteDrawer from "@components/vote-drawer/voteDrawer";
import LogoutDrawer from "@components/logout-drawer/logoutDrawer";
import DeleteDrawer from "@components/delete-drawer/deleteDrawer";
import CookiesPanel from "@components/cookies-panel/cookiesPanel";
import {overlaySelector, setCloseOverlay} from "@app/store/slices/overlay";
import {mysteryEditOverlaySelector} from "@app/store/slices/mysteryEditOverlay";
import MysteryEditOverlay from "@components/mystery-edit-overlay/mysteryEditOverlay";
import RegistrationSuccess from "@components/notification/templates/registrationSuccess";
import appService from "@services/appService";
import {partialUpdateConfig} from "@app/store/slices/config";
import {parseServerEvents} from "@utils/parseServerEvents";
import BlockedScreen from "@components/blocked-screen/blockedScreen";
import AuthOverlay from "@components/auth-overlay/authOverlay";
import {
  authOverlaySelector,
  openAuthOverlay,
} from "@app/store/slices/authOverlay";
import {WithoutAuthFormType} from "@app/store/interfaces/auth";
import AnonSignUp from "@components/notification/templates/anonSignUp";
import {useAuthHash} from "@hooks/useAuthHash";
import FakeLoaderOverlay from "@pages/messages/components/fake-loader-overlay/fakeLoaderOverlay";
import FakeBlackLoaderOverlay from "@pages/messages/components/fake-loader-overlay/fakeBlackLoaderOverlay";
import {ErrorBoundary} from "react-error-boundary";
import {ErrorBoundaryScreen} from "@components/error-boundary-screen/errorBoundaryScreen";
import {useAppSelector} from "@app/store/hooks";
import ComplainDrawer from "@components/complain-drawer/complainDrawer";
import DeletedScreen from "@components/deleted-screen/deletedScreen";
import {LocalStorageKey} from "@appTypes/localstorage";
import {SearchParams} from "@appTypes/searchParams";
import VoteDrawerForStaticView from "@components/vote-drawer/voteDrawerForStaticView";
import {isVoteDrawerVisibleSelector} from "@app/store/slices/voteDrawer";

const withLayout = (
  WrappedComponent,
  isVisibleMenu = true,
  isVisibleCookiePanel = true,
) => {
  return (props) => {
    const navigate = useNavigate();
    const {pathname} = useLocation();
    const dispatch = useDispatch();
    const notification = useNotification();
    const {list, visible: visibleMenuDrawer} = useSelector(menuDrawerSelector);
    const {visibleOverlay} = useSelector(overlaySelector);
    const {visible: visibleAuthOverlay} = useSelector(authOverlaySelector);
    const isAuthenticated = useSelector(isAuthenticatedSelector);
    const isFullyAuthenticated = useSelector(isFullyAuthenticatedSelector);
    const {isDrawerVisible, isStaticView} = useSelector(
      isVoteDrawerVisibleSelector,
    );
    const {status, personalUid} = useSelector(currentProfileSelector);
    const {
      type,
      contentId,
      visible: visibleContentOverlay,
    } = useSelector(contentOverlaySelector);
    const {mystery, visible: visibleMysteryEditOverlay} = useSelector(
      mysteryEditOverlaySelector,
    );
    const isCookieConsent = useAppSelector(isCookieConsentSelector);
    const isDesktopChatter = useAppSelector(isDesktopChatterSelector);
    const [searchParams] = useSearchParams();
    const params = useParams();
    const navigateToMessages = pathname === MESSAGES;

    useAuthHash();

    useEffect(() => {
      const redirectParams = JSON.parse(
        localStorage.getItem(LocalStorageKey.RedirectParams) || "{}",
      );
      const {chatId, paymentId, paymentStatus, paymentType, type} =
        redirectParams;

      if (chatId) {
        let redirectUrl = `${MESSAGES}?channel=${chatId}`;
        if (paymentId) redirectUrl += `&paymentId=${paymentId}`;
        if (paymentStatus) redirectUrl += `&paymentStatus=${paymentStatus}`;
        if (paymentType) redirectUrl += `&paymentType=${paymentType}`;
        if (type) redirectUrl += `&type=${type}`;

        navigate(redirectUrl);
        localStorage.removeItem(LocalStorageKey.RedirectParams);
      }
    }, [navigate]);

    const handleClickMenuItem = useCallback(
      (navigateTo) => {
        if (pathname === navigateTo) {
          if (visibleOverlay) {
            return dispatch(setCloseOverlay());
          }
          return false;
        }

        notification.clear();
        if (
          navigateTo !== PROFILE_CURRENT &&
          navigateTo !== MESSAGES &&
          navigateTo !== NOTIFICATION
        ) {
          return navigate(navigateTo);
        }

        if (isAuthenticated) {
          return navigate(navigateTo);
        } else {
          dispatch(
            openAuthOverlay({
              withoutAuthFormType: WithoutAuthFormType.Default,
              pathname,
            }),
          );
        }
      },
      [isAuthenticated, pathname, visibleOverlay],
    );

    useLayoutEffect(() => {
      const paymentStatus = searchParams.get(SearchParams.PaymentStatus);
      const paymentId = searchParams.get(SearchParams.PaymentId);
      const anonPaymentPopup = localStorage.getItem(
        LocalStorageKey.AnonPaymentPopup,
      );

      if (anonPaymentPopup === "hide" && !paymentStatus && !paymentId) {
        localStorage.setItem(LocalStorageKey.AnonPaymentPopup, "show");
      }
    }, []);

    useEffect(() => {
      const emailRegistrationSuccess = searchParams.get(
        SearchParams.EmailRegistration,
      );
      const emailLoginSuccess = searchParams.get(SearchParams.EmailLogin);
      const registrationSuccessVisible = localStorage.getItem(
        LocalStorageKey.Register,
      );
      const loginSuccessVisible = localStorage.getItem(LocalStorageKey.Login);
      const anonPaymentPopup = localStorage.getItem(
        LocalStorageKey.AnonPaymentPopup,
      );
      const paymentStatus = searchParams.get(SearchParams.PaymentStatus);
      const paymentId = searchParams.get(SearchParams.PaymentId);
      const isAnonymUser = localStorage.getItem(LocalStorageKey.AnonUserId);

      if (emailRegistrationSuccess && !registrationSuccessVisible) {
        notification.send({
          type: "message",
          text: <RegistrationSuccess />,
          options: {
            icon: <PaymentSuccessIcon />,
            duration: 5000,
          },
        });
        localStorage.setItem(LocalStorageKey.Register, "visible");
        localStorage.removeItem(LocalStorageKey.AnonPaymentPopup);
      }

      if (emailLoginSuccess && !loginSuccessVisible) {
        localStorage.setItem(LocalStorageKey.Login, "visible");
      }

      if (isAnonymUser && (paymentStatus || paymentId)) {
        localStorage.setItem(LocalStorageKey.AnonPaymentPopup, "hide");
      }

      if (
        isAnonymUser &&
        anonPaymentPopup === "show" &&
        !visibleAuthOverlay &&
        !params.username
      ) {
        notification.send({
          type: "error",
          text: <AnonSignUp />,
          options: {
            icon: <NotificationErrorIcon />,
            duration: pathname === PROFILE_CURRENT ? Infinity : 10000,
          },
        });
      }

      return () => {
        const anonPaymentPopup = localStorage.getItem(
          LocalStorageKey.AnonPaymentPopup,
        );
        if (anonPaymentPopup === "hide") {
          localStorage.setItem(LocalStorageKey.AnonPaymentPopup, "show");
        }
      };
    }, []);

    useLayoutEffect(() => {
      if (isAuthenticated) {
        userService
          .getCurrentProfile(personalUid)
          .then((response) => {
            if (response?.data?.email) {
              localStorage.setItem(LocalStorageKey.UserId, response.data.uid);
              dispatch(setProfileData(response.data));
            }
          })
          .catch(({response}) => {
            if (response?.status === 400) {
              openAuthOverlay({
                withoutAuthFormType: WithoutAuthFormType.Default,
                pathname,
              });
            }
          });
      }

      const localVersion = localStorage.getItem(LocalStorageKey.CtvVersion);

      appService.getConfig().then((response) => {
        if (response?.data) {
          const configs = parseServerEvents(response.data?.configs);

          if (!localVersion) {
            localStorage.setItem(LocalStorageKey.CtvVersion, configs.version);
          } else if (configs.version && configs.version !== localVersion) {
            localStorage.setItem(LocalStorageKey.CtvVersion, configs.version);
            window.location.reload();
          }

          dispatch(partialUpdateConfig(configs));
        }
      });
    }, [isAuthenticated]);

    useLayoutEffect(() => {
      return () => {
        localStorage.setItem(LocalStorageKey.PreviousPage, pathname);
      };
    }, [pathname]);

    if (status === "blocked") {
      return (
        <div className="content">
          <BlockedScreen />
        </div>
      );
    }

    if (status === "deleted") {
      return (
        <div className="content">
          <DeletedScreen personalUid={personalUid} />
        </div>
      );
    }

    return (
      <ErrorBoundary FallbackComponent={ErrorBoundaryScreen}>
        <div
          style={{
            height:
              isCookieConsent || !isVisibleCookiePanel
                ? `calc(100% - var(--sat))`
                : `calc(100% - var(--sat) - 60px)`,
          }}
          className={
            isDesktopChatter && navigateToMessages
              ? "contentChatter"
              : "content"
          }
        >
          <Notification />
          <WrappedComponent {...props} />
          {isVisibleMenu && <Menu onClickItem={handleClickMenuItem} />}
          <MenuDrawer list={list} visible={visibleMenuDrawer} />
          {isDrawerVisible &&
            (isStaticView ? <VoteDrawerForStaticView /> : <VoteDrawer />)}
          {!isFullyAuthenticated && <AuthOverlay />}
          <FakeLoaderOverlay />
          <FakeBlackLoaderOverlay />
          <ContentOverlay
            type={type}
            contentId={contentId}
            visible={visibleContentOverlay}
          />
          <MysteryEditOverlay
            mystery={mystery}
            visible={visibleMysteryEditOverlay}
          />
          <LogoutDrawer />
          <DeleteDrawer />
          <ComplainDrawer />
        </div>

        {isVisibleCookiePanel && (
          <div
            style={{
              position: "absolute",
              bottom: 0,
              left: "50%",
              transform: "translateX(-50%)",
              zIndex: 999999,
            }}
          >
            <CookiesPanel />
          </div>
        )}
      </ErrorBoundary>
    );
  };
};

export default withLayout;
