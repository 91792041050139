import toast, {ToastBar, Toaster} from "react-hot-toast";
import {ReactComponent as CloseIcon} from "../../assets/images/close.svg";
import React, {CSSProperties} from "react";
import styles from "./notification.module.scss";

const Notification = () => {
  const containerToastStyles: CSSProperties = {
    width: "100%",
    position: "absolute",
    maxWidth: "420px",
    margin: "auto",
    inset: 0,
    top: 0,
    left: 0,
    boxSizing: "border-box",
    zIndex: 999999,
  };

  return (
    <Toaster
      toastOptions={{
        className: styles.toast,
        duration: 10000,
      }}
      containerStyle={containerToastStyles}
    >
      {(t) => (
        <ToastBar
          toast={t}
          style={{
            ...t.style,
          }}
        >
          {({icon, message}) => {
            return (
              <>
                <div className={styles.icon}>{icon}</div>
                <div className={styles.message}>{message}</div>
                <div
                  onClick={() => toast.dismiss(t.id)}
                  className={styles.close}
                >
                  <CloseIcon />
                </div>
              </>
            );
          }}
        </ToastBar>
      )}
    </Toaster>
  );
};

export default Notification;
