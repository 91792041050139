const useValidate = () => {
  return (values) => {
    const emailRegex = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
    const passwordRegex = new RegExp(/^\S{6,}$/);
    let errors = {};

    if (values.name !== undefined) {
      if (!values.name.trim()) {
        errors.name = 'Field "Username" is required';
      }
    }

    if (values.email !== undefined) {
      if (!values.email) {
        errors.email = 'Field "Email" is required';
      } else if (!emailRegex.test(values.email)) {
        errors.email = "You have entered an incorrect email format";
      }
    }

    if (values.password !== undefined) {
      if (!values.password.trim()) {
        errors.password = 'Field "Password" is required';
      } else if (!passwordRegex.test(values.password)) {
        errors.password = "The password must contain at least 6 characters";
      }
    }

    if (values.repeatPassword !== undefined) {
      if (!values.repeatPassword.trim()) {
        errors.repeatPassword = 'Field "Repeat password" is required';
      } else if (values.password !== values.repeatPassword) {
        errors.repeatPassword = "Passwords do not match";
      }
    }

    if (Object.keys(errors).length === 0) {
      return {};
    }

    return errors;
  };
};

export default useValidate;
