export default function getButtonColorByVote(vote: number): string {
  switch (vote) {
    case 15000:
      return "linear-gradient(358.57deg, #52078D -8.62%, #AD44FF 113.83%)";
    case 10000:
      return "linear-gradient(358.57deg, #131A98 -8.62%, #1CC6FC 113.83%)";
    case 5000:
      return "linear-gradient(106.9deg, #FF00D6 -17.57%, #FF00D6 4.77%, #FF00C7 19.9%, #F40075 52.61%, #EC0071 93.65%)";
    case 3000:
      return "linear-gradient(1.3deg, #FF4D00 20.83%, #FCEC21 122.69%)";
    case 1000:
      return "linear-gradient(143.38deg, #FFE600 28.82%, #FFA800 70.25%)";
    case 200:
      return "linear-gradient(180deg, #B8F87F 0%, #71D50C 54.44%)";
    case 100:
      return "linear-gradient(343.18deg, #0085FF -3.24%, #4ED5FF 67.31%)";
    default:
      return "linear-gradient(343.18deg, #0085FF -3.24%, #4ED5FF 67.31%)";
  }
}
