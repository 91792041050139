export const calculateProportionalSize = (
  videoWidth?: number,
  videoHeight?: number,
  contentWidth?: number,
) => {
  if (!videoWidth || !videoHeight || !contentWidth) {
    return {
      //TODO use when needed
    // console.log(
    //   `Invalid input. Please provide a valid video resolution and content width. ${videoWidth} ${videoHeight}`,
    // );
  }}

  const aspectRatio = videoWidth / videoHeight;
  const proportionalHeight = contentWidth / aspectRatio;

  const roundedWidth = contentWidth; //Math.round(contentWidth);
  const roundedHeight = proportionalHeight; //Math.round(proportionalHeight);

  return {
    width: roundedWidth,
    height: roundedHeight,
  };
};
