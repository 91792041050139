import styles from "./selectCountry.module.scss";
import Button, {ButtonStyle, ButtonType} from "../../../button/button";
import React, {useState} from "react";
import CountryAutocomplete from "../../../country-autocomplete/countryAutocomplete";
import {CountryItem} from "../../../country-autocomplete/type";
import {setCountry, signupSelector} from "@app/store/slices/signup";
import {FormState} from "@app/store/interfaces/auth";
import {changeAuthOverlayFormState} from "@app/store/slices/authOverlay";
import {AnalyticIDs} from "@app/store/interfaces/analytics";
import {useAppDispatch, useAppSelector} from "@app/store/hooks";
import Amplitude from "@utils/amplitude";
import userService from "@services/userService";
import {isAuthenticatedSelector} from "@app/store/slices/profile";
import {useAnonymCreation} from "@components/auth-overlay/hooks/useAnonymCreation";
import {LocalStorageKey} from "@appTypes/localstorage";
import {setFakeLoaderOverlayVisible} from "@app/store/slices/fakeLoaderOverlay";

const SelectCountry = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const {country} = useAppSelector(signupSelector);
  const isAuthenticated = useAppSelector(isAuthenticatedSelector);

  const {goToTargetPage} = useAnonymCreation();

  const onChangeCountry = (newValue: CountryItem) => {
    dispatch(setCountry(newValue));
  };

  const handleNextClick = () => {
    Amplitude.track(AnalyticIDs.CountryConfirmed, {
      path: window.location.pathname,
      country: country?.label,
    });

    if (isAuthenticated) {
      const selectedCountry = country?.value;
      const countryByIp = localStorage.getItem(LocalStorageKey.UserCountry);

      const detectedCountry = selectedCountry || countryByIp;

      if (detectedCountry) {
        userService
          .updateCurrentProfileCountry({
            country: detectedCountry,
          })
          .then(() => {
            dispatch(setFakeLoaderOverlayVisible(true));
            goToTargetPage();
          });
      }

      return;
    }
    dispatch(changeAuthOverlayFormState(FormState.Auth));
  };

  return (
    <div className={styles.selectCountryWrapper}>
      <h2>Confirm your country</h2>

      <div className={styles.autocompleteWrapper}>
        <CountryAutocomplete
          selectedCountry={country?.value}
          onChangeCountry={onChangeCountry}
        />
      </div>

      <div className={styles.buttonsWrapper}>
        <Button
          onClick={handleNextClick}
          disabled={!Boolean(country) || loading}
          loading={loading}
          style={ButtonStyle.Pink}
          type={ButtonType.Filled}
          rootStyles={{width: "100%"}}
        >
          <span className={styles.buttonText}>Next</span>
        </Button>
      </div>
    </div>
  );
};

export default SelectCountry;
