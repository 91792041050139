export enum ActionType {
  Chat = "Chat",
  Unlock = "Unlock",
  Vote = "Vote",
}

export type ActionKey = "profile" | "unlock" | "chat" | "vote";

export interface UserLandingDataParams {
  username?: string;
  id?: string;
  personalUid?: string;
  action?: string;
}
