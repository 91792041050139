import styles from "./auth.module.scss";
import Signup from "./signup";
import Link from "@mui/material/Link";
import Signin from "./signin";
import React from "react";
import {useNavigate} from "react-router-dom";
import {checkRuByIp as checkRU} from "@utils/detections";
import {ReactComponent as CloseCircleIcon} from "@assets/images/close-circle.svg";
import {
  COMMUNITY_GUIDELINES,
  PRIVACY_POLICY,
  PRIVACY_POLICY_RU,
  TERMS_OF_SERVICE,
  TERMS_OF_SERVICE_RU,
} from "@app/router.constants";
import {useDispatch, useSelector} from "react-redux";
import {
  authOverlaySelector,
  closeAuthOverlay,
} from "@app/store/slices/authOverlay";
import {signupSelector} from "@app/store/slices/signup";
import {WithoutAuthFormType} from "@app/store/interfaces/auth";
import {AnalyticIDs} from "@app/store/interfaces/analytics";
import {useAppSelector} from "@app/store/hooks";
import {currentProfileSelector} from "@app/store/slices/profile";

interface IAuthProps {
  isVisibleForm: boolean;
  isSignIn: boolean;
  setIsSignIn: (value: boolean) => void;
}

const Auth = (props: IAuthProps) => {
  const {isVisibleForm, isSignIn, setIsSignIn} = props;
  const {country} = useSelector(signupSelector);
  const {withoutAuthFormType, isCloseButtonHidden} =
    useAppSelector(authOverlaySelector);
  const {isAnonym} = useAppSelector(currentProfileSelector);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const isRU = checkRU() || country?.value === "RU";

  const bottomFormLinks = () => {
    return (
      <div className={styles.auth_links}>
        By signing up you agree to our
        <br />
        <Link
          onClick={() => {
            dispatch(closeAuthOverlay());
            navigate(isRU ? TERMS_OF_SERVICE_RU : TERMS_OF_SERVICE);
          }}
          style={{
            textAlign: "center",
            cursor: "pointer",
            color: "#BCBCBC",
            textDecoration: "none",
          }}
        >
          Terms of Service
        </Link>
        ,
        <Link
          onClick={() => {
            dispatch(closeAuthOverlay());
            navigate(isRU ? PRIVACY_POLICY_RU : PRIVACY_POLICY);
          }}
          style={{
            textAlign: "center",
            cursor: "pointer",
            display: "inline-block",
            paddingLeft: 10,
            color: "#BCBCBC",
            textDecoration: "none",
          }}
        >
          Privacy Policy
        </Link>
        <span
          style={{
            paddingLeft: 10,
          }}
        >
          and
        </span>
        <Link
          onClick={() => {
            dispatch(closeAuthOverlay());
            navigate(COMMUNITY_GUIDELINES);
          }}
          style={{
            textAlign: "center",
            cursor: "pointer",
            paddingLeft: 10,
            color: "#BCBCBC",
            textDecoration: "none",
          }}
        >
          Community Guidelines
        </Link>
      </div>
    );
  };

  const handleSignUpClick = () => {
    setIsSignIn(false);
  };

  const handleCloseClick = () => {
    dispatch(closeAuthOverlay());
  };

  return (
    <div className={styles.root}>
      {(withoutAuthFormType !== WithoutAuthFormType.None || isAnonym) &&
        !isCloseButtonHidden && (
          <div className={styles.close} onClick={handleCloseClick}>
            <CloseCircleIcon />
          </div>
        )}
      {isSignIn ? (
        <div
          className={styles.auth_content}
          style={{
            justifyContent:
              withoutAuthFormType === WithoutAuthFormType.None
                ? "flex-end"
                : "center",
          }}
        >
          <Signin visible={true} />
          <Link
            id={AnalyticIDs.SignInForgetClickButton}
            onClick={() => {
              dispatch(closeAuthOverlay());
              navigate("/user/reset-password");
            }}
            style={{
              width: "100%",
              cursor: "pointer",
              fontSize: 14,
              marginTop: 16,
              display: "flex",
              justifyContent: "center",
            }}
          >
            Forget password?
          </Link>
          {bottomFormLinks()}
          <div className={styles.auth_navigation}>
            Don’t have an account?
            <Link
              onClick={handleSignUpClick}
              style={{
                fontSize: "12px",
                fontWeight: 400,
                textAlign: "center",
                cursor: "pointer",
                paddingLeft: 10,
                color: "#F755AC",
                textDecoration: "none",
              }}
            >
              Sign Up
            </Link>
          </div>
        </div>
      ) : (
        <div
          className={styles.auth_content}
          style={{
            justifyContent:
              withoutAuthFormType === WithoutAuthFormType.None
                ? "flex-end"
                : "center",
          }}
        >
          <Signup visible={true} visibleForm={isVisibleForm} />
          {bottomFormLinks()}
          <div className={styles.auth_navigation}>
            Already have an account?
            <Link
              onClick={() => setIsSignIn(true)}
              style={{
                fontSize: "12px",
                fontWeight: 400,
                textAlign: "center",
                cursor: "pointer",
                paddingLeft: 10,
                color: "#F755AC",
                textDecoration: "none",
              }}
            >
              Sign In
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default Auth;
