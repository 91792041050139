import styles from "./skeleton.module.scss";
import React, {useMemo, CSSProperties} from "react";
import clsx from "clsx";

interface SkeletonProps {
  radius?: number | string;
  type?:
    | "small-text"
    | "normal-text"
    | "medium-text"
    | "large-text"
    | "extra-large-text"
    | "small-circle"
    | "normal-circle"
    | "feed-circle"
    | "medium-circle"
    | "large-circle"
    | "extra-large-circle";
  theme?: "light" | "dark";
  style?: CSSProperties;

  [key: string]: any;
}

const Skeleton: React.FC<SkeletonProps> = (props) => {
  const {radius, type, theme, style, ...restProps} = props;

  const rootStyles = clsx(styles.root, {
    [styles.light]: theme === "light",
  });

  return useMemo(() => {
    switch (type) {
      case "small-text":
        return (
          <div
            className={rootStyles}
            style={{
              ...restProps,
              height: 8,
              borderRadius: 4,
              ...style,
            }}
          />
        );
      case "normal-text":
        return (
          <div
            className={rootStyles}
            style={{
              ...restProps,
              height: 12,
              borderRadius: 6,
              ...style,
            }}
          />
        );
      case "medium-text":
        return (
          <div
            className={rootStyles}
            style={{
              ...restProps,
              height: 16,
              borderRadius: 8,
              ...style,
            }}
          />
        );
      case "large-text":
        return (
          <div
            className={rootStyles}
            style={{
              ...restProps,
              height: 24,
              borderRadius: 12,
              ...style,
            }}
          />
        );
      case "extra-large-text":
        return (
          <div
            className={rootStyles}
            style={{
              ...restProps,
              height: 46,
              borderRadius: 16,
              ...style,
            }}
          />
        );
      case "small-circle":
        return (
          <div
            className={rootStyles}
            style={{
              ...restProps,
              width: 20,
              height: 20,
              borderRadius: 10,
              ...style,
            }}
          />
        );
      case "normal-circle":
        return (
          <div
            className={rootStyles}
            style={{
              ...restProps,
              width: 32,
              height: 32,
              borderRadius: 16,
              ...style,
            }}
          />
        );
      case "feed-circle":
        return (
          <div
            className={rootStyles}
            style={{
              ...restProps,
              width: 40,
              height: 40,
              borderRadius: 20,
              ...style,
            }}
          />
        );
      case "medium-circle":
        return (
          <div
            className={rootStyles}
            style={{
              ...restProps,
              width: 48,
              height: 48,
              borderRadius: 24,
              ...style,
            }}
          />
        );
      case "large-circle":
        return (
          <div
            className={rootStyles}
            style={{
              ...restProps,
              width: 80,
              height: 80,
              borderRadius: 40,
              ...style,
            }}
          />
        );
      case "extra-large-circle":
        return (
          <div
            className={rootStyles}
            style={{
              ...restProps,
              width: 120,
              height: 120,
              borderRadius: 100,
              ...style,
            }}
          />
        );
      default:
        return (
          <div
            className={rootStyles}
            style={{
              ...restProps,
              borderRadius: radius,
              ...style,
            }}
          />
        );
    }
  }, [type, radius, theme, style, restProps]);
};

export default Skeleton;
