import {SocialNetwork} from "@app/store/interfaces/socialNetwork";

export enum RequestStatus {
  Idle = "Idle",
  Loading = "Loading",
  Failed = "Failed",
  Success = "Success",
}

export interface ICreateOnlineGift {
  recipientUid: string;
  catalogOnlineGiftUid: string;
  message: string;
}

export type AuthByHashType =
  | "cms"
  | "google"
  | "facebook"
  | "instagram"
  | "anonym";


export interface ICreatePost {
  description: string;
  type?: string;
  socialType?: SocialNetwork | null;
  socialLink?: string;
}
