import React, {ChangeEvent, useEffect, useState} from "react";
import styles from "./stepOne.module.scss";
import Button, {ButtonStyle} from "../../../button/button";
import {ReactComponent as CloseIcon} from "../../../../assets/images/close.svg";
import PageTitle from "../../../page-title/pageTitle";
import Input from "../../../input/input";
import {Scrollbars} from "react-custom-scrollbars-2";
import {
  IMystery,
  MysteryPriceItemType,
} from "../../../../app/store/interfaces/mystery";
import userService from "../../../../services/userService";
import {useAppSelector} from "../../../../app/store/hooks";
import Skeleton from "../../../skeleton/skeleton";
import {getPrice} from "../../../../utils/getPrice";
import {
  currentProfileSelector,
  isRuSelector,
} from "../../../../app/store/slices/profile";

interface IStepOneProps {
  uid: string;
  description: string;
  votesAmount: string;
  prices: MysteryPriceItemType[];
  loadingPrices: boolean;
  onUpdateMystery: (mystery: IMystery) => void;
  onClose: () => void;
  onNext: () => void;
}

const StepOne = (props: IStepOneProps) => {
  const {
    uid,
    description,
    votesAmount,
    prices,
    loadingPrices,
    onUpdateMystery,
    onNext,
    onClose,
  } = props;
  const [saveLoading, setSaveLoading] = useState(false);
  const [descriptionState, setDescriptionState] = useState("");
  const [votesAmountState, setVotesAmountState] = useState<string | undefined>(
    undefined,
  );

  const {country} = useAppSelector(currentProfileSelector);
  const isRu = useAppSelector(isRuSelector);

  const isNext =
    descriptionState === description && votesAmountState === votesAmount;

  const handleChangeVoteAmount = (e: any) => {
    setVotesAmountState(e.target.value);
  };

  const handleChangeDescription = (event: ChangeEvent<HTMLInputElement>) => {
    setDescriptionState(event.target.value);
  };

  const handleClose = () => {
    setDescriptionState("");
    setVotesAmountState(undefined);
    onClose();
  };

  const handleSave = () => {
    const options = {
      type: votesAmountState || votesAmount,
      description: descriptionState || description,
    };

    onNext();
    userService
      .updateMystery(uid, options)
      .then((response) => {
        onUpdateMystery(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {});
  };

  const handleNext = () => {
    if (isNext) {
      return onNext();
    }

    return handleSave();
  };

  useEffect(() => {
    setDescriptionState(description);
    setVotesAmountState(votesAmount);
  }, [description, votesAmount]);

  return (
    <div className={styles.root}>
      <PageTitle
        button={<CloseIcon />}
        buttonClick={handleClose}
        buttonStyle={{
          alignSelf: "flex-start",
          paddingTop: "15px",
        }}
      >
        <div className={styles.step}>Step 1/2</div>
      </PageTitle>

      <Scrollbars
        className={styles.area}
        renderView={(props) => <div {...props} className={styles.content} />}
      >
        <div className={styles.field}>
          <div className={styles.field_title}>
            Mystery box description<span>*</span>
          </div>
          <div className={styles.field_input}>
            <Input
              label="Box description"
              labelTextNotFocused="You can add description"
              name="description"
              multiline
              value={descriptionState ?? ""}
              onChange={handleChangeDescription}
              showCharacterCount
            />
          </div>
        </div>
        <div className={styles.field}>
          <div className={styles.field_title}>
            Choose votes amount<span>*</span>
          </div>
          <div className={styles.field_input}>
            {loadingPrices ? (
              <>
                <Skeleton
                  width="180px"
                  height="24px"
                  style={{
                    borderRadius: 8,
                    marginBottom: 22,
                  }}
                />
                <Skeleton
                  width="180px"
                  height="24px"
                  style={{
                    borderRadius: 8,
                    marginBottom: 22,
                  }}
                />
                <Skeleton
                  width="180px"
                  height="24px"
                  style={{
                    borderRadius: 8,
                    marginBottom: 22,
                  }}
                />
              </>
            ) : (
              prices.map(
                (item) =>
                  item.forShow && (
                    <label key={`${item.type}-${item.votes}`}>
                      <input
                        type="radio"
                        name="radio"
                        value={item.type}
                        checked={votesAmountState === item.type}
                        onChange={handleChangeVoteAmount}
                      />
                      {`${item.votes} votes (${getPrice(isRu ? item?.priceRu : item?.price, isRu)})`}
                    </label>
                  ),
              )
            )}
          </div>
        </div>
      </Scrollbars>

      <div
        style={{
          fontStyle: "normal",
          margin: "10px auto",
          width: "calc(100% - 48px)",
          display: "flex",
        }}
      >
        <Button
          title="Next"
          style={ButtonStyle.Gold}
          onClick={handleNext}
          disabled={!descriptionState || !votesAmountState}
          rootStyles={{
            fontStyle: "normal",
            flex: 1,
          }}
        />
        {/*<Button*/}
        {/*    title="Save"*/}
        {/*    style={ButtonStyle.White}*/}
        {/*    onClick={handleSave}*/}
        {/*    disabled={!description || !votesAmount || disabled || saveLoading}*/}
        {/*    loading={saveLoading}*/}
        {/*    rootStyles={{*/}
        {/*        fontStyle: "normal",*/}
        {/*        flex: 1,*/}
        {/*    }}*/}
        {/*/>*/}
      </div>
    </div>
  );
};

export default StepOne;
