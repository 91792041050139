import {ReactComponent as GoogleIcon} from "../../../../assets/images/google.svg";
import Button, {ButtonStyle, ButtonType} from "../../../button/button";
import React from "react";
import {isInAppBrowser} from "../../../../utils/detections";
import {googleAuthLink} from "../../../../utils/googleAuth";

interface IGoogleButtonProps {
  title: string;
  onClick?: () => void;
}

const GoogleButton = (props: IGoogleButtonProps) => {
  const {title, onClick} = props;

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else {
      window.location.assign(googleAuthLink);
    }
  };

  return (
    !isInAppBrowser() && (
      <Button
        title={`${title} with Google`}
        iconStart={<GoogleIcon />}
        type={ButtonType.Filled}
        style={ButtonStyle.Auth}
        onClick={handleClick}
        rootStyles={{
          height: 50,
        }}
      />
    )
  );
};

export default GoogleButton;
