import {LocalStorageKey} from "@appTypes/localstorage";

const RegistrationSuccess = () => {
  const renderCheckSpamMessage = () => {
    const checkEmailRegex = /@(yahoo|outlook|hotmail)\./i;
    const email = localStorage.getItem(LocalStorageKey.RegistrationEmail);

    if (email && checkEmailRegex.test(email)) {
      localStorage.removeItem(LocalStorageKey.RegistrationEmail);
      return (
        <span
          style={{
            background: "#5DFE35",
            padding: "0 4px",
            lineHeight: "18px",
            textTransform: "uppercase",
            color: "#000000",
          }}
        >
          Check your spam folder
        </span>
      );
    }
    return null;
  };

  return (
    <span>
      Congratulations! You're successfully registered. Confirm your email using
      the link sent to your inbox.
      <br />
      {renderCheckSpamMessage()}
    </span>
  );
};

export default RegistrationSuccess;
