import {useAppDispatch, useAppSelector} from "@app/store/hooks";
import {
  deletePost,
  profileSelector,
  setDeleteVisible,
  setMysteryDelete,
  setProfileContentDelete,
} from "@app/store/slices/profile";
import Drawer from "../drawer/drawer";
import {setContentFeedDelete} from "@app/store/slices/contentFeed";
import {setCloseOverlay} from "@app/store/slices/overlay";
import contentService from "@services/contentService";
import {setChallengeContentDelete} from "@app/store/slices/challenge";
import {setCloseMenuDrawer} from "@app/store/slices/menuDrawer";
import userService from "@services/userService";
import {useNavigate} from "react-router-dom";
import {PROFILE_CURRENT, ROOT} from "@app/router.constants";
import useSignOut from "@hooks/useSignOut";
import {LocalStorageKey} from "@appTypes/localstorage";

const DeleteDrawer = () => {
  const {deleteDrawerVisible, deleteDrawerType, deleteContentId} =
    useAppSelector(profileSelector);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const signout = useSignOut();

  const handleClose = () => {
    dispatch(
      setDeleteVisible({
        visible: false,
        videoId: undefined,
      }),
    );
  };

  const handleOpen = () => {
    dispatch(
      setDeleteVisible({
        visible: false,
        videoId: undefined,
      }),
    );
  };

  const navigateRoot = () => {
    navigate(ROOT);
  };

  const handleApply = () => {
    if (deleteDrawerType === "profile") {
      dispatch(
        setDeleteVisible({
          visible: false,
          videoId: undefined,
        }),
      );
      return userService
        .deleteProfile()
        .then(() => {
          localStorage.removeItem(LocalStorageKey.AuthHash);
          signout(navigateRoot, undefined);
        })
        .catch((error) => {
          console.log(error);
        });
    }

    if (deleteDrawerType === "mystery" && deleteContentId) {
      return userService.deleteMystery(deleteContentId).then((response) => {
        if (response) {
          dispatch(setMysteryDelete(deleteContentId));
          dispatch(setCloseMenuDrawer());
          dispatch(setCloseOverlay());
          dispatch(
            setDeleteVisible({
              visible: false,
              videoId: undefined,
            }),
          );
          navigate(PROFILE_CURRENT, {
            state: {
              tab: 1,
            },
          });
        }
      });
    }

    if (deleteDrawerType === "post" && deleteContentId) {
      return userService.deletePost(deleteContentId).then((response) => {
        if (response) {
          dispatch(deletePost(deleteContentId));
          dispatch(setCloseMenuDrawer());
          dispatch(setCloseOverlay());
          dispatch(
            setDeleteVisible({
              visible: false,
              videoId: undefined,
            }),
          );
        }
      });
    }

    if (deleteContentId) {
      return contentService.deleteContent(deleteContentId).then((response) => {
        if (response) {
          dispatch(setContentFeedDelete(deleteContentId));
          dispatch(setChallengeContentDelete(deleteContentId));
          dispatch(setProfileContentDelete(deleteContentId));
          dispatch(setCloseMenuDrawer());
          dispatch(setCloseOverlay());
          dispatch(
            setDeleteVisible({
              visible: false,
              videoId: undefined,
            }),
          );
        }
      });
    }
  };

  const renderTitle = () => {
    if (deleteDrawerType === "profile") {
      return "Delete profile";
    }

    return "Delete";
  };

  const renderText = () => {
    if (deleteDrawerType === "profile") {
      return "Do you really want to delete your profile?";
    }

    if (deleteDrawerType === "mystery") {
      return "Do you really want to delete this mystery box?";
    }

    return "Do you really want to delete this content?";
  };

  return (
    <Drawer
      title={renderTitle()}
      visible={deleteDrawerVisible || false}
      onClose={handleClose}
      onOpen={handleOpen}
      buttonText="Yes"
      buttonClick={handleApply}
    >
      <div
        style={{
          textAlign: "center",
        }}
      >
        {renderText()}
      </div>
    </Drawer>
  );
};

export default DeleteDrawer;
