export const CONTEXT_RU_USEFUL_REQUISITES = "context-ru-useful-requisites";
export const CONTEXT_RU_USEFUL_TERMS = "context-ru-useful-terms";
export const CONTEXT_RU_USEFUL_PRIVACY_POLICY =
  "context-ru-useful-privacy-policy";
export const CONTEXT_RU_USEFUL_PAYMENT = "context-ru-useful-payment";

export const CONTEXT_SHARE_CONTENT = "share-content";
export const CONTEXT_SHARE_CHALLENGE = "share-challenge";
export const CONTEXT_SHARE_PROFILE = "share-profile";
export const CONTEXT_SHARE_SOCIAL = "share-social";

export const CONTEXT_UNJOIN_CHALLENGE = "unjoin-challenge";

export const CONTEXT_EDIT_CONTENT = "edit-content";
export const CONTEXT_ARCHIVE_CONTENT = "archive-content";
export const CONTEXT_UNARCHIVE_CONTENT = "unarchive-content";
export const CONTEXT_DELETE_CONTENT = "delete-content";
export const CONTEXT_COMPLAIN_CONTENT = "complain-content";
export const CONTEXT_COMPLAIN_USER = "complain-user";
export const CONTEXT_COMPLAIN_CHAT_MESSAGE = "complain-chat-message";

export const CONTEXT_LOGOUT = "logout";
export const CONTEXT_DELETE_PROFILE = "delete-profile";
export const CONTEXT_SETTINGS = "settings";
export const CONTEXT_PAYMENTS = "payments";
export const CONTEXT_INFORMATION = "information";
export const CONTEXT_CREATE_ACCOUNT = "create-account";

export const CONTEXT_EDIT_MYSTERY = "edit-mystery";
export const CONTEXT_ARCHIVE_MYSTERY = "archive-mystery";
export const CONTEXT_UNARCHIVE_MYSTERY = "unarchive-mystery";
export const CONTEXT_DELETE_MYSTERY = "delete-mystery";

export const CONTEXT_EDIT_POST = "edit-post";

export const CONTEXT_UNFOLLOW_USER = "unfollow-user";

export const CONTEXT_SORT_NEW_FIRST = "sort-new-first";
export const CONTEXT_SORT_UNREAD_FIRST = "sort-unread-first";

export const CONTEXT_MARK_ALL_AS_READ = "mark-all-as-read";

export const CONTEXT_CREATE_MYSTERY = "context-create-mystery";

export const CONTEXT_CREATE_POST = "context-create-post";
export const CONTEXT_ARCHIVE_POST = "context-archive-post";
export const CONTEXT_UNARCHIVE_POST = "context-unarchive-post";
export const CONTEXT_DELETE_POST = "delete-post";
