import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import styles from "./bottomControls.module.scss";
import clsx from "clsx";
import {formatNumber} from "@utils/format";
import {useLocation, useNavigate} from "react-router-dom";
import {IChallenge} from "@app/store/interfaces/challenge";
import feedService from "@services/feedService";
import {useAppDispatch, useAppSelector} from "@app/store/hooks";
import {
  setMysteryBoxes,
  setOpenVoteDrawer,
  updateFreeDateVoteDrawer,
} from "@app/store/slices/voteDrawer";
import LinesEllipsis from "react-lines-ellipsis";
import {updateVoteCountFeed} from "@app/store/slices/feed";
import {IContentPrices} from "@app/store/interfaces/content";
import {fromServerToClientDate} from "@utils/time";
import moment from "moment/moment";
import contentService from "@services/contentService";
import {updateVoteCountContent} from "@app/store/slices/content";
import {ReactComponent as UserSubscribeIcon} from "@assets/images/user-info-subscribe.svg";
import {ReactComponent as BlockedIcon} from "@assets/images/notification-blocked-red.svg";
import {ReactComponent as ErrorIcon} from "@assets/images/notification-error-white.svg";
import {ReactComponent as CommentsIcon} from "@assets/images/video-info-comments.svg";
import {ReactComponent as ShareIcon} from "@assets/images/video-info-share.svg";
import {ReactComponent as CopyIcon} from "@assets/images/notification-copy.svg";
import {ReactComponent as LikeIcon} from "@assets/images/like.svg";
import {ReactComponent as SuccessIconCircle} from "@assets/images/success-icon-circle.svg";
import {IUser} from "@app/store/interfaces/user";
import {
  currentProfileSelector,
  isAuthenticatedSelector,
  isFullyAuthenticatedSelector,
  updateVoteCountProfileContents,
} from "@app/store/slices/profile";
import useNotification from "@hooks/useNotification";
import EmailNotVerified from "../../notification/templates/emailNotVerified";
import Mute from "../mute/mute";
import {updateVoteCountContentFeed} from "@app/store/slices/contentFeed";
import {updateVoteCountChallengeContents} from "@app/store/slices/challenge";
import Timer from "../../timer/timer";
import Avatar, {AvatarSize} from "../../avatar/avatar";
import Animation from "../../animation/animation";
import animationVote from "@assets/animations/vote-button.json";
import getHeight from "@utils/getHeight";
import {configSelector} from "@app/store/slices/config";
import {setCloseOverlay} from "@app/store/slices/overlay";
import {setReffererUserUid} from "@app/store/slices/signup";
import {AnalyticIDs, AnalyticSource} from "@app/store/interfaces/analytics";
import {ButtonBase} from "@mui/material";
import userService from "@services/userService";
import CircularProgress from "@mui/material/CircularProgress";
import {
  authOverlaySelector,
  openAuthOverlay,
} from "@app/store/slices/authOverlay";
import {
  WithoutAuthFormType,
  WithoutAuthRegPlace,
  WithoutAuthRegPoint,
} from "@app/store/interfaces/auth";
import FollowLock from "./follow-lock/followLock";
import {LocalStorageKey} from "@appTypes/localstorage";

interface IVideoInfoProps {
  contentId?: string;
  description?: string;
  challenge?: Partial<IChallenge>;
  user?: Partial<IUser>;
  prices?: IContentPrices;
  voteCount: number;
  rank?: number;
  analyticCategory?: string;
  sharingCount?: number;
  commentsCount?: number;
  visible?: boolean;
  voteVisible?: boolean;
  isUserLanding?: boolean;
  bottom?: string | number;
  muted: boolean;
  toggleMute: () => void;
}

const BottomControls = (props: IVideoInfoProps) => {
  const {
    contentId,
    description,
    user,
    voteCount,
    sharingCount,
    voteVisible = true,
    prices,
    visible,
    isUserLanding,
    bottom,
    muted,
    toggleMute,
  } = props;

  const [currentVoteButton, setCurrentVoteButton] = useState(0);
  const [animationVoteStopped, setAnimationVoteStopped] = useState(true);
  const isAuthenticated = useAppSelector(isAuthenticatedSelector);
  const isFullyAuthenticated = useAppSelector(isFullyAuthenticatedSelector);
  const {visible: visibleAuthOverlay} = useAppSelector(authOverlaySelector);
  const [clamped, setClamped] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [isDisabledFollowForAnonym, setIsDisabledFollowForAnonym] =
    useState(false);

  const [followButtonStatus, setFollowButtonStatus] = useState<
    "loading" | "succeeded" | undefined
  >(undefined);
  const [isUserFollowed, setIsUserFollowed] = useState<boolean>(
    Boolean(user?.following),
  );

  const notification = useNotification();
  const currentUser = useAppSelector(currentProfileSelector);
  const {freeVoteCountdown} = useAppSelector(configSelector);
  const freeVoteTimeLeftPercent = useRef<number | null>(null);
  const freeVoteButton = useRef<HTMLDivElement | null>(null);

  const isCurrentUser = user && user.uid === currentUser?.uid;

  const navigate = useNavigate();
  const {pathname} = useLocation();

  const dispatch = useAppDispatch();

  const handleUpdateContent = (vote: number, date: string | null) => {
    const updateOptions = {
      vote,
      contentId,
      date,
    };
    dispatch(
      updateVoteCountContent({
        vote,
        date,
      }),
    );
    dispatch(updateVoteCountFeed(updateOptions));
    dispatch(updateVoteCountContentFeed(updateOptions));
    dispatch(updateVoteCountChallengeContents(updateOptions));
    dispatch(updateVoteCountProfileContents(updateOptions));
    setCurrentVoteButton(date ? 1 : 0);
    setAnimationVoteStopped(date ? false : true);
    dispatch(
      updateFreeDateVoteDrawer({
        date,
      }),
    );
  };

  const handleClickVote = () => {
    if (user?.uid) {
      dispatch(setReffererUserUid(user.uid));
    }

    if (!isAuthenticated) {
      dispatch(
        openAuthOverlay({
          withoutAuthFormType: WithoutAuthFormType.VoteFree,
          username: user?.fullName || user?.name || user?.username || "User",
          regPoint: WithoutAuthRegPoint.VoteButton,
        }),
      );

      return;
    }

    const freeVotesCount = Number(
      localStorage.getItem(LocalStorageKey.FreeVotesCount) || 0,
    );
    if (
      currentUser.isAnonym &&
      currentVoteButton === 0 &&
      freeVotesCount >= 9
    ) {
      return dispatch(
        openAuthOverlay({
          withoutAuthFormType: WithoutAuthFormType.VoteFree,
          username: user?.fullName || user?.name || user?.username || "User",
          regPoint: WithoutAuthRegPoint.VoteButton,
          pathname,
        }),
      );
    }

    if (currentUser.status === "blocked" && !currentUser.isAnonym) {
      return notification.send({
        type: "error",
        text: `Oops! It seems your account is currently blocked, so you're unable to vote. Feel free to reach out to the admin for help or more details.`,
        options: {
          duration: 10000,
          icon: <BlockedIcon />,
        },
      });
    }

    if (!currentUser.isVerified && isFullyAuthenticated) {
      return notification.send({
        type: "error",
        text: <EmailNotVerified />,
        options: {
          duration: 10000,
          icon: <ErrorIcon />,
        },
      });
    }

    if (contentId) {
      if (currentVoteButton > 0) {
        dispatch(setMysteryBoxes([]));
        return contentService
          .getContentMysteryBoxes(contentId)
          .then((response) => {
            if (response?.data?.items) {
              dispatch(setMysteryBoxes(response.data.items));
            }
            dispatch(setOpenVoteDrawer({isStaticView: false}));
          })
          .catch((error) => {
            console.log(error);
          });
      }

      feedService
        .setVote({
          contentId,
          voteType: "free",
          voteCount: 1,
          source: AnalyticSource.Feed,
        })
        .then((response) => {
          if (response) {
            const date = moment()
              .add(freeVoteCountdown, "minutes")
              .toISOString();

            localStorage.setItem(
              LocalStorageKey.FreeVotesCount,
              `${freeVotesCount + 1}`,
            );
            handleUpdateContent(1, date);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleReflow = (reflow: {clamped: boolean}) => {
    const {clamped} = reflow;

    setClamped(clamped);
  };

  const handleClickDescription = () => {
    if ((clamped && !expanded) || (!clamped && expanded)) {
      setExpanded((prevState) => !prevState);
    }
  };

  const handleShareContent = () => {
    if (contentId && user?.username) {
      contentService.shareContent(contentId, user?.username).then((data) => {
        if (data) {
          notification.send({
            type: "message",
            text: "Link copied to clipboard.",
            options: {
              icon: <CopyIcon />,
              duration: 3000,
            },
          });
        }
      });
    }
  };

  const handleFollowButton = () => {
    if (!user?.uid) return;

    dispatch(setReffererUserUid(user.uid));

    if (isAuthenticated && currentUser.isAnonym) {
      return setIsDisabledFollowForAnonym(true);
    }

    if (!isAuthenticated) {
      dispatch(
        openAuthOverlay({
          withoutAuthFormType: WithoutAuthFormType.Follow,
          username: user?.fullName || user?.name || user?.username || "User",
          regPoint: WithoutAuthRegPoint.FollowButton,
          pathname,
        }),
      );

      return;
    }

    setFollowButtonStatus("loading");

    userService
      .follow(user.uid)
      .then(() => {
        setFollowButtonStatus("succeeded");
      })
      .catch((error) => {
        notification.send({
          type: "error",
          text: "Some error occurred trying to follow the user",
        });
        console.error("User can't follow another user", error);
        setFollowButtonStatus(undefined);
      });
  };

  const getDateFreeVote = () => {
    if (prices) {
      let date;

      if (typeof prices.freeVoteDate === "string") {
        date = prices.freeVoteDate;
      } else {
        date = prices.freeVoteDate?.date;
      }

      return fromServerToClientDate(date);
    }
  };

  const handleUpdateTime = (timeLeft: number) => {
    if (timeLeft && typeof timeLeft === "number") {
      const maxTime = freeVoteCountdown * 60 * 1000;
      const timeLeftPercent = ((maxTime - timeLeft) / maxTime) * 100;
      freeVoteTimeLeftPercent.current = parseFloat(timeLeftPercent.toFixed(2));

      if (freeVoteButton.current) {
        freeVoteButton.current?.style.setProperty(
          "background",
          `linear-gradient(90deg, #D7FB73 ${freeVoteTimeLeftPercent.current}%, #FFFFFF ${freeVoteTimeLeftPercent.current}%)`,
        );
      }
    }
  };

  const handleCompleteTime = () => {
    handleUpdateContent(voteCount, null);
  };

  const handleClickUser = (navigateTo: string, isCurrentUser: boolean) => {
    if (pathname === navigateTo) {
      return dispatch(setCloseOverlay());
    }

    if (!isCurrentUser) {
      return navigate(navigateTo, {
        state: {
          from: pathname,
        },
      });
    }

    return navigate(navigateTo);
  };

  const renderVoteButtons = () => {
    if (currentVoteButton > 0) {
      const dateEnd = getDateFreeVote();
      return (
        <>
          <div
            ref={freeVoteButton}
            className={styles.vote_free_lock}
            style={{
              background: `linear-gradient(90deg, #D7FB73 ${freeVoteTimeLeftPercent.current}%, #FFFFFF ${freeVoteTimeLeftPercent.current}%)`,
            }}
          >
            Free vote after
            {dateEnd && (
              <div className={styles.timer}>
                <Timer
                  visibleHours={false}
                  dateEnd={dateEnd}
                  updateTime={handleUpdateTime}
                  completeTime={handleCompleteTime}
                />
              </div>
            )}
          </div>
          <div className={styles.vote_buy} onClick={handleClickVote}>
            Buy votes
          </div>
        </>
      );
    }

    return (
      <div
        id={AnalyticIDs.VoteClick}
        data-uid={user?.uid}
        data-button="vote"
        className={styles.vote_free}
        onClick={handleClickVote}
      >
        +1 Vote for me
      </div>
    );
  };

  useLayoutEffect(() => {
    if (prices?.freeVoteDate) {
      let date;

      if (typeof prices.freeVoteDate === "string") {
        date = prices.freeVoteDate;
      } else if (typeof prices.freeVoteDate?.date === "string") {
        date = prices.freeVoteDate.date;
      } else {
        return;
      }
      const clientDate = fromServerToClientDate(date);
      const diff = moment(clientDate).diff(moment(), "milliseconds");

      if (diff > 0) {
        setCurrentVoteButton(1);
      }
    }
  }, [prices]);

  useEffect(() => {
    if (!currentUser.isAnonym) {
      setIsDisabledFollowForAnonym(false);
    }
  }, [currentUser]);

  useEffect(() => {
    return () => {
      setIsDisabledFollowForAnonym(false);
    };
  }, []);

  return (
    <div
      className={styles.root}
      style={{
        bottom,
      }}
    >
      {isDisabledFollowForAnonym && !visibleAuthOverlay && (
        <FollowLock
          username={user?.fullName || user?.name || user?.username || "User"}
          onClick={() => setIsDisabledFollowForAnonym(false)}
        />
      )}
      <div
        className={clsx(
          styles.row,
          isUserLanding && styles.row_slide_animation,
        )}
      >
        <div
          className={styles.info}
          style={{
            marginRight: "-8px",
            minWidth: 80,
            width: 80,
            marginBottom:
              currentUser.messagesCount > 0 && pathname === "/" ? 70 : 0,
          }}
        >
          {visible && (
            <div className={clsx(styles.info_item, styles.info_item_user)}>
              <div
                onClick={() => {
                  if (user && user.uid !== currentUser?.uid) {
                    handleClickUser(`/profile/${user.username}`, false);
                  } else {
                    handleClickUser(`/profile`, true);
                  }
                }}
              >
                <Avatar
                  status={user?.onlineStatus?.status}
                  border={true}
                  isFeed={true}
                  url={user?.avatar}
                  size={AvatarSize.Feed}
                  loading={!user?.username}
                />
              </div>
              {followButtonStatus === "succeeded" && (
                <div className={styles.info_item_user_subscribed_success}>
                  <SuccessIconCircle />
                </div>
              )}
              {followButtonStatus === "loading" && (
                <CircularProgress
                  size={16}
                  sx={{
                    position: "absolute",
                    top: "30px",
                  }}
                />
              )}
              {!isCurrentUser && !isUserFollowed && !followButtonStatus && (
                <ButtonBase
                  onClick={handleFollowButton}
                  className={styles.info_item_user_subscribe}
                >
                  <UserSubscribeIcon />
                </ButtonBase>
              )}
            </div>
          )}
          {visible && (
            <div className={clsx(styles.info_item, styles.info_likes)}>
              <div className={styles.info_top}>
                <LikeIcon />
              </div>
              <div className={styles.info_bottom}>
                {formatNumber(voteCount ?? 0)}
              </div>
            </div>
          )}
          {visible && (
            <div className={clsx(styles.info_item, styles.info_item_comments)}>
              <div className={styles.info_top}>
                <CommentsIcon />
              </div>
              <div className={styles.info_bottom}>0</div>
            </div>
          )}
          {visible && (
            <div
              className={clsx(styles.info_item, styles.info_item_share)}
              onClick={handleShareContent}
            >
              <div className={styles.info_top}>
                <ShareIcon />
              </div>
              <div className={styles.info_bottom}>{sharingCount || 0}</div>
            </div>
          )}
          <div className={clsx(styles.info_item, styles.info_item_mute)}>
            <Mute muted={muted} toggleMute={toggleMute} />
          </div>
        </div>
        <div className={styles.col}>
          {description && (
            <div
              className={clsx(
                styles.description,
                expanded && styles.expanded,
                clamped && styles.clamped,
              )}
              onClick={handleClickDescription}
            >
              <LinesEllipsis
                text={`${description} `}
                maxLine={expanded ? "20" : "2"}
                ellipsis=" "
                trimRight
                basedOn="letters"
                onReflow={handleReflow}
              />
            </div>
          )}
        </div>
      </div>
      {visible && voteVisible && (
        <div className={styles.row}>
          {visible && (
            <Animation
              height={getHeight()}
              animationData={animationVote}
              isStopped={animationVoteStopped}
              rootStyle={{
                marginBottom: 65,
                position: "absolute",
                bottom: 0,
                left: 0,
              }}
            />
          )}
          <div className={styles.vote}>{visible && renderVoteButtons()}</div>
        </div>
      )}
    </div>
  );
};

export default BottomControls;
