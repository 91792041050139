import {useNavigate, useSearchParams} from "react-router-dom";
import {USER_AUTH} from "@app/router.constants";
import {isInAppBrowser} from "@utils/detections";
import {useEffect} from "react";
import {useAppSelector} from "@app/store/hooks";
import {currentProfileSelector} from "@app/store/slices/profile";
import {LocalStorageKey} from "@appTypes/localstorage";
import {SearchParams} from "@appTypes/searchParams";
import Amplitude from "@utils/amplitude";
import {AnalyticIDs} from "@app/store/interfaces/analytics";

export const useAuthHash = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const {isAnonym} = useAppSelector(currentProfileSelector);

  useEffect(() => {
    const hash = localStorage.getItem(LocalStorageKey.AuthHash);
    const currentUrl = new URL(window.location.href);
    const hashFromUrl = searchParams.get(SearchParams.Hash);
    const instagramRedirectData = searchParams.get(
      SearchParams.InstagramRedirectData,
    );

    const isInApp = isInAppBrowser();

    if (instagramRedirectData) {
      // redirectParams [hash],[userId],[deviceId],[sessionId]
      const redirectParams = instagramRedirectData.split(",");
      const hash = redirectParams[0];
      const userId = redirectParams[1];
      const deviceId = redirectParams[2];
      const sessionId = redirectParams[3];

      Amplitude.track(AnalyticIDs.RedirectEnded);

      currentUrl.searchParams.delete(SearchParams.Hash);

      localStorage.setItem(
        LocalStorageKey.AfterHashAuthPage,
        `${window.location.origin}${window.location.pathname}`,
      );

      let url = `${USER_AUTH}?hash=${hash}&auth_type=instagram`;

      fetch("/front-api/logs", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: "Redirect ended",
          url,
          deviceId,
          sessionId,
          userId,
        }),
      });

      navigate(url);
    } else if (isInApp && !hashFromUrl && isAnonym) {
      hash && currentUrl.searchParams.set(SearchParams.Hash, hash);

      window.history.replaceState(null, "", currentUrl.toString());
    } else if (!isInApp && hashFromUrl) {
      currentUrl.searchParams.delete(SearchParams.Hash);

      localStorage.setItem(
        LocalStorageKey.AfterHashAuthPage,
        currentUrl.pathname.includes("signup")
          ? "/profile"
          : currentUrl.pathname,
      );

      let url = `${USER_AUTH}?hash=${hashFromUrl}&auth_type=anonym`;

      navigate(url);
    }
  }, [navigate, searchParams]);

  return;
};
