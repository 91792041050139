import React from "react";
import Button, {ButtonStyle} from "../../button/button";
import {useAppDispatch} from "@app/store/hooks";
import {openAuthOverlay} from "@app/store/slices/authOverlay";
import {
  WithoutAuthFormType,
  WithoutAuthRegPoint,
} from "@app/store/interfaces/auth";
import useNotification from "@hooks/useNotification";
import {useLocation} from "react-router-dom";

const AnonSignUp = () => {
  const dispatch = useAppDispatch();
  const notification = useNotification();
  const {pathname} = useLocation();

  const handleSignUpClick = () => {
    notification.clear();
    dispatch(
      openAuthOverlay({
        withoutAuthFormType: WithoutAuthFormType.Default,
        regPoint: WithoutAuthRegPoint.RedAlertPopup,
        pathname,
      }),
    );
  };

  return (
    <span>
      Sign Up to save access to your profile and content
      <Button
        onClick={handleSignUpClick}
        style={ButtonStyle.White}
        rootStyles={{
          marginTop: 16,
          height: 40,
        }}
      >
        <span
          style={{
            color: "#000",
            textTransform: "uppercase",
            fontSize: "12px",
            fontWeight: 800,
          }}
        >
          Sign up
        </span>
      </Button>
    </span>
  );
};

export default AnonSignUp;
