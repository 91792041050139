import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import {ReactComponent as CloseCircleIcon} from "@assets/images/close-circle.svg";
import React, {useEffect, useState} from "react";

import styles from "./voteDrawer.module.scss";
import {
  isVoteDrawerVisibleSelector,
  setCloseVoteDrawer,
  setOpenVoteDrawer,
  voteDrawerPrices,
  voteDrawerSelector,
} from "@app/store/slices/voteDrawer";
import {useAppDispatch, useAppSelector} from "@app/store/hooks";
import Timer from "../timer/timer";
import {fromServerToClientDate} from "@utils/time";
import VoteDrawerPrice from "@components/vote-drawer/price/voteDrawerPrice";
import {LocalStorageKey} from "@appTypes/localstorage";

const VoteDrawerForStaticView = () => {
  const {mysteryBoxes, user, contentId, timerVisible} = useAppSelector(voteDrawerSelector);
  const prices = useAppSelector(voteDrawerPrices);
  const {isDrawerVisible} = useAppSelector(isVoteDrawerVisibleSelector);
  const dispatch = useAppDispatch();
  const [freeVoteDate, setFreeVoteDate] = useState<string | null>(null);

  useEffect(() => {
    const localKey = `${LocalStorageKey.FreeVotesCount}_${contentId}`;
    const storedData = localStorage.getItem(localKey);
    if (storedData) {
      const {timerEnd} = JSON.parse(storedData);
      if (timerEnd && !isNaN(new Date(timerEnd).getTime())) {
        setFreeVoteDate(fromServerToClientDate(timerEnd));
        return;
      }
    }

    if (prices?.freeVoteDate) {
      const date =
        typeof prices.freeVoteDate === "string"
          ? prices.freeVoteDate
          : prices.freeVoteDate?.date;
      setFreeVoteDate(date ? fromServerToClientDate(date) : null);
    }
  }, [contentId, prices]);

  const handleCloseVoteDrawer = () => {
    dispatch(setCloseVoteDrawer());
  };

  return (
    <SwipeableDrawer
      container={document.querySelector(".feed")}
      anchor="bottom"
      open={isDrawerVisible}
      onClose={handleCloseVoteDrawer}
      onOpen={() =>
        dispatch(
          setOpenVoteDrawer({
            isStaticView: true,
          }),
        )
      }
      disableSwipeToOpen={true}
      ModalProps={{
        keepMounted: false,
      }}
    >
      <div className={styles.root}>
        <div className={styles.packs}>
          <div className={styles.close} onClick={handleCloseVoteDrawer}>
            <CloseCircleIcon />
          </div>
          <div className={styles.title}>
            {mysteryBoxes && mysteryBoxes.length ? (
              <>
                Buy votes and <br />
                get gift from{" "}
                <span className={styles.title_name}>
                  {user?.fullName || user?.name || user?.username || "User"}
                </span>
              </>
            ) : (
              <>
                Buy votes <br />
                for{" "}
                <span className={styles.title_name}>
                  {user?.fullName || user?.name || user?.username || "User"}
                </span>
              </>
            )}
          </div>

          <div className={styles.packs_list}>
            {prices &&
              prices.items?.map((price, priceIndex) => (
                <VoteDrawerPrice price={price} key={priceIndex} />
              ))}
          </div>
          {timerVisible && (
            <div className={styles.free}>
              <div className={styles.free_plus}>+1</div>
              <div className={styles.free_text}>
                Your +1 free vote is available every 1 hr
              </div>
              {isDrawerVisible && freeVoteDate && (
                <div className={styles.free_timer}>
                  <Timer dateEnd={freeVoteDate} />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </SwipeableDrawer>
  );
};

export default VoteDrawerForStaticView;
