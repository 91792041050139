import styles from "./fakeLoaderOverlay.module.scss";
import React from "react";
import Skeleton from "@components/skeleton/skeleton";
import {ReactComponent as BackIcon} from "@assets/images/back.svg";
import {ReactComponent as SendDisabledIcon} from "@assets/images/message-send-disabled.svg";
import Avatar, {AvatarSize} from "@components/avatar/avatar";
import {useAppDispatch, useAppSelector} from "@app/store/hooks";
import {
  fakeLoaderOverlaySelector,
  setFakeLoaderOverlayVisible,
} from "@app/store/slices/fakeLoaderOverlay";
import {useNavigate} from "react-router-dom";
import {MESSAGES} from "@app/router.constants";

const FakeLoaderOverlay = () => {
  const {visible, avatar, online, lastSeen, displayName, blackOverlayVisible} =
    useAppSelector(fakeLoaderOverlaySelector);

  const skeletonStyle = {
    backgroundColor: "#000",
  };
  const skeletonStyle2 = {
    backgroundColor: "#262626",
  };

  const dispatch = useAppDispatch();

  if (!visible) {
    return null;
  }

  if (blackOverlayVisible) {
    return <div className={styles.blackOverlay} />;
  }

  return (
    <div className={styles.main}>
      <div className={styles.root}>
        <div className={styles.header}>
          <div
            className={styles.left}
            onClick={() => dispatch(setFakeLoaderOverlayVisible(false))}
          >
            <BackIcon fill={"white"} />
          </div>
          <h1 className={styles.center}>
            <div className={styles.nameContainer}>{displayName}</div>
          </h1>
          <div className={styles.right}>
            <Avatar url={avatar} size={AvatarSize.Feed} />
          </div>
        </div>
        <div style={{height: "82px"}} />
        <div className={styles.header3}>
          <div className={styles.item3}>
            <Skeleton maxWidth={100} type="medium-text" style={skeletonStyle} />
          </div>
          <div className={styles.circle}>
            <Skeleton type="normal-circle" style={skeletonStyle2} />
          </div>
        </div>
        <div className={styles.header2}>
          <div className={styles.item2}>
            <Skeleton maxWidth={220} type="medium-text" style={skeletonStyle} />
          </div>
          <div className={styles.circle}>
            <Skeleton type="normal-circle" style={skeletonStyle2} />
          </div>
        </div>
        <div className={styles.header1}>
          <div className={styles.item1}>
            <Skeleton maxWidth={130} type="medium-text" style={skeletonStyle} />
            <Skeleton
              maxWidth={180}
              marginTop={5}
              type="medium-text"
              style={skeletonStyle}
            />
            <Skeleton
              maxWidth={150}
              marginTop={5}
              type="medium-text"
              style={skeletonStyle}
            />
          </div>
          <div className={styles.circle}>
            <Skeleton type="normal-circle" style={skeletonStyle2} />
          </div>
        </div>
        <div className={styles.time}>
          <Skeleton maxWidth={60} type="normal-text" style={skeletonStyle2} />
        </div>
        <div style={{height: "22px"}} />
        <div className={styles.header4}>
          <div className={styles.item4}>
            <Skeleton maxWidth={100} type="medium-text" style={skeletonStyle} />
          </div>
        </div>
        <div className={styles.header4}>
          <div className={styles.item5}>
            <Skeleton maxWidth={220} type="medium-text" style={skeletonStyle} />
          </div>
        </div>
        <div className={styles.header4}>
          <div className={styles.time2}>
            <Skeleton maxWidth={60} type="normal-text" style={skeletonStyle2} />
          </div>
        </div>
        <div className={styles.footer}>
          <div className={styles.input_box}>
            <div className={styles.input}></div>
            <div className={styles.button}>
              <SendDisabledIcon />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FakeLoaderOverlay;
