import {privateApi, publicApi} from "./api";
import {isMobile} from "../utils/detections";

const origin = window.location.origin;

interface IContentsByChallengeIdParams {
  challengeId: string;
  page: number;
  contentId?: string;
}

const challengeService = {
  shareChallenge: async (alias: string) => {
    const url = `${origin}/challenges/${alias}`;

    if (window?.navigator?.share && isMobile()) {
      return await window.navigator.share({
        url,
      });
    } else {
      await window?.navigator?.clipboard?.writeText?.(url);
      return true;
    }
  },
  getCategories: async () => {
    return await publicApi.get("/challenges/top");
  },
  getList: async (page = 1, status = "active") => {
    return await privateApi.get(`/challenges?page=${page}&status=${status}`);
  },
  getItemByAlias: async (alias: string) => {
    return await privateApi.get(`/challenges/${alias}`);
  },
  getItemRulesById: async (alias: string) => {
    return await privateApi.get(`/challenges/${alias}/rules`);
  },
  getTopContentsByChallengeId: async (params: IContentsByChallengeIdParams) => {
    const {contentId, page = 1, challengeId} = params;

    let url = `/challenges/${challengeId}/contents/top?page=${page}`;

    if (contentId && page === 1) {
      url = `${url}&c_uid=${contentId}`;
    }

    return await privateApi.get(url);
  },
  getNewContentsByChallengeId: async (params: IContentsByChallengeIdParams) => {
    const {contentId, page = 1, challengeId} = params;

    let url = `/challenges/${challengeId}/contents/top?page=${page}`;

    if (contentId && page === 1) {
      url = `${url}&c_uid=${contentId}`;
    }

    return await privateApi.get(url);
  },
  getUserContentsByChallengeId: async (challengeId: string) => {
    return await privateApi.get(`/challenges/${challengeId}/contents/user`);
  },
  unjoinChallenge: async (challengeId: string) => {
    return await privateApi.post(`/challenges/${challengeId}/unjoin`, {});
  },
  subscribeChallenge: async (challengeId: string) => {
    return await privateApi.post(`/challenges/${challengeId}/subscribe`, {});
  },
  unsubscribeChallenge: async (challengeId: string) => {
    return await privateApi.post(`/challenges/${challengeId}/unsubscribe`, {});
  },
};

export default challengeService;
