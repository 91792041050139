import moment from "moment";

export const formatVotes = (
  number?: number | string,
  withPlus = true,
): string => {
  if (!number && number !== 0) {
    return "–";
  }

  const formattedNumber = number
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return `${withPlus ? "+" : ""}${formattedNumber}`;
};

export const formatCash = (number: number | string): string => {
  if (!number && number !== 0) {
    return "–";
  }

  const formattedNumber = number
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return `$ ${formattedNumber}`;
};

export const formatPercent = (number: number): string => {
  if (!number && number !== 0) {
    return "–";
  }

  return `${Math.round(number)}%`;
};

export const formatRank = (number?: number): string => {
  if (!number && number !== 0) {
    return "–";
  }

  if (number < 1000) {
    return number.toString();
  } else if (number >= 1000 && number < 1000000) {
    return (number / 1000).toFixed(1).replace(/\.0$/, "") + "K+";
  } else {
    return (number / 1000000).toFixed(1).replace(/\.0$/, "") + "M+";
  }
};

export const formatNumber = (number: number, isBigOnly = false): string => {
  if (!number && number !== 0) {
    return "–";
  }

  if (number < 1000) {
    return number.toString();
  }
  // else if (number < 99999) {
  //   return (number / 1000).toString().replace(".", " ");
  // }
  else if (number > 999 && number < 1000000) {
    return (number / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  } else {
    return (number / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  }
};

export const formatDate = (dateStr: string, needMinutes?: boolean): string => {
  const date = moment(dateStr, "YYYY-MM-DD HH:mm:ss");
  if (!dateStr || !date.isValid()) {
    return "–";
  }

  return needMinutes
    ? date.format("DD/MM/YYYY HH:mm")
    : date.format("DD/MM/YYYY");
};
