import withLayout from "@hoc/withLayout";
import {PROFILE_CURRENT} from "@app/router.constants";
import {useNavigate, useParams} from "react-router-dom";
import PageTitle from "@components/page-title/pageTitle";
import React, {useEffect, useMemo, useState} from "react";
import userService from "@services/userService";
import {IPost, PostStatus} from "@app/store/interfaces/post";
import styles from "./post.module.scss";
import {Scrollbars} from "react-custom-scrollbars-2";
import ReactPlayer from "react-player";
import {
  clearMenuDrawer,
  setMenuDrawer,
  setOpenMenuDrawer,
} from "@app/store/slices/menuDrawer";
import {ReactComponent as UserMenuIcon} from "@assets/images/user-menu.svg";
import {useDispatch} from "react-redux";
import {
  CONTEXT_ARCHIVE_POST,
  CONTEXT_DELETE_POST,
  CONTEXT_EDIT_POST,
  CONTEXT_UNARCHIVE_POST,
} from "@components/menu-drawer/menuDrawer.constants";
import {useAppSelector} from "@app/store/hooks";
import {currentProfileSelector} from "@app/store/slices/profile";

const Post = () => {
  const {id} = useParams();
  const {uid} = useAppSelector(currentProfileSelector);

  const dispatch = useDispatch();

  const [post, setPost] = useState<IPost | undefined>();

  const isCurrentUserPost = useMemo(() => {
    return post?.user?.uid === uid;
  }, [uid, post]);

  useEffect(() => {
    id &&
      userService.getPostById(id).then((response) => {
        setPost(response.data);
      });
  }, [id]);

  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(PROFILE_CURRENT, {
      replace: true,
      state: {
        tab: 3,
      },
    });
  };

  const menuList = useMemo(() => {
    const isEditable = post?.isEditable;
    const isArchived = post?.status === PostStatus.Archive;
    const isCanArchive =
      post?.status !== PostStatus.WaitingApprove &&
      post?.status !== PostStatus.Blocked;

    return [
      {
        visible: isEditable,
        text: "Edit",
        type: CONTEXT_EDIT_POST,
        post,
      },
      {
        visible: isArchived,
        text: "Unarchive",
        type: CONTEXT_UNARCHIVE_POST,
        postId: post?.uid,
      },
      {
        visible: !isArchived && isCanArchive,
        text: "Archive",
        type: CONTEXT_ARCHIVE_POST,
        postId: post?.uid,
      },
      {
        visible: isEditable,
        text: "Delete",
        type: CONTEXT_DELETE_POST,
        postId: post?.uid,
        color: "#FF4848",
      },
    ];
  }, [post, isCurrentUserPost]);

  const handleClickMenu = () => {
    dispatch(clearMenuDrawer());
    dispatch(setMenuDrawer(menuList));
    dispatch(setOpenMenuDrawer());
  };

  return (
    <Scrollbars
      renderView={(props) => <div {...props} className={styles.root} />}
    >
      <PageTitle
        title="Post"
        backButtonVisible={true}
        backClick={handleBackClick}
        button={<UserMenuIcon />}
        buttonClick={handleClickMenu}
      />
      <div className={styles.list}>
        {post?.media.map((item) => {
          switch (item.type) {
            case "image":
              return <img key={item.uid} src={item.url} alt="post image" />;
            case "video":
              return (
                <ReactPlayer
                  key={item.uid}
                  height={"100%"}
                  width={"100%"}
                  url={item.url}
                  controls={true}
                  alt="post video"
                  playsinline={true}
                />
              );
          }
        })}
      </div>
    </Scrollbars>
  );
};

export default withLayout(Post);
