import userService from "@services/userService";
import {Dispatch} from "react";
import useNotification from "@hooks/useNotification";
import {PaymentProvider} from "@appTypes/payments";
import feedService from "@services/feedService";
import {LocalStorageKey} from "@appTypes/localstorage";
import {AnalyticSource} from "@app/store/interfaces/analytics";

export type VoteType = "free" | "paid";

export enum PaymentName {
  Tips = "Tips",
  CustomTips = "CustomTips",
  MysteryBox = "MysteryBox",
  RealGift = "RealGift",
  OnlineGift = "OnlineGift",
  Votes = "Votes",
  AddVotes = "AddVotes",
  HotContent = "HotContent",
  Post = "Post",
}

interface IPay {
  paymentName: PaymentName;
  paymentObjectUid: string;
  paymentProvider: PaymentProvider;
  voteCount?: number;
  voteType?: VoteType;
}

interface IUsePaymentsParams {
  paymentSource: AnalyticSource;
  setLoading: Dispatch<React.SetStateAction<boolean>>;
}

export const usePayments = (params: IUsePaymentsParams) => {
  const {setLoading, paymentSource} = params;

  const notification = useNotification();

  return ({
    paymentName,
    paymentObjectUid,
    paymentProvider,
    voteCount,
    voteType,
  }: IPay) => {
    switch (paymentName) {
      case PaymentName.Tips: {
        userService
          .buyTips(paymentObjectUid, paymentProvider, paymentSource)
          .then((paymentResponse) => {
            localStorage.setItem(
              LocalStorageKey.PaymentUid,
              paymentResponse.data.paymentUid,
            );

            if (paymentResponse.data.paymentLink) {
              window.location.assign(paymentResponse.data.paymentLink);
            }
          })
          .catch((error) => {
            notification.send({
              type: "error",
              text: error?.response?.data?.detail ?? "error occurred",
            });
          })
          .finally(() => {
            setLoading(false);
          });

        break;
      }
      case PaymentName.MysteryBox: {
        userService
          .buyMysteryBox(
            paymentObjectUid,
            undefined,
            paymentProvider,
            paymentSource,
          )
          .then((paymentResponse) => {
            localStorage.setItem(
              LocalStorageKey.PaymentUid,
              paymentResponse.data.paymentUid,
            );

            if (paymentResponse.data.paymentLink) {
              window.location.assign(paymentResponse.data.paymentLink);
            }
          })
          .catch((error) => {
            notification.send({
              type: "error",
              text: error?.response?.data?.detail ?? "error occurred",
            });
          })
          .finally(() => {
            setLoading(false);
          });

        break;
      }
      case PaymentName.RealGift: {
        userService
          .buyRealGift(paymentObjectUid, paymentProvider, paymentSource)
          .then((paymentResponse) => {
            localStorage.setItem(
              LocalStorageKey.PaymentUid,
              paymentResponse.data.paymentUid,
            );

            if (paymentResponse.data.paymentLink) {
              window.location.assign(paymentResponse.data.paymentLink);
            }
          })
          .catch((error) => {
            notification.send({
              type: "error",
              text: error?.response?.data?.detail ?? "error occurred",
            });
          })
          .finally(() => {
            setLoading(false);
          });

        break;
      }
      case PaymentName.OnlineGift: {
        userService
          .buyOnlineGift(paymentObjectUid, paymentProvider, paymentSource)
          .then((paymentResponse) => {
            localStorage.setItem(
              LocalStorageKey.PaymentUid,
              paymentResponse.data.paymentUid,
            );

            if (paymentResponse.data.paymentLink) {
              window.location.assign(paymentResponse.data.paymentLink);
            }
          })
          .catch((error) => {
            notification.send({
              type: "error",
              text: error?.response?.data?.detail ?? "error occurred",
            });
          })
          .finally(() => {
            setLoading(false);
          });

        break;
      }
      case PaymentName.Votes: {
        userService
          .buyVotes(paymentObjectUid, paymentProvider, paymentSource)
          .then((paymentResponse) => {
            localStorage.setItem(
              LocalStorageKey.PaymentUid,
              paymentResponse.data.paymentUid,
            );

            if (paymentResponse.data.paymentLink) {
              window.location.assign(paymentResponse.data.paymentLink);
            }
          })
          .catch((error) => {
            notification.send({
              type: "error",
              text: error?.response?.data?.detail ?? "error occurred",
            });
          })
          .finally(() => {
            setLoading(false);
          });

        break;
      }
      case PaymentName.AddVotes: {
        voteCount &&
          feedService
            .setVote({
              voteType: voteType ?? "",
              paymentProvider,
              voteCount,
              source: paymentSource,
              contentId: paymentObjectUid,
            })
            .then((paymentResponse) => {
              localStorage.setItem(
                LocalStorageKey.PaymentUid,
                paymentResponse.data.paymentUid,
              );

              if (paymentResponse.data.paymentLink) {
                window.location.assign(paymentResponse.data.paymentLink);
              }
            })
            .catch((error) => {
              notification.send({
                type: "error",
                text: error?.response?.data?.detail ?? "error occurred",
              });
            })
            .finally(() => {
              setLoading(false);
            });

        break;
      }

      case PaymentName.HotContent: {
        userService
          .buyHotContent(paymentObjectUid, paymentSource)
          .then((paymentResponse) => {
            localStorage.setItem(
              LocalStorageKey.PaymentUid,
              paymentResponse.data.paymentUid,
            );

            if (paymentResponse.data.paymentLink) {
              window.location.assign(paymentResponse.data.paymentLink);
            }
          })
          .finally(() => {
            setLoading(false);
          });

        break;
      }

      case PaymentName.CustomTips: {
        userService
          .buyCustomTips(paymentObjectUid, paymentProvider, paymentSource)
          .then((paymentResponse) => {
            localStorage.setItem(
              LocalStorageKey.PaymentUid,
              paymentResponse.data.paymentUid,
            );

            if (paymentResponse.data.paymentLink) {
              window.location.assign(paymentResponse.data.paymentLink);
            }
          })
          .catch((error) => {
            notification.send({
              type: "error",
              text: error?.response?.data?.detail ?? "error occurred",
            });
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };
};
