import {privateApi} from "./api";
import {isMobile} from "../utils/detections";

const origin = window.location.origin;

const contentService = {
  shareContent: async (id: string, userName: string) => {
    const url = `${origin}?c_uid=${id}`;

    if (window?.navigator?.share && isMobile()) {
      await window.navigator.share({
        url,
      });
      await privateApi.post(`/content/${id}/sharing`, {});
      return undefined;
    } else {
      await window?.navigator?.clipboard?.writeText?.(url);
      await privateApi.post(`/content/${id}/sharing`, {});
      return true;
    }
  },
  getContent: async (id: string) => {
    return await privateApi.get(`/content/${id}`);
  },
  putContent: async (id: string, options: any) => {
    return await privateApi.put(`/content/${id}`, options);
  },
  deleteContent: async (id: string) => {
    return await privateApi.delete(`/content/${id}`);
  },
  archiveContent: async (id: string) => {
    return await privateApi.post(`/content/${id}/archive`, {});
  },
  unarchiveContent: async (id: string) => {
    return await privateApi.post(`/content/${id}/unarchive`, {});
  },
  uploadContent: async (formData: any, onUploadProgress: any) => {
    return await privateApi.post("/content/upload/media", formData, {
      onUploadProgress,
    });
  },
  setContent: async (options: any) => {
    return await privateApi.post("/content", options);
  },
  viewContent: async (id: string) => {
    return await privateApi.post(`/content/${id}/view`, {});
  },
  swipeContent: async (id: string) => {
    return await privateApi.post(`/content/${id}/swipe`, {});
  },
  getContentMysteryBoxes: async (id: string) => {
    return await privateApi.get(`/content/${id}/mystery-boxes`);
  },
};

export default contentService;
