import {ReactComponent as FacebookIcon} from "../../../../assets/images/facebook.svg";
import Button, {ButtonStyle, ButtonType} from "../../../button/button";
import React, {useState} from "react";
import {useAppSelector} from "../../../../app/store/hooks";
import {configSelector} from "../../../../app/store/slices/config";
import {useSelector} from "react-redux";
import {signupSelector} from "../../../../app/store/slices/signup";
import {checkRuByIp as checkRU} from "../../../../utils/detections";
import {facebookAuthLink} from "../../../../utils/facebookAuth";

interface IFacebookButtonProps {
  title: string;
  onClick?: () => void;
}

const FacebookButton = (props: IFacebookButtonProps) => {
  const {title, onClick} = props;
  const [loading, setLoading] = useState(false);
  const {facebookDisabled} = useAppSelector(configSelector);
  const {country} = useSelector(signupSelector);

  const isRU = checkRU() || country?.value === "RU";

  const handleClick = () => {
    setLoading(true);
    if (onClick) {
      onClick();
    } else {
      window.location.assign(facebookAuthLink);
    }
  };

  return (
    !facebookDisabled &&
    !isRU && (
      <Button
        title={`${title} with Facebook`}
        iconStart={<FacebookIcon />}
        type={ButtonType.Filled}
        style={ButtonStyle.Auth}
        loading={loading}
        disabled={loading}
        onClick={handleClick}
        rootStyles={{
          height: 50,
        }}
      />
    )
  );
};

export default FacebookButton;
