import React, {
  memo,
  RefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
  VideoHTMLAttributes,
} from "react";
import Hls from "hls.js";
import {ReactComponent as PlayIcon} from "@assets/images/video-play.svg";
import clsx from "clsx";
import styles from "../videoPlayer.module.scss";
import appService, {LogType} from "@services/appService";
import moment from "moment/moment";
import {
  errorDataByErrorType,
  errorMessageByMediaErrorCode,
} from "../helpers/hlsLog.helper";
import {calculateProportionalSize} from "../helpers/videoSize.helper";
import {useAppDispatch, useAppSelector} from "@app/store/hooks";
import {
  currentProfileSelector,
  isViewedContentSelector,
  setViewedContent,
} from "@app/store/slices/profile";
import Amplitude from "@utils/amplitude";
import {v4 as generateUid} from "uuid";
import {configSelector} from "@app/store/slices/config";

type IHlsPlayerProps = VideoHTMLAttributes<HTMLVideoElement> & {
  isMuted: RefObject<boolean>;
  src: string;
  autoPlay: boolean;
  defaultAutoPlay: boolean;
  hlsDisabled: boolean;
  setPreview: (value: boolean) => void;
  onEnded: () => void;
  onViewed: () => void;
  traceDebugId?: string;
  isUserLanding?: boolean;
  videoInfoBottom?: number | string;
  width?: number;
  needLoad?: boolean;
  playingActive?: boolean;
  setPlayingActive?: () => void;
  progressVisible?: boolean;
  uid?: string;
};

const formatLogTime = "DD.MM.YYYY HH:mm:ss.SSS";

const HlsPlayer = (props: IHlsPlayerProps) => {
  const {
    uid,
    src,
    loop,
    className,
    autoPlay,
    hlsDisabled,
    videoInfoBottom,
    defaultAutoPlay,
    needLoad,
    isUserLanding,
    isMuted,
    poster,
    width,
    playingActive,
    progressVisible,
    setPreview,
    onEnded,
    onViewed,
    setPlayingActive,
  } = props;
  const [loading, setLoading] = useState(true);
  const [paused, setPaused] = useState(false);
  const playerRef = useRef<HTMLVideoElement>(null);
  const progressRef = useRef<HTMLDivElement>(null);
  const [rootWidth, setRootWidth] = useState(width);

  const {networkType} = useAppSelector(configSelector);
  const {uid: currentId} = useAppSelector(currentProfileSelector);
  const isViewedContent = useAppSelector(isViewedContentSelector)(uid);
  const dispatch = useAppDispatch();

  let currentTime = 0;
  let isViewContentState = useRef(isViewedContent);
  const isVideoViewed = useRef(false);

  // variables for check amplitude time_content_load and time_content_play
  const timeLoadingStartRef = useRef(0);
  const timeLoadingEndRef = useRef(0);
  const timeCanPlayRef = useRef(0);
  const timePlayStartRef = useRef(0);
  const canLoadRef = useRef(false);
  const segmentSize = useRef(0);
  // for debug logs
  const traceDebugId = useRef("");
  const isAutoPlay = useRef(false);

  const videoOverlayStyles = clsx(styles.video_overlay, {
    [styles.loading]: loading,
    [styles.paused]: paused,
  });

  const isNeedLoad = useMemo(() => {
    return needLoad && !autoPlay && playingActive;
  }, [needLoad, autoPlay, playingActive]);

  const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  };

  const isHLS = Hls.isSupported() && !hlsDisabled && !isIos();

  const handleLoadStart = () => {
    if (timeLoadingStartRef.current) {
      return false;
    }
    traceDebugId.current = generateUid();
    timeLoadingStartRef.current = Date.now();
  };

  const handleLoadedData = () => {
    if (timeLoadingEndRef.current) {
      return false;
    }

    timeLoadingEndRef.current = Date.now();

    let options: any = {
      time: timeLoadingEndRef.current - timeLoadingStartRef.current,
      content_id: uid,
      user_id: currentId,
    };

    if (segmentSize.current > 0) {
      options = {
        time: timeLoadingEndRef.current - timeLoadingStartRef.current,
        content_id: uid,
        user_id: currentId,
        segment_size: segmentSize.current,
      };
    }
  };

  const handleCanPlay = () => {
    setLoading(false);
    if (canLoadRef.current || !autoPlay) {
      return false;
    }

    canLoadRef.current = true;
    timeCanPlayRef.current = Date.now();
  };

  const handlePlayLog = () => {
    if (timePlayStartRef.current || !timeCanPlayRef.current) {
      return false;
    }

    timePlayStartRef.current = Date.now();

    let options: any = {
      time: timePlayStartRef.current - timeCanPlayRef.current,
      content_id: uid,
      user_id: currentId,
    };

    if (segmentSize.current > 0) {
      options = {
        time: timePlayStartRef.current - timeCanPlayRef.current,
        content_id: uid,
        user_id: currentId,
        segment_size: segmentSize.current,
      };
    }

    setPreview(false);
    setPaused(false);
  };

  const handleWaiting = () => {
    setLoading(true);
  };

  const handleStalled = (event: any) => {
    console.log("[STALLED]", event);
  };

  const handleSuspend = (event: any) => {
    console.log("[SUSPEND]", event);
  };

  const handleVisibilityChange = (event: any) => {
    if (isHLS && playerRef?.current && autoPlay) {
      if (window.document.visibilityState === "hidden" || event.persisted) {
        playerRef.current.pause?.();
        playerRef.current.currentTime = 0;
        progressRef.current!.style.width = "0";
        // videoStartTime = 0;
        setPreview(true);
        setPaused(true);
      }
    }
    if (!isHLS && playerRef?.current) {
      if (autoPlay) {
        playerRef.current.pause?.();
        playerRef.current.currentTime = 0;
        progressRef.current!.style.width = "0";
        // videoStartTime = 0;
        setPreview(true);
        setPaused(true);
      }
    }
  };

  const handleDebugLog = (event: any) => {
    const data = errorDataByErrorType(event);
    const videoWidth = event.target?.videoWidth;
    const videoHeight = event.target?.videoHeight;
    const quality =
      videoWidth && videoHeight ? `${videoWidth}x${videoHeight}` : "unknown";

    appService
      .send({
        type: LogType.Debug,
        traceId: traceDebugId.current,
        data: {
          hlsSupport: isHLS,
          contentUid: uid,
          date: moment().format(formatLogTime),
          networkType,
          quality,
          ...data,
        },
      })
      .catch((error) => {
        console.log("[debug common]", error);
      });
  };

  const handleErrorLog = (event: any) => {
    let data;
    const mediaErrorCode = event?.target?.error?.code;
    const videoWidth = event.target?.videoWidth;
    const videoHeight = event.target?.videoHeight;
    const quality =
      videoWidth && videoHeight ? `${videoWidth}x${videoHeight}` : "unknown";

    if (mediaErrorCode) {
      if (mediaErrorCode === 4) {
        return false;
      }
      const errorMessage = errorMessageByMediaErrorCode(mediaErrorCode);

      data = {
        mediaErrorCode: mediaErrorCode,
        errorMessage,
      };
    } else {
      data = event;
    }

    appService
      .send({
        type: LogType.Error,
        traceId: traceDebugId.current,
        data: {
          hlsSupport: isHLS,
          contentUid: uid,
          networkType,
          quality,
          ...data,
        },
      })
      .catch((error) => {
        console.log("[common]", error);
      });
  };

  const handleTimeUpdate = (event: any) => {
    if (event.target && event.target.paused) {
      return false;
    }

    handlePlayLog();

    if (event.target.currentTime > 2) {
      setPlayingActive?.();
    }

    if (event.target.currentTime > 0 && event.target.currentTime <= 2) {
      isVideoViewed.current = false;
    }

    if (currentTime !== event.target.currentTime) {
      if (event.target.currentTime > event.target.duration - 1) {
        if (!isViewContentState.current) {
          dispatch(setViewedContent(uid));
          isViewContentState.current = true;
          onEnded?.();
        }

        if (!isVideoViewed.current) {
          isVideoViewed.current = true;
          onViewed?.();
        }
      }
    }

    const duration = event.target.duration;
    const elapsedTime = event.target.currentTime;
    currentTime = event.target.currentTime;

    if (progressRef?.current && duration > 0) {
      progressRef.current!.style.width = (elapsedTime / duration) * 100 + "%";
    }
  };

  const handlePlay = () => {
    handleDebugLog({
      type: "play",
    });
    setPreview(false);
    setPaused(false);
  };

  const handlePause = () => {
    handleDebugLog({
      type: "pause",
    });
  };

  const handleEnded = () => {
    handleDebugLog({
      type: "ended",
    });
  };

  const togglePauseVideo = () => {
    if (!playerRef?.current?.paused) {
      playerRef.current?.pause();
      setPaused(true);
    } else {
      playerRef.current?.play();
      setPaused(false);
    }
  };

  const renderProgress = () => {
    return progressVisible ? (
      <div
        className={styles.progress}
        style={{
          bottom: videoInfoBottom ? `calc(${videoInfoBottom} - 9px)` : 0,
        }}
      >
        <div className={styles.progress_indicator} ref={progressRef} />
      </div>
    ) : (
      <></>
    );
  };

  const renderOverlay = () => {
    return (
      <div className={videoOverlayStyles} onClick={() => togglePauseVideo()}>
        {paused && <PlayIcon />}
      </div>
    );
  };

  useEffect(() => {
    let hls: Hls;

    function _initPlayer() {
      if (hls != null) {
        setPreview(true);
        setPaused(true);
        hls.detachMedia();
      }

      const newHls = new Hls({
        enableWorker: false,
        //...hlsPlayerConfig,
      });

      if (playerRef?.current != null && (isNeedLoad || autoPlay)) {
        newHls.attachMedia(playerRef.current);
      }

      newHls.on(Hls.Events.MEDIA_ATTACHED, () => {
        if (isNeedLoad || autoPlay) {
          newHls.loadSource(src);

          newHls.on(Hls.Events.MANIFEST_PARSED, () => {
            if (playerRef.current) {
              const videoWidth = playerRef.current?.videoWidth;
              const videoHeight = playerRef.current?.videoHeight;

              const calculatedProps = calculateProportionalSize(
                videoWidth,
                videoHeight,
                rootWidth,
              );

              if (calculatedProps?.width && playerRef.current) {
                // @ts-ignore
                playerRef.current.width = calculatedProps.width;
              }

              if (calculatedProps?.height && playerRef.current) {
                // @ts-ignore
                playerRef.current.height = calculatedProps.height;
              }

              if (autoPlay) {
                playerRef.current
                  ?.play()
                  .then(() => {
                    // setPreview(false);
                    // setPaused(false);
                  })
                  .catch(() =>
                    console.log(
                      "Unable to autoplay prior to user interaction with the dom.",
                    ),
                  );
              }
            }
          });

          newHls.on(Hls.Events.FRAG_BUFFERED, () => {
            if (autoPlay && playerRef.current && !segmentSize.current) {
              const levels = hls.levels;
              if (levels && levels.length > 0) {
                const firstLevelDetails = levels[0];
                const firstLevelSegments =
                  firstLevelDetails?.details?.fragments;
                if (firstLevelSegments && firstLevelSegments.length > 0) {
                  const firstSegmentSize = firstLevelSegments[0]?.stats?.total;
                  if (firstSegmentSize !== undefined) {
                    if (firstSegmentSize === 0) {
                      // set segment_size
                      segmentSize.current = 0;
                    } else {
                      segmentSize.current = Math.round(firstSegmentSize / 1024);
                    }
                  }
                }
              }
            }
          });
        }
      });

      newHls.on(Hls.Events.ERROR, function (_, data) {
        handleErrorLog({
          ...data,
          errorType: data.type,
        });
        if (data.fatal) {
          switch (data.type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              newHls.startLoad();
              break;
            case Hls.ErrorTypes.MEDIA_ERROR:
              newHls.recoverMediaError();
              break;
            default:
              _initPlayer();
              break;
          }
        }
      });

      hls = newHls;
    }

    if (isHLS) {
      _initPlayer();
    }

    return () => {
      if (isHLS) {
        if (hls != null) {
          if (progressRef.current) {
            progressRef.current!.style.width = "0";
          }
          setPreview(true);
          hls.destroy();
          hls.detachMedia();
        }
      }
    };
  }, [autoPlay, isNeedLoad, playerRef, segmentSize, src, rootWidth]);

  useEffect(() => {
    if (!isHLS && playerRef?.current) {
      if (!autoPlay) {
        setPreview(true);
        playerRef.current.pause?.();
        playerRef.current.currentTime = 0;
        if (progressRef.current) {
          progressRef.current!.style.width = "0";
        }
        // cancelAnimationFrame(animationId);
        // videoStartTime = 0;
        currentTime = 0;
      } else {
        playerRef.current.paused && playerRef.current.play?.();
      }
    }
  }, [playerRef, progressRef, autoPlay]);

  useEffect(() => {
    const isNeedToSetSource =
      !isHLS &&
      playerRef?.current &&
      (isNeedLoad || autoPlay) &&
      !defaultAutoPlay;

    if (isNeedToSetSource) {
      playerRef.current.src = src;
    }
  }, [isHLS, autoPlay, src, isNeedLoad, playerRef, defaultAutoPlay]);

  useEffect(() => {
    if (!isHLS) {
      if (playerRef?.current && currentTime > 0) {
        autoPlay && !playerRef.current.paused && setPaused(false);
      }
    }
  }, [playerRef, autoPlay]);

  useEffect(() => {
    const videoElement = playerRef.current;

    window.document.addEventListener(
      "visibilitychange",
      handleVisibilityChange,
      false,
    );
    window.addEventListener("pagehide", handleVisibilityChange, false);

    if (videoElement) {
      videoElement.addEventListener("loadstart", handleDebugLog, false);
      videoElement.addEventListener("loadeddata", handleDebugLog, false);
      videoElement.addEventListener("loadedmetadata", handleDebugLog, false);
      videoElement.addEventListener("suspend", handleDebugLog, false);
      videoElement.addEventListener("stalled", handleDebugLog, false);

      videoElement.addEventListener("play", handlePlay, false);
      videoElement.addEventListener("pause", handlePause, false);
      videoElement.addEventListener("timeupdate", handleTimeUpdate, false);
      videoElement.addEventListener("error", handleErrorLog, false);
    }

    return () => {
      window.document.removeEventListener(
        "visibilitychange",
        handleVisibilityChange,
      );
      window.removeEventListener("pagehide", handleVisibilityChange);

      if (videoElement) {
        videoElement.removeEventListener("play", handlePlay);
        videoElement.removeEventListener("pause", handlePause);
        videoElement.removeEventListener("timeupdate", handleTimeUpdate);
        videoElement.removeEventListener("error", handleErrorLog);

        videoElement.removeEventListener("loadstart", handleDebugLog);
        videoElement.removeEventListener("loadeddata", handleDebugLog);
        videoElement.removeEventListener("loadedmetadata", handleDebugLog);
        videoElement.removeEventListener("suspend", handleDebugLog);
        videoElement.removeEventListener("stalled", handleDebugLog);
      }
    };
  }, []);

  useEffect(() => {
    if (autoPlay && width && width !== rootWidth) {
      setRootWidth(width);
    }
  }, [width, rootWidth, autoPlay]);

  useEffect(() => {
    if (isAutoPlay.current && !autoPlay) {
      // clear amplitude data for debugging after change slide
      timeLoadingStartRef.current = 0;
      timeLoadingEndRef.current = 0;
      timePlayStartRef.current = 0;
      timeCanPlayRef.current = 0;
      segmentSize.current = 0;
      canLoadRef.current = false;
      traceDebugId.current = "";
    }
    isAutoPlay.current = autoPlay;
  }, [autoPlay, isAutoPlay]);

  if (isHLS) {
    return (
      <>
        <video
          loop={loop}
          className={className}
          controls={false}
          preload={isNeedLoad || autoPlay ? "metadata" : "none"}
          playsInline={true}
          ref={playerRef}
          poster={poster}
          muted={isMuted.current as boolean}
          onLoadStart={handleLoadStart}
          onLoadedData={handleLoadedData}
          onStalled={handleStalled}
          onCanPlay={handleCanPlay}
          onWaiting={handleWaiting}
          onPlaying={(event) => {
            if (event.target) {
              const calculatedProps = calculateProportionalSize(
                // @ts-ignore
                event.target.videoWidth,
                // @ts-ignore
                event.target.videoHeight,
                rootWidth,
              );
              if (calculatedProps?.width && playerRef.current) {
                // @ts-ignore
                playerRef.current.width = calculatedProps.width;
              }

              if (calculatedProps?.height && playerRef.current) {
                // @ts-ignore
                playerRef.current.height = calculatedProps.height;
              }
            }
            setLoading(false);
          }}
        />
        {!isUserLanding && renderProgress()}
        {renderOverlay()}
      </>
    );
  }

  if (defaultAutoPlay) {
    return (
      <>
        <video
          src={src}
          loop={loop}
          className={className}
          controls={false}
          preload="metadata"
          poster={poster}
          ref={playerRef}
          autoPlay={true}
          playsInline
          muted={isMuted.current as boolean}
          onLoadStart={handleLoadStart}
          onLoadedData={handleLoadedData}
          onWaiting={handleWaiting}
          onCanPlay={handleCanPlay}
          onPlaying={(event) => {
            if (event.target) {
              const calculatedProps = calculateProportionalSize(
                // @ts-ignore
                event.target.videoWidth,
                // @ts-ignore
                event.target.videoHeight,
                rootWidth,
              );
              if (calculatedProps?.width && playerRef.current) {
                // @ts-ignore
                playerRef.current.width = calculatedProps.width;
              }

              if (calculatedProps?.height && playerRef.current) {
                // @ts-ignore
                playerRef.current.height = calculatedProps.height;
              }
            }
            setLoading(false);
          }}
        />
        {!isUserLanding && renderProgress()}
        {renderOverlay()}
      </>
    );
  }

  return (
    <>
      <video
        src={src}
        loop={loop}
        className={className}
        controls={false}
        preload={isNeedLoad || autoPlay ? "metadata" : "none"}
        poster={poster}
        ref={playerRef}
        autoPlay={autoPlay}
        playsInline
        muted={isMuted.current as boolean}
        onLoadStart={handleLoadStart}
        onLoadedData={handleLoadedData}
        onWaiting={handleWaiting}
        onStalled={handleStalled}
        onSuspend={handleSuspend}
        onCanPlay={handleCanPlay}
        onPlaying={(event) => {
          // @ts-ignore
          if (event.target?.videoWidth && rootWidth) {
            const calculatedProps = calculateProportionalSize(
              // @ts-ignore
              event.target.videoWidth,
              // @ts-ignore
              event.target.videoHeight,
              rootWidth,
            );
            if (calculatedProps?.width && playerRef.current) {
              // @ts-ignore
              playerRef.current.width = calculatedProps.width;
            }

            if (calculatedProps?.height && playerRef.current) {
              // @ts-ignore
              playerRef.current.height = calculatedProps.height;
            }
          }
          setLoading(false);
        }}
      />
      {!isUserLanding && renderProgress()}
      {renderOverlay()}
    </>
  );
};

export default memo(HlsPlayer);
