import React from "react";
import styles from "./fakeLoaderOverlay.module.scss";
import {useAppSelector} from "@app/store/hooks";
import {fakeLoaderOverlaySelector} from "@app/store/slices/fakeLoaderOverlay";

const FakeBlackLoaderOverlay = () => {
  const {isLoaderOverlayBlack} = useAppSelector(fakeLoaderOverlaySelector);

  if (!isLoaderOverlayBlack) return null;

  return <div className={styles.blackOverlay} />;
};

export default FakeBlackLoaderOverlay;
