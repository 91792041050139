import React, {useEffect, useMemo, useState} from "react";
import StepOne from "./steps/step-one/stepOne";
import StepTwo from "./steps/step-two/stepTwo";
import {useDispatch} from "react-redux";
import {
  clearMysteryEditOverlay,
  setCloseMysteryEditOverlay,
  setMysteryEdit,
} from "@app/store/slices/mysteryEditOverlay";
import Overlay from "../overlay/overlay";
import {updateMysteryBoxItem} from "@app/store/slices/profile";
import userService from "@services/userService";
import useMysteryBoxPrices from "@hooks/useMysteryBoxPrices";
import {updateMysteryBox} from "@app/store/slices/mystery";

const MysteryEditOverlay = (props) => {
  const {visible, mystery} = props;
  const [currentStep, setCurrentStep] = useState(0);
  const [loadingPrices, prices] = useMysteryBoxPrices(visible);

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setCloseMysteryEditOverlay());
    setTimeout(() => {
      setCurrentStep(0);
      dispatch(clearMysteryEditOverlay());
    }, 500);
  };

  const handleNextStep = () => {
    setCurrentStep((prevState) => prevState + 1);
  };

  const handleBackStep = () => {
    setCurrentStep((prevState) => prevState - 1);
  };

  const handleUpdate = (updateMystery) => {
    dispatch(updateMysteryBoxItem(updateMystery));
    dispatch(updateMysteryBox(updateMystery));
    dispatch(
      setMysteryEdit({
        mystery: updateMystery,
      }),
    );
  };

  const renderCurrentStep = useMemo(() => {
    if (!mystery) {
      return false;
    }
    if (currentStep === 0) {
      return (
        <StepOne
          uid={mystery.uid}
          description={mystery.description}
          votesAmount={mystery.type}
          prices={prices}
          loadingPrices={loadingPrices}
          onUpdateMystery={handleUpdate}
          onClose={handleClose}
          onNext={handleNextStep}
        />
      );
    }

    return (
      <StepTwo
        mysteryBox={mystery}
        onUpdateMystery={handleUpdate}
        onClose={handleClose}
        onBack={handleBackStep}
      />
    );
  }, [currentStep, mystery, prices, loadingPrices]);

  useEffect(() => {
    if (mystery?.uid && mystery?.media === undefined) {
      userService
        .getMysteryById(mystery.uid)
        .then((response) => {
          handleUpdate(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [mystery]);

  return mystery && <Overlay visible={visible}>{renderCurrentStep}</Overlay>;
};

export default MysteryEditOverlay;
