import {createSlice} from "@reduxjs/toolkit";
import {AppState} from "@app/store/type";
import {IPost} from "@app/store/interfaces/post";

interface IPostEditOverlayState {
  post?: IPost;
  visible: boolean;
}

const initialState: IPostEditOverlayState = {
  post: undefined,
  visible: false,
};

export const postEditOverlaySlice = createSlice({
  name: "postEditOverlay",
  initialState,
  reducers: {
    setPostEdit(state, action) {
      state.post = action.payload.post;
    },
    setOpenPostEditOverlay(state) {
      state.visible = true;
    },
    setClosePostEditOverlay(state) {
      state.visible = false;
    },
    clearPostEditOverlay() {
      return initialState;
    },
  },
});

export const {
  setPostEdit,
  setOpenPostEditOverlay,
  setClosePostEditOverlay,
  clearPostEditOverlay,
} = postEditOverlaySlice.actions;

export const postEditOverlaySelector = (state: AppState) =>
  state.postEditOverlay;

export const postEditOverlayReducer = postEditOverlaySlice.reducer;
