export const errorMessageByMediaErrorCode = (code: number) => {
  switch (code) {
    case 1:
      return "Aborted by the user's request";
    case 2:
      return "Network error occurred";
    case 3:
      return "An error occurred while trying to decode the media resource";
    case 4:
      return "Src not supported";
    default:
      return "Unknown error";
  }
};

export const errorNetworkMessageByNetworkStateCode = (code: number) => {
  switch (code) {
    case 0:
      return "NETWORK_EMPTY[0]: The initial state when the browser has not yet started downloading the video or the video has not yet been set to download.";
    case 1:
      return "NETWORK_IDLE[1]: The browser has finished loading the video and there are no active network requests. In this state, the video is ready to play and it can be started without waiting for loading";
    case 2:
      return "NETWORK_LOADING[2]: The browser is in the process of loading the video. In this state, the browser makes network requests to download the video, and playback may be paused while the download occurs.";
    case 3:
      return "NETWORK_NO_SOURCE[3]: The video could not be loaded because the source (src) was not specified or the source does not exist. This can happen, for example, if the video link is incorrect or if the video file is deleted from the server";
    default:
      return "unknown";
  }
};

export const errorReadyStateMessageByReadyStateCode = (code: number) => {
  switch (code) {
    case 0:
      return "HAVE_NOTHING[0]: No data available. The browser is just starting to download data";
    case 1:
      return "HAVE_METADATA[1]: The browser has loaded the video metadata such as duration, resolution, format, etc. However, the video is not ready to play yet because the content data has not yet been loaded.";
    case 2:
      return "HAVE_CURRENT_DATA[2]: The browser has downloaded enough data to start playing the video. Data is available for the current playback position, but not enough to actually play more than one frame.";
    case 3:
      return "HAVE_FUTURE_DATA[3]: The browser has loaded enough data to allow playback to continue uninterrupted, but buffering is still ongoing.";
    case 4:
      return "HAVE_ENOUGH_DATA[4]: The browser has loaded all the data and is ready for continuous playback. The video is fully downloaded and ready to play in full.";
    default:
      return "unknown";
  }
};

export const errorDataByErrorType = (event: any) => {
  const type = event?.type;
  const error = event?.error || event?.target?.error;
  const networkState = errorNetworkMessageByNetworkStateCode(
    event?.target?.networkState || 4,
  );
  const readyState = errorReadyStateMessageByReadyStateCode(
    event?.target?.readyState || 5,
  );
  const buffered = event?.target?.buffered?.length || "unknown";
  const currentTime = event?.target?.currentTime || "unknown";
  const duration = event?.target?.duration || "unknown";
  const src = event?.target?.src || "unknown";
  const videoTracksCount = event?.target?.videoTracks?.length;

  const data = {
    error,
    networkState,
    readyState,
    buffered,
    currentTime,
    duration,
    src,
    ...(videoTracksCount ? {videoTracksCount} : {}),
  };

  if (type) {
    switch (type) {
      case "play":
        return {
          status: "PLAY",
        };
      case "pause":
        return {
          status: "PAUSE",
        };
      case "waiting":
        return {
          status: "WAITING",
          message: "Video is waiting for more data.",
          data,
        };
      case "loadstart":
        return {
          status: "LOAD_START",
        };
      case "loadeddata":
        return {
          status: "LOAD_END",
        };
      case "loadedmetadata":
        return {
          status: "LOAD_METADATA",
        };
      case "suspend":
        return {
          status: "SUSPEND",
          message: "Data loading has been suspended.",
          data,
        };
      case "stalled":
        return {
          status: "STALLED",
          message: "Failed to fetch data, but trying.",
          data,
        };
      default:
        return {
          status: "unknown",
          data,
        };
    }
  }
};
