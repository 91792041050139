import {IPaymentOfferViewedOptions} from "@app/store/interfaces/analytics";

export const getPaymentOfferViewedOptions = (
  options: IPaymentOfferViewedOptions,
): IPaymentOfferViewedOptions => {
  if (options.originalCurrency === "RUB") {
    return options;
  } else {
    const {originalCurrency, originalPrice, ...restOptions} = options;

    return restOptions;
  }
};
