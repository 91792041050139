import React, {useEffect, useMemo, useRef, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import useValidation from "@hooks/useValidation";
import clsx from "clsx";
import styles from "./auth.module.scss";
import authService from "@services/authService";
import {
  PROFILE_CURRENT,
  ROOT,
  TERMS_OF_SERVICE,
  TERMS_OF_SERVICE_RU,
} from "@app/router.constants";
import Input from "@components/input/input";
import Button from "@components/button/button";
import {ReactComponent as CloseIcon} from "@assets/images/close.svg";
import {ReactComponent as PaymentSuccessIcon} from "@assets/images/payment-success.svg";
import {useDispatch, useSelector} from "react-redux";
import {signupSelector} from "@app/store/slices/signup";
import SocialAuthButtons from "../../../social-auth-buttons/socialAuthButtons";
import {
  authOverlaySelector,
  closeAuthOverlay,
} from "@app/store/slices/authOverlay";
import RegistrationSuccess from "../../../notification/templates/registrationSuccess";
import useNotification from "@hooks/useNotification";
import {AnalyticIDs} from "@app/store/interfaces/analytics";
import {
  currentProfileSelector,
  setIsAnonym,
  setIsAuthenticated,
  setProfileData,
} from "@app/store/slices/profile";
import {googleAuth} from "@utils/googleAuth";
import {facebookAuth} from "@utils/facebookAuth";
import Header from "./header";
import {useAppSelector} from "@app/store/hooks";
import {FormType} from "@app/store/interfaces/auth";
import userService from "@services/userService";
import {LocalStorageKey} from "@appTypes/localstorage";
import * as amplitude from "@amplitude/analytics-browser";
import {getAnalyticSource} from "@components/auth-overlay/utils/getAnalyticSource";
import Amplitude from "@utils/amplitude";
import Link from "@mui/material/Link";

interface ISignUpProps {
  visible: boolean;
  visibleForm: boolean;
}

const defaultValues = {
  email: "",
  password: "",
};

const Signup = (props: ISignUpProps) => {
  const {visible, visibleForm} = props;
  const [errorMessage, setErrorMessage] = useState("");
  const [serverErrorMessage, setServerErrorMessage] = useState("");
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [visiblePassword, setVisiblePassword] = useState(false);
  const isSignUpViewedEventSent = useRef(false);

  const {withoutAuthFormType, username, regPoint, regPlace, contentId} =
    useAppSelector(authOverlaySelector);
  const {
    isAnonym,
    personalUid,
    country: currentProfileCountry,
  } = useAppSelector(currentProfileSelector);

  const navigate = useNavigate();
  const location = useLocation();
  const {alias: challengeId, id: userId} = useParams();

  const isChallenges = location.pathname.includes("challenges");
  const isProfile = location.pathname.includes("profile");

  const {country, reffererUserUid} = useSelector(signupSelector);

  const notification = useNotification();
  const dispatch = useDispatch();

  const {values, errors, handleChange, handleClear} =
    useValidation(defaultValues);

  const isVisiblePassword = visiblePassword || values.email || values.password;

  const errorVisible = useMemo(() => {
    return errors && (errorEmail || errorPassword) && errorMessage;
  }, [errors, errorEmail, errorPassword, errorMessage]);

  const errorStyle = clsx(styles.error, errorVisible && styles.error_visible);

  const serverErrorStyle = clsx(
    styles.error,
    serverErrorMessage && styles.visible,
  );

  const incorrectPassStyle = clsx(
    styles.error_incorrect_pass,
    serverErrorMessage && styles.visible,
  );

  const handleFocus = () => {
    setVisiblePassword(true);
  };

  const handleBlur = () => {
    setVisiblePassword(false);
  };

  const handleCloseMessage = () => {
    setErrorMessage("");
  };

  const handleCloseServerMessage = () => {
    setServerErrorMessage("");
  };

  const handleSignup = () => {
    if (!values.email || !values.password) {
      return false;
    }
    const path = window.location.pathname;

    setLoading(true);
    authService
      .signup({
        email: values.email,
        password: values.password,
        contentId: location?.state?.contentId,
        country: currentProfileCountry ?? country?.value,
        userUid: reffererUserUid,
        source: getAnalyticSource(path, regPoint, !!personalUid),
        regPoint,
      })
      .then((response) => {
        if (response.data.token) {
          const isRegistered =
            response.data.message === "Registered Successfully";

          localStorage.setItem(LocalStorageKey.RegistrationEmail, values.email);
          localStorage.setItem(LocalStorageKey.Token, response.data.token);
          localStorage.setItem(
            LocalStorageKey.RefreshToken,
            response.data.refreshToken,
          );
          localStorage.setItem(
            LocalStorageKey.StreamToken,
            response.data.streamToken,
          );
          localStorage.setItem(LocalStorageKey.UserId, response.data.uid);
          localStorage.removeItem(LocalStorageKey.AfterAnonAuthPage);
          localStorage.removeItem(LocalStorageKey.AnonPaymentPopup);
          localStorage.removeItem(LocalStorageKey.AnonUserId);

          response.data.uid && amplitude.setUserId(response.data.uid);

          dispatch(setIsAnonym(false));
          dispatch(setIsAuthenticated(true));
          dispatch(closeAuthOverlay());

          const shouldKeepCurrentPage = isProfile || isChallenges;

          if (!isAnonym && !shouldKeepCurrentPage && isRegistered) {
            navigate(`${PROFILE_CURRENT}?email-registration=success`);
          }

          if (!isAnonym && !shouldKeepCurrentPage && !isRegistered) {
            navigate(`${PROFILE_CURRENT}?email-login=success`);
          }

          if (isRegistered) {
            notification.send({
              type: "message",
              text: <RegistrationSuccess />,
              options: {
                icon: <PaymentSuccessIcon />,
                duration: 5000,
              },
            });
            localStorage.setItem(LocalStorageKey.Register, "visible");
          }

          if (isAnonym) {
            userService.getCurrentProfile(personalUid).then((response) => {
              if (response.data) {
                dispatch(setProfileData(response.data));
              }
            });
          }

          if (!shouldKeepCurrentPage && contentId) {
            navigate(`${ROOT}?c_uid=${contentId}`);
          }
        }
      })
      .catch(({response}) => {
        setServerErrorMessage(
          response?.data?.error ||
            response?.data?.message ||
            response?.data?.detail ||
            "Unexpected server error, please try again later.",
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleEnter = () => {
    !errors.email && !errors.password && !loading && handleSignup();
  };

  useEffect(() => {
    !errors.email && setErrorEmail(false);
    !errors.password && setErrorPassword(false);

    if (!errors) {
      setErrorMessage("");
    } else {
      if (errors.email) {
        !errors.password && setErrorMessage(errors.email);
      } else {
        errors.password && setErrorMessage(errors.password);
      }
    }
  }, [errors]);

  useEffect(() => {
    if (serverErrorMessage) {
      const timeout = setTimeout(() => {
        setServerErrorMessage("");
      }, 10000);

      return () => {
        setServerErrorMessage("");
        clearTimeout(timeout);
      };
    }
  }, [serverErrorMessage]);

  useEffect(() => {
    if (!visibleForm) {
      isSignUpViewedEventSent.current = false;
      handleClear(defaultValues);
      setErrorMessage("");
      setErrorEmail(false);
      setErrorPassword(false);
      setServerErrorMessage("");
    }
  }, [visibleForm]);

  useEffect(() => {
    if (!isSignUpViewedEventSent.current && visibleForm) {
      const path = window.location.pathname;

      isSignUpViewedEventSent.current = true;

      Amplitude.track(AnalyticIDs.SignUpFormViewed, {
        path,
        source: getAnalyticSource(path, regPoint, !!personalUid),
      });
    }
  }, [regPoint, personalUid, visibleForm]);

  return (
    <>
      <div className={errorStyle}>
        {errorMessage}
        <div className={styles.close} onClick={handleCloseMessage}>
          <CloseIcon />
        </div>
      </div>

      {serverErrorMessage === "Password is incorrect" ? (
        <div className={incorrectPassStyle}>
          <span>Incorrect email or password.</span>
          <Link
            id={AnalyticIDs.SignInForgetClickButton}
            onClick={() => {
              dispatch(closeAuthOverlay());
              navigate("/user/reset-password");
            }}
            style={{
              cursor: "pointer",
              fontSize: 16,
              color: "#FFF",
              textDecoration: "underline",
              textDecorationColor: "#FFF",
            }}
          >
            Forget password?
          </Link>
          <div className={styles.close} onClick={handleCloseServerMessage}>
            <CloseIcon />
          </div>
        </div>
      ) : (
        <div className={serverErrorStyle}>
          <span>{serverErrorMessage}</span>
          <div className={styles.close} onClick={handleCloseServerMessage}>
            <CloseIcon />
          </div>
        </div>
      )}

      <Header
        formType={FormType.Signup}
        withoutAuthFormType={withoutAuthFormType}
        username={username}
      />

      <div className={styles.auth_socials}>
        <SocialAuthButtons
          title="Sign up"
          onClickGoogle={() => {
            const path = window.location.pathname;
            window.location.assign(
              googleAuth({
                country:
                  country?.value ??
                  localStorage.getItem(LocalStorageKey.UserCountry) ??
                  undefined,
                anonRegPoint: regPoint,
                userId: reffererUserUid ?? isProfile ? userId : undefined,
                challengeId: isChallenges ? challengeId : undefined,
                source: getAnalyticSource(path, regPoint, !!personalUid),
                contentId,
              }),
            );
          }}
          onClickFacebook={() => {
            const path = window.location.pathname;
            window.location.assign(
              facebookAuth({
                country: country?.value,
                anonRegPoint: regPoint,
                userId: reffererUserUid ?? isProfile ? userId : undefined,
                challengeId: isChallenges ? challengeId : undefined,
                source: getAnalyticSource(path, regPoint, !!personalUid),
                contentId,
              }),
            );
          }}
        />
      </div>

      <div className={styles.auth_text}>or continue with</div>

      <div className={styles.auth_form}>
        <div className={styles.auth_dynamic}>
          <Input
            className={styles.field}
            label="Email"
            name="email"
            borderStyle="white"
            type="text"
            autocomplete="off"
            value={values.email}
            error={errorEmail && values.email}
            onChange={handleChange}
            onEnter={handleEnter}
            onFocus={handleFocus}
            onBlur={() => {
              errors.email && !errorMessage && setErrorMessage(errors.email);
              errors.email && setErrorEmail(true);
              handleBlur();
            }}
          />
          <div
            style={{
              maxHeight: isVisiblePassword ? "100px" : "0",
              transition: "max-height 0.3s ease",
              overflow: isVisiblePassword ? "visible" : "hidden",
            }}
          >
            <Input
              className={styles.field}
              label="Password"
              name="password"
              type="password"
              borderStyle="white"
              autocomplete="nope"
              value={values.password}
              error={errorPassword && values.password}
              onChange={handleChange}
              onEnter={handleEnter}
              onFocus={handleFocus}
              onBlur={() => {
                errors.password &&
                  !errorMessage &&
                  setErrorMessage(errors.password);
                errors.password && setErrorPassword(true);
                handleBlur();
              }}
            />
          </div>
        </div>

        <Button
          loading={loading}
          onClick={handleSignup}
          rootStyles={{
            height: 50,
            background: "#FF1694",
          }}
        >
          <span
            style={{
              color: "#FFFFFF",
              fontWeight: 800,
            }}
          >
            Create account
          </span>
        </Button>
      </div>
    </>
  );
};

export default Signup;
