import {IChallenge} from "./challenge";
import {IOnlineStatus} from "@app/store/interfaces/onlineStatus";

export enum ContentStatus {
  New = "new",
  Active = "active",
  ActiveProfile = "active_profile",
  Past = "past",
  Archive = "archive",
  Deleted = "deleted",
  Blocked = "blocked",
  UserNotApproved = "u_not_approved",
}

export enum ContentType {
  Video = "video",
  Image = "image",
}

export enum HowToFillDrawerType {
  Description = "description",
  Link = "link",
}

export interface IContentPrice {
  type: string;
  votes: number;
  price: number;
  priceRu: number;
  forShow: boolean;
  forShowRu: boolean;
}

export interface IContentPrices {
  items: IContentPrice[];
  freeVoteDate?: {
    date: string;
    timezone_type?: number;
    timezone?: string;
  };
}

export interface IContentMysteryBox {
  uid: string;
  type: string;
  description: string;
  preview?: string;
  videoCount: string;
  imageCount: string;
  audioCount: string;
}

export interface IContentPlaylists {
  S: string;
  M: string;
  L: string;
}

export interface IContent {
  uid: string;
  challenge: Partial<IChallenge>;
  createAt: string;
  data?: {
    duration?: number;
    width?: number;
    height?: number;
  };
  description: string;
  format: string;
  previewUrl: string;
  rank: number;
  rankDelta: number;
  status: ContentStatus;
  type: ContentType;
  url: string;
  image: string;
  playlists: IContentPlaylists;
  user: {
    avatar: string;
    following: boolean;
    label?: string;
    name?: string;
    fullName?: string;
    online?: boolean;
    onlineStatus: IOnlineStatus;
    uid: string;
    username: string;
  };
  voteCount: number;
  prices: IContentPrices;
  sharingCount: number;
}
