import Overlay from "../overlay/overlay";
import {authOverlaySelector} from "@app/store/slices/authOverlay";
import {useAppSelector} from "@app/store/hooks";
import AuthOverlayBackground from "@assets/images/auth-overlay-background.jpg";
import React, {useState} from "react";
import styles from "./authOverlay.module.scss";
import {FormState, WithoutAuthFormType} from "@app/store/interfaces/auth";
import AgeConfirmation from "./formStates/ageConfirmation/ageConfirmation";
import SelectCountry from "./formStates/selectCountry/selectCountry";
import Auth from "./formStates/auth/auth";
import clsx from "clsx";
import {currentProfileSelector} from "@app/store/slices/profile";

const AuthOverlay = () => {
  const {visible, formState, withoutAuthFormType} =
    useAppSelector(authOverlaySelector);
  const [isSignIn, setIsSignIn] = useState(false);
  const {isAnonym} = useAppSelector(currentProfileSelector);

  const withoutAuthFormTypeNone =
    withoutAuthFormType === WithoutAuthFormType.None;

  const backgroundClass = clsx(
    styles.background,
    !withoutAuthFormTypeNone && styles.background_blur,
  );

  const renderForm = () => {
    switch (formState) {
      case FormState.AgeConfirmation:
        return <AgeConfirmation />;
      case FormState.CountrySelection:
        return <SelectCountry />;
      default:
        return (
          <Auth
            isVisibleForm={visible && formState === FormState.Auth}
            isSignIn={isSignIn}
            setIsSignIn={setIsSignIn}
          />
        );
    }
  };

  return (
    <Overlay
      visible={visible}
      rootStyle={{
        background:
          !withoutAuthFormTypeNone && formState === FormState.Auth
            ? "transparent"
            : "#000000",
      }}
    >
      <div className={styles.root}>
        <div className={backgroundClass}>
          {withoutAuthFormTypeNone &&
            formState === FormState.Auth &&
            visible && <img src={AuthOverlayBackground} alt="Create account" />}
        </div>
        {withoutAuthFormTypeNone &&
          (formState === FormState.Auth || isAnonym) && (
            <div className={styles.background_gradient} />
          )}
        <div className={styles.content}>{renderForm()}</div>
      </div>
    </Overlay>
  );
};

export default AuthOverlay;
