import {IPrice} from "@app/store/interfaces/mystery";
import {IOnlineStatus} from "@app/store/interfaces/onlineStatus";
import {SocialNetwork} from "@app/store/interfaces/socialNetwork";

export type PostPriceItemType = IPrice & {
  type: string;
  forShow: boolean;
};

export enum PostType {
  S = "S",
  M = "M",
  L = "L",
  XL = "XL",
}

export enum PostStatus {
  Active = "active",
  Archive = "archive",
  Blocked = "blocked",
  WaitingApprove = "waiting_approve",
}

export interface IPostMedia {
  uid: string;
  type: string;
  previewUrl: string;
  url?: string;
  rank: number;
  likesCount: number;
  commentsCount: number;
  duration?: number;
  loading?: boolean;
  data?: {
    duration?: number;
    width?: number;
    height?: number;
  };
}

export interface IPost {
  uid: string;
  createAt: string;
  description: string;
  videoCount: string;
  imageCount: string;
  audioCount: string;
  commentCount?: string;
  likeCount?: string;
  unlockCount?: string;
  isActive: boolean;
  preview: string;
  previewUid: string;
  status: any;
  blockReason: string;
  blockDescription: string;
  type: PostType | string;
  socialLink?: string;
  socialType?: SocialNetwork;
  user: {
    avatar: string;
    following: boolean;
    label?: string;
    name?: string;
    fullName?: string;
    online?: boolean;
    onlineStatus: IOnlineStatus;
    uid: string;
    username: string;
  };
  media: IPostMedia[];
  isPurchased?: boolean;
  isEditable?: boolean;
  price?: IPrice;
  label?: string;
  hasNewLabelTimer?: boolean;
  newLabelTimerValue?: string;
  hasTimer?: boolean;
  timerValue?: string;
  hasPurchaseLimit?: boolean;
  purchaseLimitValue?: number;
  hasGiftLimit?: boolean;
  giftLimitValue?: number;
  // challenge: Partial<IChallenge>;
  // format: string;
  // rank: number;
  // url: string;
  // voteCount: number;
}
