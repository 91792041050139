import {Autocomplete, Box, TextField} from "@mui/material";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {CountryItem, CountryItemDto} from "./type";
import appService from "../../services/appService";
import {RequestStatus} from "../../services/type";
import {makeStyles} from "@mui/styles";
import Skeleton from "../skeleton/skeleton";
import SVG from "react-inlinesvg";
import {ReactComponent as ReloadIcon} from "../../assets/images/reload-icon.svg";
import {LocalStorageKey} from "@appTypes/localstorage";

const useStyles = makeStyles({
  paper: {
    borderRadius: "24px !important",
    marginTop: "4px",
  },
  popper: {
    zIndex: 99999,
  },
});

interface CountryAutocompleteProps {
  selectedCountry?: string;
  onChangeCountry: (newValue: CountryItem) => void;
  isDarkContext?: boolean;
  isAutocomplete?: boolean;
  darkBorderColor?: string;
}

const CountryAutocomplete = (props: CountryAutocompleteProps) => {
  const {
    onChangeCountry,
    selectedCountry,
    isDarkContext = true,
    isAutocomplete = true,
    darkBorderColor,
  } = props;

  const [countries, setCountries] = useState<CountryItem[]>([]);
  const [status, setStatus] = useState<RequestStatus>(RequestStatus.Loading);

  const countryObject = useMemo(() => {
    return countries.find((country) => country.value === selectedCountry);
  }, [selectedCountry, countries]);

  const classes = useStyles();

  useEffect(() => {
    if (isAutocomplete) {
      const detectedCountry = localStorage.getItem(LocalStorageKey.UserCountry);
      const detectedCountryObject = countries?.find(
        (country) => country.value === detectedCountry,
      );
      detectedCountryObject && onChangeCountry(detectedCountryObject);
    }
  }, [countries]);

  useEffect(() => {
    loadCountries();
  }, []);

  const loadCountries = () => {
    appService
      .getCountryList()
      .then((res) => {
        setStatus(RequestStatus.Loading);
        const countriesDto: CountryItemDto[] = res.data.countries;

        setCountries(
          countriesDto.map((country): CountryItem => {
            return {
              value: country.code,
              label: country.name,
              image: country.image,
            };
          }),
        );
        setStatus(RequestStatus.Success);
      })
      .catch(() => {
        setStatus(RequestStatus.Failed);
      });
  };

  if (status === RequestStatus.Loading) {
    return (
      <Skeleton
        type="medium-text"
        style={{
          height: "50px",
          width: "100%",
          borderRadius: 46,
          backgroundColor: isDarkContext ? "#262626" : "#747474",
        }}
      />
    );
  }

  if (status === RequestStatus.Failed) {
    return (
      <>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            color: "#FF4848",
            height: "50px",
            border: "1px solid #FF4848",
            borderRadius: "25px",
            padding: "0 10px",
            margin: "10px 0",
          }}
        >
          <span>We couldn't load country list</span>
          <ReloadIcon
            style={{marginLeft: "1px", cursor: "pointer"}}
            onClick={loadCountries}
          />
        </div>
      </>
    );
  }

  return (
    <Autocomplete
      id="combo-box-demo"
      options={countries}
      classes={{paper: classes.paper, popper: classes.popper}}
      value={countryObject || null}
      onChange={(event, newValue) => {
        newValue && onChangeCountry(newValue);
      }}
      sx={
        isDarkContext
          ? {
              width: "100%",

              "& .MuiFormLabel-root": {
                color: "#fff !important",
                paddingLeft: "4px",
                backdropFilter: "blur(100px)",
              },

              "& .MuiAutocomplete-clearIndicator": {
                color: "#fff",
              },

              "& .MuiAutocomplete-popupIndicator": {
                color: "#fff",
              },

              "& .MuiAutocomplete-popper": {
                zIndex: 9999999,
              },

              "& .MuiOutlinedInput-notchedOutline": {
                border: 1,
                borderStyle: "solid",
                borderColor: darkBorderColor ?? "#fff",
                borderRadius: "46px",
              },

              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#D7FB73",
                },

              "& .MuiAutocomplete-paper": {
                borderRadius: "20",
              },
            }
          : {
              "& .MuiOutlinedInput-notchedOutline": {
                border: 1,
                borderStyle: "solid",
                borderRadius: "46px",
                borderColor: "#000",
              },
              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#000",
                },
              "& .MuiFormLabel-root": {
                color: "#000 !important",
                paddingLeft: "4px",
                backdropFilter: "blur(100px)",
              },
            }
      }
      renderOption={(props, option) => {
        return (
          <Box
            component="li"
            sx={{"& > svg": {mr: 2, flexShrink: 0}}}
            {...props}
          >
            <SVG
              src={option.image}
              width={40}
              height={40}
              title="Country flag"
            />

            {option.label}
          </Box>
        );
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label="Country"
            size="small"
            InputProps={{
              ...params.InputProps,
              startAdornment: countryObject && (
                <SVG
                  style={{marginLeft: "10px"}}
                  src={countryObject.image}
                  width={30}
                  height={30}
                  title="Country flag"
                />
              ),
            }}
            sx={
              isDarkContext
                ? {
                    input: {color: "#fff"},
                    "& .MuiInputLabel-root": {
                      marginTop: "4px",
                    },
                    "& .MuiOutlinedInput-root": {
                      height: "50px",
                      "&:hover fieldset": {
                        borderColor: "rgba(215, 251, 115, 0.8)",
                      },
                    },
                  }
                : {
                    input: {color: "#000"},
                    "& .MuiInputLabel-root": {
                      marginTop: "4px",
                    },
                    "& .MuiOutlinedInput-root": {
                      height: "50px",
                      "&:hover fieldset": {
                        borderColor: "#000000",
                      },
                    },
                  }
            }
          />
        );
      }}
    />
  );
};

export default CountryAutocomplete;
