import {FormType, WithoutAuthFormType} from "@app/store/interfaces/auth";
import styles from "./auth.module.scss";
import {ReactComponent as GreenTickCircleIcon} from "@assets/images/green-tick-circle.svg";
import React from "react";

interface IHeaderProps {
  formType: FormType;
  withoutAuthFormType: WithoutAuthFormType;
  username?: string;
}

const Header = (props: IHeaderProps) => {
  const {formType, withoutAuthFormType, username} = props;

  const renderTitle = () => {
    return (
      <div className={styles.auth_title}>
        <span>Sign</span>
        {formType === FormType.Signin ? <span>IN</span> : <span>UP</span>}
      </div>
    );
  };

  const renderHeader = () => {
    switch (withoutAuthFormType) {
      case WithoutAuthFormType.None:
        return (
          <>
            {renderTitle()}

            <div className={styles.auth_features}>
              <div className={styles.auth_features_item}>
                <GreenTickCircleIcon />
                Chat with models for free
              </div>
              <div className={styles.auth_features_item}>
                <GreenTickCircleIcon />
                Unlock private content
              </div>
              <div className={styles.auth_features_item}>
                <GreenTickCircleIcon />
                Become a fan
              </div>
            </div>
          </>
        );
      case WithoutAuthFormType.VoteFree:
        return (
          <div className={styles.auth_title_vote}>
            Only real people can vote for{" "}
            <span className={styles.auth_title_vote_name}>{username}</span>.
            Confirm you are real.
          </div>
        );
      case WithoutAuthFormType.Like:
        return (
          <div className={styles.auth_title_like}>
            Only real people can like posts from{" "}
            <span className={styles.auth_title_like_name}>{username}</span>.
            Confirm you are real
          </div>
        );
      case WithoutAuthFormType.Comment:
        return (
          <div className={styles.auth_title_comment}>
            Only real people can send comments to{" "}
            <span className={styles.auth_title_comment_name}>{username}</span>.
            Confirm you are real
          </div>
        );
      case WithoutAuthFormType.Follow:
        return (
          <div className={styles.auth_title_follow}>
            <span className={styles.auth_title_follow_name}>{username}</span>{" "}
            only accepts real people as followers. Confirm you are real
          </div>
        );
      case WithoutAuthFormType.Join:
        return (
          <div className={styles.auth_title_join}>
            Join challenges only after signing{" "}
            {formType === FormType.Signin ? "in" : "up"}
          </div>
        );
      case WithoutAuthFormType.Subscribe:
        return (
          <div className={styles.auth_title_join}>
            Subscribe challenges only after signing{" "}
            {formType === FormType.Signin ? "in" : "up"}
          </div>
        );
      case WithoutAuthFormType.VideoPlayer:
        return (
          <div className={styles.auth_title_video}>
            Only real users can view content. Confirm you are real.
          </div>
        );
      case WithoutAuthFormType.GetForFreeHotContent:
        return (
          <div className={styles.auth_title_video}>
            Confirm your real and get a free content.
          </div>
        );
      case WithoutAuthFormType.Default:
      default:
        return renderTitle();
    }
  };

  return renderHeader();
};

export default Header;
