import React, {Suspense, useEffect, useLayoutEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import router from "./router";
import {RouterProvider} from "react-router-dom";
import userService from "@services/userService";
import {
  currentProfileSelector,
  setMessageTotalCount,
  setProfileData,
} from "./store/slices/profile";
import {DefaultToast, ToastProvider} from "react-toast-notifications";
import {ReactComponent as NetworkIcon} from "@assets/images/notification-slow-network.svg";
import useAuth from "@hooks/useAuth";
import {useAppSelector} from "./store/hooks";
import {StreamChatContext} from "./context/streamChatContext";
import getHeight from "@utils/getHeight";
import {baseURL} from "@services/api";
import appService, {LogType} from "@services/appService";
import {configSelector, partialUpdateConfig} from "./store/slices/config";
import {parseServerEvents} from "@utils/parseServerEvents";
import {NetworkSpeedType, ServerEvents} from "./store/interfaces/config";
import useSpeedTest from "@hooks/useSpeedTest";
import SlowNetwork from "@components/notification/templates/slowNetwork";
import useNotification from "@hooks/useNotification";
import useFirebaseTokenUpdater from "@hooks/useFirebaseTokenUpdater";
import useSignOut from "@hooks/useSignOut";
import {useCreateChatClient} from "stream-chat-react";
import CircularProgress from "@mui/material/CircularProgress";
import {LocalStorageKey} from "@appTypes/localstorage";
import {SearchParams} from "@appTypes/searchParams";
import {setDollarAnimationData} from "@app/store/slices/dollarAnimation";

// TODO: we need change to stacked toasts (for example https://www.npmjs.com/package/react-stacked-toast)
const Notification = ({children, ...props}) => {
  return <DefaultToast {...props}>{children}</DefaultToast>;
};
let sse;

function App() {
  const dispatch = useDispatch();
  const isAuth = useAuth();
  const {uid} = useAppSelector(currentProfileSelector);

  const {personalUid} = useAppSelector(currentProfileSelector);
  const notification = useNotification();
  const {slowNetworkNotificationViewed, networkType} =
    useSelector(configSelector);
  const signout = useSignOut();

  const chatClient = useCreateChatClient({
    apiKey: process.env.REACT_APP_STREAM_API_KEY,
    tokenOrProvider: localStorage.getItem(LocalStorageKey.StreamToken),
    userData: {id: uid},
    options: {
      timeout: 6000,
    },
  });

  useSpeedTest();

  // TODO: push notifications
  useFirebaseTokenUpdater();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const chatId = searchParams.get(SearchParams.ChatId);
    const paymentId = searchParams.get(SearchParams.PaymentId);
    const paymentStatus = searchParams.get(SearchParams.PaymentStatus);
    const paymentType = searchParams.get(SearchParams.PaymentType);
    const type = searchParams.get(SearchParams.Type);

    if (chatId) {
      const redirectParams = {
        chatId,
        paymentId,
        paymentStatus,
        paymentType,
        type,
      };
      localStorage.setItem(
        LocalStorageKey.RedirectParams,
        JSON.stringify(redirectParams),
      );
    }
  }, []);

  const setFullHeight = () => {
    const windowHeight = getHeight();
    const vh = windowHeight * 0.01;

    window.document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  const handleUpdateVersion = (version) => {
    localStorage.setItem(LocalStorageKey.CtvVersion, version);
    window.location.reload();
  };

  const navigateRoot = () => {
    window.location.assign(window.location.origin);
  };

  const handleNeedRelogin = (event) => {
    const data = event?.data && JSON.parse(event?.data);
    const userId = data.user;

    if (uid === userId) {
      signout(navigateRoot, undefined);
    }
  };

  const handleServerEvents = (event) => {
    const localVersion = localStorage.getItem(LocalStorageKey.CtvVersion);

    const data = event?.data && JSON.parse(event?.data);
    const eventName = data?.name;
    const eventValue = data?.value;

    if (data) {
      if (
        eventName === ServerEvents.AppFrontVersion &&
        localVersion !== eventValue
      ) {
        handleUpdateVersion(eventValue);
      }

      if (eventName === ServerEvents.AppFreeVoteCountdown) {
        dispatch(
          partialUpdateConfig({
            freeVoteCountdown: Number(eventValue),
          }),
        );
      }

      if (eventName === ServerEvents.AppFrontFacebookDisabled) {
        dispatch(
          partialUpdateConfig({
            facebookDisabled: Number(eventValue),
          }),
        );
      }

      if (eventName === ServerEvents.AppFrontInstagramRedirect) {
        dispatch(
          partialUpdateConfig({
            instagramRedirect: eventValue,
          }),
        );
      }

      if (eventName === ServerEvents.AppFrontInstagramRedirectModels) {
        dispatch(
          partialUpdateConfig({
            instagramRedirectModels: eventValue,
          }),
        );
      }
    }
  };

  const handlePaymentEvent = (event) => {
    const data = event?.data && JSON.parse(event?.data);
    dispatch(setDollarAnimationData(data));
  };

  const checkAppVersion = () => {
    if (window?.document?.visibilityState !== "hidden") {
      const localVersion = localStorage.getItem(LocalStorageKey.CtvVersion);

      appService.getConfig().then((response) => {
        if (response?.data) {
          const configs = parseServerEvents(response.data?.configs);

          if (configs.version && configs.version !== localVersion) {
            handleUpdateVersion(configs.version);
          }

          dispatch(partialUpdateConfig(configs));
        }
      });
    }
  };

  useLayoutEffect(() => {
    const setHeightOnLoad = () => {
      setFullHeight();
      window.removeEventListener("DOMContentLoaded", setHeightOnLoad);
    };

    window.addEventListener("DOMContentLoaded", setHeightOnLoad);
    window.addEventListener("resize", setFullHeight);
    window.addEventListener("orientationchange", setFullHeight);
    window.visualViewport?.addEventListener("resize", setFullHeight);

    window.document.addEventListener(
      "visibilitychange",
      checkAppVersion,
      false,
    );
    window.addEventListener("pagehide", checkAppVersion, false);

    return () => {
      window.removeEventListener("DOMContentLoaded", setHeightOnLoad);
      window.removeEventListener("resize", setFullHeight);
      window.removeEventListener("orientationchange", setFullHeight);
      window.visualViewport?.removeEventListener("resize", setFullHeight);

      window.document.removeEventListener("visibilitychange", checkAppVersion);
      window.removeEventListener("pagehide", checkAppVersion);
    };
  }, []);

  useEffect(() => {
    isAuth &&
      userService
        .getCurrentProfile(personalUid)
        .then((response) => {
          if (response.data) {
            dispatch(setProfileData(response.data));
          }
        })
        .catch(({response}) => {
          // console.log(response);
        });
  }, [isAuth]);

  useEffect(() => {
    if (chatClient) {
      chatClient.getUnreadCount().then((response) => {
        dispatch(setMessageTotalCount(response.total_unread_count));
      });
      chatClient.on((event) => {
        if (event.total_unread_count > 0) {
          dispatch(setMessageTotalCount(event.total_unread_count));
        }

        if (event.type === "message.read") {
          chatClient.getUnreadCount().then((response) => {
            dispatch(setMessageTotalCount(response.total_unread_count));
          });
        }
      });
    }
  }, [chatClient]);

  useEffect(() => {
    const token = localStorage.getItem(LocalStorageKey.Token);
    let esUrl = `${baseURL}/events`;

    if (token) {
      esUrl = `${baseURL}/events?auth=${token}`;
    }

    if (sse) {
      sse.close();
    }

    try {
      sse = new EventSource(esUrl, {withCredentials: false});

      sse.addEventListener("config", handleServerEvents, false);
      sse.addEventListener("need_relogin", handleNeedRelogin, false);
      sse.addEventListener("notification", handleServerEvents, false);
      sse.addEventListener("payment_success", handlePaymentEvent, false);
    } catch (error) {
      console.error("Error initializing EventSource:", error);
    }

    return () => {
      sse.removeEventListener("config", handleServerEvents);
      sse.removeEventListener("need_relogin", handleNeedRelogin);
      sse.removeEventListener("notification", handleServerEvents);
      sse.removeEventListener("payment_success", handlePaymentEvent);
    };
  }, [uid]);

  useEffect(() => {
    if (
      networkType === NetworkSpeedType.Slow &&
      !slowNetworkNotificationViewed
    ) {
      notification.send({
        type: "warning",
        text: <SlowNetwork />,
        options: {
          icon: <NetworkIcon />,
          duration: 10000,
        },
      });
      dispatch(
        partialUpdateConfig({
          slowNetworkNotificationViewed: true,
        }),
      );
    }
  }, [networkType, slowNetworkNotificationViewed]);

  return (
    <Suspense fallback={<CircularProgress />}>
      <ToastProvider
        autoDismiss={false}
        autoDismissTimeout={10000}
        placement="top-center"
        components={{
          Toast: Notification,
        }}
        newestOnTop={true}
      >
        <StreamChatContext.Provider value={chatClient}>
          <RouterProvider router={router} />
        </StreamChatContext.Provider>
      </ToastProvider>
    </Suspense>
  );
}

export default App;
