export const ROOT = "/";
export const PROFILE_DATA_CLEAR = "/profile-data-clear";
export const USER_LANDING = "/:username";
export const USER_LANDING_UNLOCK = "/:username/unlock";
export const USER_LANDING_CHAT = "/:username/chat";
export const USER_LANDING_VOTE = "/:username/vote";
export const TIPS_LANDING = "tips/:id";
export const CUSTOM_TIPS_LANDING = "custom-tips/:id";
export const MYSTERY_BOXES_LANDING = "/mystery-boxes/:id";
export const REAL_GIFTS_LANDING = "/real-gifts/:id";
export const ONLINE_GIFTS_LANDING = "/online-gifts/:id";
export const VOTES_LANDING = "votes/:id";
export const USER_LANDING_BY_CONTENT = "/:username/:id";
export const CHALLENGE_LIST = "/challenges";
export const CHALLENGE_ITEM = "/challenges/:alias";
export const CHALLENGE_ITEM_RULES = "/challenges/:alias/rules";
export const CONTENT = "/content/:id";
export const MYSTERY_BOX = "/unpack-mystery-box/:id";
export const POST = "/unpack-post/:id";
export const MESSAGES = "/messages";
export const NOTIFICATION = "/notification";
export const PROFILE_CURRENT = "/profile";
export const PROFILE_CURRENT_FOLLOWERS = "/profile/followers";
export const SETTINGS = "/profile/settings";
export const PAYMENTS = "/profile/payments";
export const PROFILE_ITEM = "/profile/:id";
export const PROFILE_ITEM_FOLLOWERS = "/profile/:id/followers";
export const USER_RESET_PASSWORD = "/user/reset-password";
export const USER_RESET_PASSWORD_FAILED = "/user/reset-password/failed";
export const USER_NEW_PASSWORD = "/user/new-password";
export const USER_CONFIRMATION_SUCCESS = "/user/confirmation/success";
export const USER_CONFIRMATION_FAILED = "/user/confirmation/failed";
export const USER_AUTH = "/user/auth";
export const AGE_CONFIRMATION = "/user/signup/age-confirmation";
export const SELECT_COUNTRY = "/user/signup/select-country";

export const INFORMATION = "/information";
export const TERMS_OF_SERVICE = "/terms-of-service";
export const PRIVACY_POLICY = "/privacy-policy";
export const COMMUNITY_GUIDELINES = "/community-guidelines";
export const COOKIES_POLICY = "/cookies-policy";
export const FB_DATA_DELETION = "/fbdatadeletion";

export const TERMS_OF_SERVICE_RU = "/terms-of-service-ru";
export const PRIVACY_POLICY_RU = "/privacy-policy-ru";
export const REQUISITES_RU = "/requisites-ru";
export const PAYMENT_RU = "/payment-ru";

export const SUPPORT_CHAT = "/support/chat";

export const PAGE_NOT_FOUND = "/not-found";
export const PAYMENT_SUCCESS = "/payment/success";
export const PAYMENT_FAILED = "/payment/fail";
