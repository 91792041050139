import {WithoutAuthRegPoint} from "@app/store/interfaces/auth";
import {AnalyticSource} from "@app/store/interfaces/analytics";
import {getSourceByPath} from "@utils/getSourceByPath";

export const getAnalyticSource = (
  path: string,
  regPoint?: WithoutAuthRegPoint,
  hasPersonalUid?: boolean,
): string => {
  if (hasPersonalUid) {
    return AnalyticSource.External;
  }

  switch (regPoint) {
    case WithoutAuthRegPoint.ShowcaseProfile:
    case WithoutAuthRegPoint.OpenFollowersOrViewers:
    case WithoutAuthRegPoint.ProfileSettings:
    case WithoutAuthRegPoint.CreateMysteryBox:
      return AnalyticSource.Profile;

    case WithoutAuthRegPoint.GetForFreeHotContent:
    case WithoutAuthRegPoint.Chat:
      return AnalyticSource.Chat;

    case WithoutAuthRegPoint.CreateAccountBtn:
    case WithoutAuthRegPoint.RedAlertPopup:
    case WithoutAuthRegPoint.VideoPlayerTimeout:
    case WithoutAuthRegPoint.VoteButton:
    case WithoutAuthRegPoint.LikeButton:
    case WithoutAuthRegPoint.FollowButton:
    case WithoutAuthRegPoint.CommentButton:
    case WithoutAuthRegPoint.ShareProfile:
      return getSourceByPath(path);

    case WithoutAuthRegPoint.JoinChallengeButton:
    case WithoutAuthRegPoint.SubscribeChallengeButton:
      return AnalyticSource.Challenge;

    default:
      return "other";
  }
};
