import {useAppDispatch, useAppSelector} from "@app/store/hooks";
import {profileSelector, setLogoutVisible} from "@app/store/slices/profile";
import Drawer from "../drawer/drawer";
import useSignOut from "@hooks/useSignOut";
import {useNavigate} from "react-router-dom";
import {ROOT} from "@app/router.constants";
import {setFakeBlackLoaderOverlayVisible} from "@app/store/slices/fakeLoaderOverlay";
import {AnalyticIDs} from "@app/store/interfaces/analytics";

const LogoutDrawer = () => {
  const {logoutDrawerVisible} = useAppSelector(profileSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const signout = useSignOut();

  const handleClose = () => {
    dispatch(setLogoutVisible(false));
  };

  const handleOpen = () => {
    dispatch(setLogoutVisible(true));
  };

  const navigateRoot = () => {
    navigate(ROOT);
  };

  const handleApply = () => {
    dispatch(setFakeBlackLoaderOverlayVisible(true));
    dispatch(setLogoutVisible(false));
    signout(navigateRoot, undefined, AnalyticIDs.LogoutRegular);
  };

  return (
    <Drawer
      title="Log OUT"
      visible={logoutDrawerVisible || false}
      onClose={handleClose}
      onOpen={handleOpen}
      buttonText="Yes"
      buttonClick={handleApply}
      confirmId="logout_popup_clk_btn"
      cancelId="logout_popup_clk_element"
    >
      <div
        style={{
          textAlign: "center",
        }}
      >
        Do you really want to log out of the service?
      </div>
    </Drawer>
  );
};

export default LogoutDrawer;
