import React from "react";
import {ReactComponent as FollowLockIcon} from "@assets/images/feed-follow-lock.svg";
import styles from "./followLock.module.scss";
import {useAppDispatch} from "@app/store/hooks";
import {openAuthOverlay} from "@app/store/slices/authOverlay";
import {
  WithoutAuthFormType,
  WithoutAuthRegPlace,
  WithoutAuthRegPoint,
} from "@app/store/interfaces/auth";
import Button from "../../../button/button";
import {AnalyticIDs} from "@app/store/interfaces/analytics";
import {useLocation} from "react-router-dom";

interface IFollowLockProps {
  username: string;
  onClick: () => void;
}

const FollowLock = (props: IFollowLockProps) => {
  const {username, onClick} = props;
  const {pathname} = useLocation();
  const dispatch = useAppDispatch();

  const handleSignup = () => {
    onClick();
    dispatch(
      openAuthOverlay({
        withoutAuthFormType: WithoutAuthFormType.Default,
        regPoint: WithoutAuthRegPoint.FollowButton,
        regPlace: WithoutAuthRegPlace.Feed,
        pathname,
      }),
    );
  };

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <FollowLockIcon />
        <div className={styles.content_text}>
          {username} only accepts real people as followers. Confirm you are real
        </div>
        <div className={styles.content_button}>
          <Button
            onClick={handleSignup}
            rootStyles={{
              height: 40,
              width: "100%",
              background: "#FF1694",
            }}
          >
            <span className={styles.content_button}>Create account</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FollowLock;
